import * as z from "zod";

import { ItemMetaData } from "modules/itemsV2/types";

export const Variation = {
  schema: z
    .object({
      item_id: z.string(),
      id: z.string(),
      data: z
        .object({
          is_default: z.boolean().nullish(),
        })
        .nullish()
        .and(ItemMetaData.schema),
      suggested_score: z.number().nullish(),
      name: z.string().nullish(),
      updated_at: z.date({ coerce: true }).nullish(),
    })
    .transform(({ item_id, id, data, suggested_score, name, updated_at }) => ({
      itemId: item_id,
      id,
      data,
      suggestedScore: suggested_score,
      name,
      updatedAt: updated_at,
    })),
};

export const ListResponse = z
  .object({
    variations: Variation.schema.array(),
    total_count: z.number().nonnegative(),
  })
  .transform(({ variations, total_count }) => ({
    variations,
    totalCount: total_count,
  }));

export type VariationData = z.input<typeof Variation.schema>;
export type Variation = z.output<typeof Variation.schema>;
export type ListResponseData = z.input<typeof ListResponse>;
export type ListResponse = z.output<typeof ListResponse>;

export type VariationSlice = {
  displayName: string;
  value: string;
  imageUrl?: string;
  variationId?: string;
};
