import React from "react";
import { useLocation } from "react-router-dom";

import useFeatureToggle from "app/hooks/useFeatureToggle";
import FeatureFlags from "utils/featureFlags";
import { Link } from "app/components/Navigation/shared/MenuUI";

export default function SponsoredListings() {
  const location = useLocation();
  const isRetailMediaPageEnabled = useFeatureToggle(
    FeatureFlags.RetailMediaPage
  );

  if (!isRetailMediaPageEnabled) {
    return null;
  }

  return (
    <Link
      to="/dashboard/sponsored-listings/campaigns"
      isActive={
        location.pathname.startsWith("/dashboard/sponsored-listings") ||
        location.pathname.startsWith("/dashboard/retail-media")
      }
    >
      Sponsored Listings
    </Link>
  );
}
