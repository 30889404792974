import {
  CommonPageGroupArgs,
  PageGroup,
} from "app/components/shared/shared/types";
import { getIsHistorySupported } from "app/components/shared/shared/utils";
import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

export default function ({
  userHasSso,
  isAuthServiceEnabled,
  canUpdateCompany,
  path,
  canManageUsers,
}: {
  userHasSso: boolean;
  isAuthServiceEnabled: boolean;
  canUpdateCompany?: boolean;
  canManageUsers?: boolean;
} & CommonPageGroupArgs): PageGroup {
  const isShowSubscriptionPage = isFlagEnabled(
    FeatureFlags.EnableSubscriptionPage
  );
  const isUserRoleCreationDisabled = isFlagEnabled(
    FeatureFlags.DisableUserRoleCreation
  );

  return {
    items: [
      {
        label: "Profile",
        href: "/dashboard/accounts/profile",
        activePaths: ["/dashboard/accounts/profile"],
        isEnabled: !isAuthServiceEnabled,
        isHistorySupported: false,
        hasVisited: true,
        isSubscribed: true,
        breadcrumbs: [{ label: "Accounts" }, { label: "Profile" }],
        analyticsLabel: "Profile",
      },
      {
        label: "Profile",
        href: "/dashboard/accounts_v2/profile",
        activePaths: ["/dashboard/accounts_v2/profile"],
        isEnabled: isAuthServiceEnabled,
        isHistorySupported: getIsHistorySupported(
          "/dashboard/accounts_v2/profile",
          path
        ),
        hasVisited: true,
        isSubscribed: true,
        breadcrumbs: [{ label: "Accounts" }, { label: "Profile" }],
        analyticsLabel: "Profile",
      },
      {
        label: "Password",
        href: "/dashboard/accounts/password",
        activePaths: ["/dashboard/accounts/password"],
        isEnabled: !userHasSso && !isAuthServiceEnabled,
        isHistorySupported: false,
        hasVisited: true,
        isSubscribed: true,
        breadcrumbs: [{ label: "Accounts" }, { label: "Password" }],
        analyticsLabel: "Password",
      },
      {
        label: "Subscription",
        href: "/dashboard/accounts/subscription",
        activePaths: ["/dashboard/accounts/subscription"],
        isEnabled: !!canUpdateCompany && isShowSubscriptionPage,
        isHistorySupported: false,
        hasVisited: true,
        isSubscribed: true,
        breadcrumbs: [{ label: "Accounts" }, { label: "Subscription" }],
        analyticsLabel: "Subscription",
      },
      {
        label: "Team members",
        href: "/dashboard/accounts/team",
        activePaths: ["/dashboard/accounts/team"],
        isEnabled: !!canUpdateCompany && !isAuthServiceEnabled,
        isHistorySupported: false,
        hasVisited: true,
        isSubscribed: true,
        breadcrumbs: [{ label: "Accounts" }, { label: "Team" }],
        analyticsLabel: "Team_Members",
      },
      {
        label: "Team members",
        href: "/dashboard/accounts_v2/user_management",
        activePaths: [
          "/dashboard/accounts_v2/user_management",
          "/dashboard/accounts_v2/user_management/users",
          "/dashboard/accounts_v2/user_management/user-roles",
          ...(isUserRoleCreationDisabled
            ? []
            : ["/dashboard/accounts_v2/user_management/user-roles/new"]),
        ],
        isEnabled: !!canManageUsers && isAuthServiceEnabled,
        isHistorySupported: getIsHistorySupported(
          "/dashboard/accounts_v2/user_management",
          path
        ),
        hasVisited: true,
        isSubscribed: true,
        breadcrumbs: [{ label: "Accounts" }, { label: "Team" }],
        analyticsLabel: "Team_Members",
      },
    ],
    index: 4,
    label: "Accounts",
    icon: "person-16",
    analyticsLabel: "User_Profile",
  };
}
