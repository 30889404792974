import {
  useDeleteAuthToken,
  useDeleteAdminToken,
} from "app/queries/authentication/useDeleteTokens";
import { getAdminAuthServiceToken } from "utils/authentication";

const useManageAuthTokens = () => {
  const adminToken = getAdminAuthServiceToken();

  const isImpersonating = !!adminToken;

  const { mutate: deleteAuthToken } = useDeleteAuthToken();
  const { mutate: deleteAdminToken } = useDeleteAdminToken();

  const logout = () => {
    deleteAuthToken();
    if (isImpersonating) {
      deleteAdminToken();
    }
  };

  const stopImpersonation = () => {
    deleteAuthToken();
  };

  return {
    isImpersonating,
    stopImpersonation,
    logout,
  };
};

export default useManageAuthTokens;
