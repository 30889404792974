import { AdminAuthApi } from "services/AuthApi";

import {
  GetTokenValidateResponse,
  TokenValidateResponse,
  TokenValidateResponseData,
} from "./validate";

export default async function validateAdminToken(): Promise<TokenValidateResponse> {
  const result = await AdminAuthApi.get<TokenValidateResponseData>([
    "tokens",
    "validate",
  ]);

  return GetTokenValidateResponse.fromGetTokenValidateResponseData(result);
}

validateAdminToken.key =
  "modules/authentication/actions/tokens/validateAdminToken";
