// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { CHAKRA_ICONS } from "./IconDetails";

export type CHAKRA_ICONS_KEYS = keyof typeof CHAKRA_ICONS;

export const CHAKRA_ICONS_ARRAY = Object.keys(
  CHAKRA_ICONS
) as CHAKRA_ICONS_KEYS[];

export const CHAKRA_ICONS_16 = CHAKRA_ICONS_ARRAY.filter(
  (item: CHAKRA_ICONS_KEYS) => item.indexOf("16") !== -1
);

export const CHAKRA_ICONS_24 = CHAKRA_ICONS_ARRAY.filter(
  (item: CHAKRA_ICONS_KEYS) => item.indexOf("24") !== -1
);

export type CHAKRA_ICONS_16_TYPE = (typeof CHAKRA_ICONS_16)[number];

export type CHAKRA_ICONS_24_TYPE = (typeof CHAKRA_ICONS_24)[number];
