import React, { ReactNode } from "react";
import { ChakraProvider, useEnvironment } from "@chakra-ui/react";

import theme from "../designSystem/theme";

export default function ThemeProvider({ children, resetCSS = false }: Props) {
  const env = useEnvironment();

  return (
    <ChakraProvider
      theme={theme}
      resetCSS={resetCSS}
      disableEnvironment
      environment={env}
    >
      {children}
    </ChakraProvider>
  );
}

type Props = {
  resetCSS?: boolean;
  children: ReactNode;
};
