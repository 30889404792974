import React, { forwardRef, useState, useEffect } from "react";
import {
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Flex,
  Text,
} from "@chakra-ui/react";

import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./SearchBar.styles";

type SearchBarProps = {
  query?: string;
  size?: "sm" | "lg";
  placeholder?: string;
  isAlwaysExpanded?: boolean;
  isRoundEdged?: boolean;
  autoFocus?: boolean;
  shouldReset?: boolean;
  onChange?: (query: string) => void;
  onSearch?: (query: string) => void;
  onActive?: (isActive: boolean) => void;
  resultCount?: number;
};

export const SearchBar = forwardRef<HTMLInputElement | null, SearchBarProps>(
  (
    {
      query = "",
      size = "lg",
      placeholder = "Search",
      isAlwaysExpanded = false,
      isRoundEdged = true,
      autoFocus = false,
      shouldReset = false,
      onChange,
      onSearch,
      onActive,
      resultCount,
    },
    ref
    // cognitive-complexity rule is temporarily disabled for this function, please refactor this function
    // whenever possible.
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const [searchQuery, setSearchQuery] = useState(query);
    const [isHover, setHover] = useState(false);
    const [isFocused, setFocus] = useState(false);

    const isSizeLg = size === "lg";
    const isFilled = searchQuery !== "";
    const isSearchIconDisabled =
      isAlwaysExpanded && !isHover && !isFocused && !isFilled;

    useEffect(() => {
      if (query !== searchQuery && shouldReset) {
        setSearchQuery(query);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, shouldReset]);

    const withResultCount = resultCount !== undefined;

    return (
      <Flex {...styles.searchBarContainer(isSizeLg)}>
        <InputGroup
          {...styles.searchBarinputGroup({
            isFocused,
            isAlwaysExpanded,
            isSizeLg,
            isFilled,
          })}
          onFocus={() => {
            setFocus(true);
            if (onActive) {
              onActive(true);
            }
          }}
          onBlur={() => {
            setFocus(false);
            if (!isFilled && onActive) {
              onActive(false);
            }
            if (!isFilled && onSearch) {
              onSearch("");
            }
          }}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => {
            setHover(false);
          }}
        >
          <InputLeftElement {...styles.searchBarLeft(isSizeLg).container}>
            {isSizeLg ? (
              <ChakraIcon
                icon="search-24"
                color={isSearchIconDisabled ? "grey" : "grey-dark"}
              />
            ) : (
              <ChakraIcon
                icon="search-small-16"
                color={isSearchIconDisabled ? "grey" : "grey-dark"}
              />
            )}
          </InputLeftElement>
          <ChakraInput
            ref={ref}
            {...styles.searchBarInput({
              isHover,
              isSizeLg,
              isAlwaysExpanded,
              isRoundEdged,
              isFilled,
              withResultCount: withResultCount && isFilled,
            })}
            placeholder={placeholder}
            value={searchQuery}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (onChange) {
                onChange(e.target.value);
              }
              setSearchQuery(e.target.value);
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter" && onSearch) {
                onSearch(searchQuery);
              }
            }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
          />
          {isFilled && (
            <InputRightElement
              onMouseOver={() => setHover(true)}
              onMouseOut={() => setHover(false)}
              height="100%"
              width={withResultCount ? "110px" : "35px"}
              mr={withResultCount ? "-8px" : "0"}
            >
              {withResultCount ? (
                <Text m="0" textStyle="body3-italic" color="grey" mx="4px">
                  {`${resultCount} result${
                    resultCount > 1 || resultCount === 0 ? "s" : ""
                  }`}
                </Text>
              ) : null}

              <ChakraIcon
                icon="close-16"
                {...styles.searchBarCloseIcon(isSizeLg)}
                onClick={() => {
                  if (onChange) {
                    onChange("");
                  }
                  if (onSearch) {
                    onSearch("");
                  }
                  setSearchQuery("");
                  setFocus(false);
                  if (onActive) {
                    onActive(false);
                  }
                }}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </Flex>
    );
  }
);

SearchBar.displayName = "SearchBar";
