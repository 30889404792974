import React from "react";
import PropTypes from "prop-types";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import ErrorBoundary from "./ErrorBoundary";

/**
 * TODO: deprecated Use `@tanstack/react-query` instead
 */
export default class Loader extends React.Component {
  state = {
    data: null,
    loading: true,
    error: null,
  };

  static catchNotFound(error) {
    if (error.status && error.status === 404) {
      return;
    }

    throw error;
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.token) !== JSON.stringify(this.props.token)) {
      this.load();
    }
  }

  load = () => {
    const { onResponse } = this.props;

    this.setState({ error: null, loading: true }, () => {
      this.props
        .load()
        .then((data) => {
          if (!data) {
            data = {};
          }

          onResponse(data);
          this.setState({ data });
        })
        .catch((error) => {
          this.setState({ error });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    });
  };

  render() {
    const { children } = this.props;

    return (
      <ErrorBoundary
        displayError={(error) =>
          children({ error, data: undefined, reload: this.load })
        }
      >
        {() =>
          children({
            data: this.state.data,
            error: this.state.error,
            loading: this.state.loading,
            reload: this.load,
          })
        }
      </ErrorBoundary>
    );
  }
}

Loader.defaultProps = {
  onResponse: () => null,
};

Loader.propTypes = {
  token: PropTypes.any,
  children: PropTypes.func,
  load: PropTypes.func.isRequired,
  onResponse: PropTypes.func,
};
