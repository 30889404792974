import { TabListProps } from "@chakra-ui/react";

type Variant = "line" | "unstyled" | "switch";

export const tabsStyle = { w: "100%" };

export const tabStyle = (
  size: "sm" | "md" | "lg" | undefined,
  icon: React.ReactNode,
  variant?: Variant
) => {
  let textStyle = "heading4-uppercase";
  let fontSize = "16px";
  let hoverBg = "transparent";
  let hoverBorderBottom = "2px solid black";
  // size props
  switch (size) {
    case "sm":
      textStyle = "subheadline";
      fontSize = "12px";
      hoverBorderBottom = "2px solid initial";
      break;
    case "md":
      textStyle = "body1";
      fontSize = "15px";
      hoverBg = "grey-verylight-green";
      break;
    case "lg":
      hoverBorderBottom = "2px solid initial";
      break;
  }

  if (variant === "switch") {
    textStyle = "body3";
    return {
      tab: {
        outline: "0",
        border: "0",
        borderRadius: "6px",
        m: "0",
        p: "4px 16px",
        bg: "transparent",
        color: "grey-dark",
        cursor: "pointer",
        textStyle: textStyle,
        fontSize: "13px",
        _selected: {
          color: "black",
          bg: "white",
          boxShadow:
            "0px 1px 3px 0px rgba(18, 18, 105, 0.05), 0px 0.9px 6px 0px rgba(18, 18, 105, 0.05), 0px 0.4px 1px 0px rgba(18, 18, 105, 0.13)",
        },
        _focus: {
          boxShadow:
            "0px 1px 3px 0px rgba(18, 18, 105, 0.05), 0px 0.9px 6px 0px rgba(18, 18, 105, 0.05), 0px 0.4px 1px 0px rgba(18, 18, 105, 0.13) !important",
        },
        _hover: {
          bg: "#F0F2F3",
          color: "grey-dark",
          _selected: {
            // Temporary, will be removed once new tab is completed
            bg: "#F7F7F7",
            color: "black",
          },
          _disabled: {
            bg: "transparent",
            cursor: "not-allowed",
          },
        },
        _disabled: {
          bg: "grey-light-disabled",
          color: "grey-dark-disabled",
          cursor: "not-allowed",
        },
        _notFirst: {
          ml: "4px",
        },
      },
      tabIcon: {
        mr: icon ? "8px" : 0,
      },
    };
  }

  return {
    tab: {
      p: size === "sm" ? "12px 8px" : "16px 16px 14px",
      bg: "transparent",
      borderColor: "transparent",
      borderBottom: "2px solid transparent",
      color: "grey-dark",
      cursor: "pointer",
      textStyle: textStyle,
      textTransform:
        size === "md" ? ("none" as "none") : ("uppercase" as "uppercase"),
      fontSize: fontSize,
      _selected: {
        borderBottom: "2px solid black",
        color: "black",
      },
      _hover: {
        bg: hoverBg,
        color: "black",
        borderBottom: hoverBorderBottom,
        _disabled: {
          bg: "transparent",
          cursor: "not-allowed",
          color: "grey-disabled",
          borderBottom: "2px solid transparent",
        },
      },
      _disabled: {
        bg: "transparent",
        cursor: "not-allowed",
        color: "grey-disabled",
        borderBottom: "2px solid transparent",
      },
    },
    tabIcon: {
      mr: icon ? "8px" : 0,
    },
  };
};

export const tabListStyle = (variant?: Variant) => {
  if (variant === "switch") {
    return {
      border: "none",
      bg: "grey-pale",
      borderRadius: "8px",
      p: "2px",
    };
  }

  return {
    borderColor:
      variant === "line" ? "grey-verylight-green" : "grey-light-blue",
  };
};

export const tabListStickyStyle: TabListProps = {
  position: "sticky",
  top: 0,
  zIndex: 10,
  bg: "white",
};

export const tabPanelStyle = {
  p: 0,
};

export const tabSideStyle = {
  tabSideContainer: {
    ml: "auto",
    alignItems: "center",
  },
  tabSideText: {
    textAlign: "right" as "right",
    textStyle: "body2-italic",
    color: "grey",
  },
};

export const tabLinkStyle = {
  _hover: {
    textDecoration: "none",
  },
  _focus: {
    textDecoration: "none",
    backgroundColor: "transparent",
  },
};
