import * as actions from "modules/authentication/actions";

import getQueryHook from "app/hooks/getQueryHook";

const useAdminTokenValidate = getQueryHook(
  actions.validateAdminToken.key,
  () => actions.validateAdminToken,
  {
    refetchOnMount: true,
  }
);

export default useAdminTokenValidate;
