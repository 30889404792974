import * as z from "zod";

import { AuthOperation } from "app/auth";
import { convertFromApiFormat } from "utils/datesApi";

export type UserData = {
  created_at: string;
  email: string;
  first_name: string;
  id: string;
  last_login_at: string;
  last_name: string;
  sign_in_count: number;
};

export type User = {
  createdAt: Date | null;
  email: string;
  firstName: string;
  id: string;
  lastLoginAt: Date | null;
  lastName: string;
  signInCount: number;
};

export type NextPageCursor = {
  nextPageCursor: string | null;
};

export type NextPageCursorData = {
  next_page_cursor: string | null;
};

export type Company = {
  defaultSessionTimeout: number;
  displayName: string;
  id: string;
  slug: string;
  type: string;
};

export const PermissionData = z.object({
  scope: z.string(),
  resources: z.array(z.string()),
  operation: z.nativeEnum(AuthOperation),
});

export const RoleData = z.object({
  created_at: z.string(),
  default_admin_role: z.boolean(),
  default_basic_role: z.boolean(),
  description: z.string().nullable(),
  display_name: z.string(),
  id: z.string(),
  modified_at: z.string().nullish(),
  modified_by: z.string().nullish(),
  permissions: z.array(PermissionData),
});

export const Role = RoleData.transform((data) => ({
  createdAt: data.created_at ? convertFromApiFormat(data.created_at) : null,
  defaultAdminRole: data.default_admin_role,
  defaultBasicRole: data.default_basic_role,
  description: data.description,
  displayName: data.display_name,
  id: data.id,
  modifiedAt: data.modified_at ? convertFromApiFormat(data.modified_at) : null,
  modifiedBy: data.modified_by,
  permissions: data.permissions,
}));

export type RoleData = z.output<typeof RoleData>;
export type Role = z.output<typeof Role>;
