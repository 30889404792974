import styled from "@emotion/styled";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme from "components/theme";

const Container = styled.div`
  background-color: ${theme.colors.backgroundDefault};
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
  padding: 40px 30px;
  overflow: auto;
`;

export default Container;
