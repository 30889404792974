import { useEffect } from "react";

import reportError from "services/ReportError";
import "vendor/delighted";

export default function DelightedSurvey() {
  const currentUserElement = document.querySelector<HTMLMetaElement>(
    'meta[name="current_user"]'
  );

  const currentUser =
    currentUserElement &&
    currentUserElement.content &&
    JSON.parse(currentUserElement.content);

  const currentCompanyId = document.querySelector<HTMLMetaElement>(
    'meta[name="current_company_id"]'
  );

  const currentCompanyName = document.querySelector<HTMLMetaElement>(
    'meta[name="current_company_name"]'
  );

  const environment = document.querySelector<HTMLMetaElement>(
    'meta[name="environment"]'
  );

  const currentAdminUserEmail = document.querySelector<HTMLMetaElement>(
    'meta[name="current_admin_user_email"]'
  );

  const impersonatingUserEmail = document.querySelector<HTMLMetaElement>(
    'meta[name="impersonating_user_email"]'
  );

  // We consider as `isImpersonating` if:
  // - The current admin user e-mail is set (meaning user is logged in to the admin dashboard)
  // - The impersonating user e-mail is set (meaning user is impersonating another user)
  const isImpersonating = !!(
    currentAdminUserEmail?.content || impersonatingUserEmail?.content
  );

  // Do not survey constructor users
  const enableSurvey =
    currentUser?.email &&
    currentUser?.survey_eligible &&
    !isConstructorEmail(currentUser.email) &&
    !isImpersonating;

  useEffect(() => {
    if (enableSurvey) {
      try {
        delighted.survey({
          name: currentUser?.first_name,
          email: currentUser?.email,
          initialDelay: 30,
          createdAt: currentUser?.created_at,
          properties: {
            company_id: currentCompanyId?.content,
            company_name: currentCompanyName?.content,
            environment: environment?.content,
            user_created_at: currentUser?.created_at,
          },
          onShow() {
            const el = document.getElementsByClassName(
              "delighted-web-survey"
            )[0] as HTMLElement;
            el.style.setProperty("width", "50%", "important");
            el.style.setProperty("margin", "0 auto", "important");
            el.style.setProperty("min-width", "520px", "important");
          },
        });
      } catch (error) {
        reportError(new Error(`Failed to run survey: ${error}`));
      }
    }
    // @refactoring Forbid deactivation of hook dependencies (https://constructor.slab.com/posts/rfc-remove-deactivation-of-the-es-lint-rule-for-hook-dependenciesoli-vk98awgw)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

function isConstructorEmail(email: string): boolean {
  return (
    email.endsWith("@constructor.io") ||
    email.endsWith("@cnstrctr.io") ||
    email.endsWith("@buyconstructor.io") ||
    email.endsWith("@getconstructor.io")
  );
}
