import assertNever from "assert-never";

export const Tag = (
  isDisabled: boolean,
  colorScheme: string | undefined,
  size: "sm" | "md" | "lg",
  hasIcon: boolean,
  showClose: boolean,
  onClick: boolean,
  alignItems?: "center" | "baseline"
) => {
  // colors
  let bgColor = "white";
  let borderColor = "grey-light-green";
  let hoverBgColor = "grey-light-green";
  let color = "grey-dark";

  switch (colorScheme) {
    case "yellow":
      color = "yellow-dark";
      bgColor = "yellow-very-light";
      borderColor = "yellow-very-light";
      hoverBgColor = "yellow-very-light";
      break;
    case "purple":
      color = "purple-light";
      bgColor = "purple-light-2";
      borderColor = "purple-light-2";
      hoverBgColor = "purple-light-2";
      break;
    case "grey":
      bgColor = "gray.200";
      borderColor = "gray.200";
      break;
  }

  // padding
  let paddingLeft;
  let paddingRight;

  switch (size) {
    case "lg":
      paddingLeft = 16;
      paddingRight = 16;
      break;
    case "md":
      paddingLeft = 12;
      paddingRight = 12;
      break;
    case "sm":
      paddingLeft = 8;
      paddingRight = 8;
      break;
    default:
      assertNever(size);
  }

  if (hasIcon) {
    paddingLeft -= 4;
  }
  if (showClose) {
    paddingRight -= 4;
  }

  return {
    lineHeight: 1.6,
    display: "inline-flex",
    flexFlow: "row nowrap",
    alignItems: alignItems ?? "center",
    backgroundColor: isDisabled ? "grey-disabled" : bgColor,
    paddingY: `${getTagPaddingY(size)}`,
    paddingLeft: `${paddingLeft}px`,
    paddingRight: `${paddingRight}px`,
    border: "2px solid",
    borderColor: isDisabled ? "grey-disabled" : borderColor,
    borderRadius: "4px",
    boxShadow: "none",
    color: isDisabled ? "grey" : color,
    cursor: isDisabled ? "not-allowed" : onClick ? "pointer" : "default",
    width: "fit-content",
    _hover: {
      backgroundColor: isDisabled ? "grey-disabled" : hoverBgColor,
    },
  };
};

export const TagContainer = {
  alignItems: "center",
  justifyContent: "center",
};

export const TagIcon = (isDisabled: boolean) => {
  return {
    color: isDisabled ? "grey" : "currentColor",
  };
};

export const TagLabel = (
  isDisabled: boolean,
  hasIcon: boolean,
  showClose: boolean,
  onClick: boolean
) => {
  let marginRight = "0px";
  if (hasIcon && showClose) {
    marginRight = "2px";
  } else if (showClose) {
    marginRight = "4px";
  }

  let marginLeft = "0px";
  if (hasIcon) {
    marginLeft = "2px";
  }

  return {
    marginRight: marginRight,
    marginLeft: marginLeft,
    textStyle: "body2",
    cursor: isDisabled ? "not-allowed" : onClick ? "pointer" : "default",
  };
};

export const TagCloseButton = (isDisabled: boolean) => {
  return {
    height: "16px",
    width: "16px",
    border: "none",
    backgroundColor: "transparent",
    color: "grey-dark",
    cursor: isDisabled ? "not-allowed" : "pointer",
    _hover: {
      color: "grey-dark",
    },
    alignSelf: "center",
  };
};

const getTagPaddingY = (size: "sm" | "md" | "lg"): string => {
  switch (size) {
    case "sm":
      return "0px";
    case "md":
      return "4px";
    case "lg":
      return "6px";
    default:
      assertNever(size);
  }
};
