import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme from "components/theme";
// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import Icon from "components/Modules/Icon";

const style = css`
  border-radius: 8px;
  background-color: white;
  display: block;
  box-shadow: var(--frame-shadow);
`;

const Box = styled.div`
  ${style};
  padding: 32px 30px;
`;

export default Box;

// Container is a `Box` without padding
export const Container = styled.div`
  ${style};
`;

// Primary is a container with left green border
export const Primary = styled(Container)`
  border-left: 4px solid var(--primary-green);
`;

const BaseHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Header = styled(BaseHeader)`
  padding: 16px 24px;
`;

export const HeaderPrimary = styled(Header)`
  border-bottom: 1px solid var(--neutral-greenishgrey);
`;

export const HeaderSecondary = styled(Header)`
  border-bottom: 1px solid var(--pale-grey);
`;

export const TitlePrimary = styled.div`
  font-family: var(--font-header);
  font-size: 16px;
  color: var(--neutral-black);
`;

export const TitleSecondary = styled.div`
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--grey-dark);
`;

export const Content = styled.div`
  padding: 24px;
`;

export const Comment = styled.div`
  font-size: 14px;
  font-style: italic;
  color: var(--neutral-lightgrey);
  margin-top: 8px;
`;

export function LinkBox({ href, contentProps, children, ...props }: Props) {
  if (!href) {
    return (
      <Box {...props}>
        <LinkContent {...contentProps}>{children}</LinkContent>
      </Box>
    );
  }

  return (
    <Link href={href} {...props}>
      <LinkContent {...contentProps}>{children}</LinkContent>
      <Icon icon="chevron" />
    </Link>
  );
}

type Props = {
  href: string;
  contentProps?: React.ComponentProps<typeof Content>;
  children: React.ReactNode;
};

const Link = styled.a`
  ${style}

  padding: 32px 30px;
  display: flex;
  align-items: center;
  color: ${theme.colors.textGrayBlue};
  padding-right: 8px;
  transition: box-shadow 0.3s ease-in-out;

  &:active,
  &:visited,
  &:hover,
  &:link {
    text-decoration: none;
    color: ${theme.colors.textGrayBlue};
  }

  &:hover {
    cursor: pointer;
    box-shadow: var(--heavy-shadow);
  }
`;

const LinkContent = styled.div`
  width: 100%;
  height: 100%;
`;
