import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import isPropValid from "@emotion/is-prop-valid";
import { Box, Flex } from "@chakra-ui/react";

import * as currentUser from "modules/currentUser";
import * as IndexKeyId from "utils/IndexKeyId";
import * as IndexKeyValue from "utils/IndexKeyValue";
import URI from "utils/urijs";
import * as CurrentIndex from "app/hooks/useCurrentIndex";
import Link from "components/Modules/Link";
import OptionsMenu, * as Options from "components/Modules/OptionsMenu";
import RequestBox from "components/Modules/RequestBox";
import * as indexes from "modules/indexes";
import useListIndexes from "app/queries/indexes/useListIndexes";
import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";
import { SearchBar } from "components/Modules/Widgets/SearchBar";

export default function Indexes({
  allowAll,
  indexes: originalIndexes,
  closeDropdown,
  baseUrl,
  keepQueryParameters,
  onChangeDefault,
}: Props) {
  const [currentIndex, { fetching: isCurrentIndexFetching }] =
    CurrentIndex.useApi();
  const isSearchIndexEnabled = isFlagEnabled(
    FeatureFlags.EnableSearchInIndexSelection
  );
  const [searchValue, setSearchValue] = useState("");

  const {
    data: list,
    isFetching,
    error,
  } = useListIndexes({ originalIndexes, onlyActive: true });

  const filteredIndexes =
    searchValue.length > 0
      ? list?.filter((index) =>
          index.name
            .toLocaleUpperCase()
            .includes(searchValue.toLocaleUpperCase())
        )
      : list;

  const { defaultIndexKey } = currentUser.get() || { defaultIndexKey: null };

  return (
    <OptionsMenu
      css={css`
        min-width: 320px;
      `}
    >
      <RequestBox
        fetching={isFetching}
        error={error}
        spinnerContainerProps={{ minHeight: 64 }}
        shouldDelaySpinner
      >
        {isSearchIndexEnabled && (
          <Box p="8px" pt="12px">
            <SearchBar
              size="sm"
              isAlwaysExpanded
              placeholder="Search"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              query={searchValue}
              onChange={setSearchValue}
            />
          </Box>
        )}

        {searchValue.length === 0 && allowAll && (
          <Index
            onClick={() => closeDropdown()}
            to={getIndexUrl(baseUrl, "", { keepQueryParameters })}
            isCurrent={!isCurrentIndexFetching && currentIndex === null}
          >
            All Indexes
          </Index>
        )}

        {(filteredIndexes || []).map((index) => (
          <Index
            key={index.indexKey}
            onClick={() => {
              const shouldUseNewId = isFlagEnabled(
                FeatureFlags.EnableAcidDeprecate
              );

              if (shouldUseNewId) {
                IndexKeyValue.set(index.indexKey);
              } else {
                IndexKeyId.set(index.id.toString());
              }

              closeDropdown();
            }}
            to={getIndexUrl(baseUrl, index.indexKey, {
              keepQueryParameters,
            })}
            isCurrent={currentIndex?.indexKey === index.indexKey}
          >
            {index.name}

            {index.indexKey === defaultIndexKey && <Comment>Default</Comment>}
          </Index>
        ))}

        {filteredIndexes && filteredIndexes.length === 0 && (
          <Flex
            py="40px"
            justifyContent="center"
            alignItems="center"
          >{`No indexes with "${searchValue}" found.`}</Flex>
        )}

        {(list || []).length > 1 && searchValue.length === 0 && (
          <Options.OptionButton
            css={css`
              padding-top: 18px;
              padding-bottom: 18px;
              color: var(--neutral-black);
            `}
            onClick={onChangeDefault}
          >
            {defaultIndexKey ? "Change" : "Set"} my default index
          </Options.OptionButton>
        )}
      </RequestBox>
    </OptionsMenu>
  );
}

type Props = {
  allowAll: boolean;
  indexes?: indexes.Index[];
  closeDropdown: () => void;
  baseUrl: string | null;
  keepQueryParameters: string[];
  onChangeDefault: () => void;
};

function getIndexUrl(
  baseUrl: string | null,
  indexKey: string,
  { keepQueryParameters }: { keepQueryParameters: string[] }
): string {
  const uri = baseUrl ? URI(baseUrl) : URI();

  uri.fragment("");

  Object.entries(uri.search(true)).forEach(([key]) => {
    if (!keepQueryParameters.includes(key)) {
      uri.removeSearch(key);
    }
  });

  uri.setSearch("index_key", indexKey);

  return uri.resource();
}

const Comment = styled.div`
  font-style: italic;
  font-size: 14px;
  color: var(--neutral-lightgrey);
  margin-left: 32px;
`;

const Index = styled(Options.Option, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "isCurrent",
})<{ isCurrent: boolean }>`
  display: flex;
  justify-content: space-between;

  ${({ isCurrent }) => isCurrent && `background: rgba(228, 235, 239, 0.2);`}

  & + a:before,
  & + button:before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    border-top: 2px solid var(--neutral-greenishligthgrey);
  }
`.withComponent(Link);
