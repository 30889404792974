import useCurrentUser from "app/hooks/useCurrentUser";
import createPersistedState from "vendor/use-persisted-state";

const useVisitedState = createPersistedState<Record<string, VisitedStates>>(
  "VISITED_DASHBOARD_TABS"
);

const useVisitedTabs = (): Return => {
  const { id: currentUserId } = useCurrentUser();
  const [allStates, setAllStates] = useVisitedState();

  return {
    visitedTabs: allStates?.[currentUserId] || {},
    setTabVisited: (tab: string) => {
      const currentUserState = allStates?.[currentUserId] || {};
      setAllStates((previousState) => ({
        ...previousState,
        [currentUserId]: {
          ...currentUserState,
          [tab]: true,
        },
      }));
    },
  };
};

export default useVisitedTabs;

type VisitedStates = {
  quizzes?: boolean;
  collections?: boolean;
  recommendations?: boolean;
  notificationCampaigns?: boolean;
};

export type Return = {
  visitedTabs: VisitedStates;
  setTabVisited: (s: string) => void;
};
