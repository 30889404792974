import React from "react";

export const PageLayoutWrapperContext = React.createContext<{
  isLayoutUpdating: boolean;
  updateMessage: null | string;
  setIsLayoutUpdating: (value: boolean) => void;
  setUpdateMessage: (value: string | null) => void;
}>({
  isLayoutUpdating: false,
  updateMessage: null,
  setIsLayoutUpdating: () => {},
  setUpdateMessage: () => {},
});

export const usePageLayoutWrapperContext = () => {
  const context = React.useContext(PageLayoutWrapperContext);

  return context;
};
