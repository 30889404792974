import React from "react";

import { Flex, Text } from "@chakra-ui/react";

import { Button } from "app/designSystem/components";
import Graphics from "components/Modules/Graphics";
import * as routes from "utils/routes";
import { isAuthenticationServiceSession } from "utils/authentication";

const AutomaticallyLoggedOutModal: React.FC = () => {
  return (
    <Flex
      alignItems="center"
      direction="column"
      height="100%"
      px="32px"
      width="100%"
    >
      <Graphics.Brand.LogoHorizontal height="45px" mt="140px" width="205px" />

      <Flex
        alignItems="center"
        bg="white"
        borderRadius="8px"
        boxShadow="var(--medium-shadow)"
        direction="column"
        maxWidth="570px"
        my="64px"
        pb="89px"
        pt="75px"
        px="32px"
        width="100%"
      >
        <Text
          textAlign="center"
          textColor="black"
          textStyle="heading2"
          m={0}
          mb="52px"
        >
          {isAuthenticationServiceSession()
            ? "Session Expired"
            : "Account Secured"}
        </Text>

        <Text
          m={0}
          maxWidth="338px"
          mb="58px"
          textAlign="center"
          textColor="grey-dark"
          textStyle="body2"
          width="100%"
        >
          {isAuthenticationServiceSession()
            ? "Your session expired. Please sign in again to continue."
            : "To ensure your account security, you've been logged out due to inactivity. Please log in to continue working."}
        </Text>

        <Button
          size="lg"
          onClick={() => {
            window.location.href = routes.signIn().toString();
          }}
        >
          Continue to login
        </Button>

        <Text m={0} mt="24px" textAlign="center" textColor="grey">
          Having trouble? Contact us at{" "}
          <a href="mailto:support@constructor.io">
            <Text as="span" textColor="black">
              support@constructor.io
            </Text>
          </a>
        </Text>
      </Flex>
    </Flex>
  );
};

export default AutomaticallyLoggedOutModal;
