import React, { useEffect } from "react";

import { useLocation, useRouteMatch } from "react-router-dom";

import {
  Events,
  setReferrer,
  useTracking,
} from "app/providers/TrackingProvider";
import { MountPoint } from "app/providers/ConfirmProvider";

function Wrapper(props: React.PropsWithChildren) {
  const { url, path } = useRouteMatch();
  const location = useLocation();

  const { trackEvent } = useTracking();

  useEffect(() => {
    const handleUnload = () => {
      // assume route is still loading
      if (url === "/" && path === "/") {
        return;
      }

      setReferrer({
        referrer: window.location.origin + url + location.search,
        pathReferrer: url,
        routeReferrer: path,
      });
    };

    // set referrers when going to and from
    // the pages that are fetched from server
    window.addEventListener("pagehide", handleUnload);

    // most probably it is not called but it is better to have clean up anyway
    return () => window.removeEventListener("pagehide", handleUnload);
  }, [url, path, location.search]);

  // covering client-side routing
  useEffect(() => {
    return () => {
      // assume route is still loading
      if (url === "/" && path === "/") {
        return;
      }

      setReferrer({
        referrer: window.location.origin + url + location.search,
        pathReferrer: url,
        routeReferrer: path,
      });
    };
  }, [url, path, location.search]);

  useEffect(() => {
    trackEvent(Events.PageTransition);
  }, [trackEvent, location]);

  return (
    <>
      {props.children}
      {/* We need to setup confirmation context on each page for tracking events */}
      <MountPoint />
    </>
  );
}

export default Wrapper;
