import Api from "services/Api";

import * as types from "./types";

export async function list({
  indexKey,
  section,
  itemIds = [],
  numResultsPerPage = 100,
  page = 1,
}: ListArgs): Promise<types.ListResponse> {
  const response = await Api.get<types.ListResponseData>(
    ["api", "v2", "variations"],
    {
      index_key: indexKey,
      section,
      num_results_per_page: numResultsPerPage,
      page,
      item_id: itemIds,
    }
  );

  return types.ListResponse.parse(response);
}

list.key = "modules/variations/list";

type ListArgs = {
  indexKey: string;
  section: string;
  itemIds?: string[];
  numResultsPerPage?: number;
  page?: number;
};
