import React from "react";
import PropTypes from "prop-types";
import { Flex } from "@chakra-ui/react";

import styled from "@emotion/styled";
import onClickOutside from "react-onclickoutside";

import { ChakraIcon } from "app/designSystem/components";

// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import { Action } from "components/Modules/Button";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme from "components/theme";

class NavbarDropdown extends React.Component {
  state = {
    isOpen: false,
  };

  closeDropdown = () => {
    this.props.onClose();

    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  };

  toggleOpen = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  handleClickOutside = () => this.closeDropdown();

  render() {
    const { headline, name, alignRight, children, disabled, ...props } =
      this.props;

    const { isOpen } = this.state;

    return (
      <Container {...props}>
        <Button
          type="button"
          onClick={this.toggleOpen}
          isOpen={isOpen}
          disabled={disabled}
        >
          <Headline>{headline}</Headline>
          <CurrentContainer>
            <CurrentName>{name}</CurrentName>

            <Flex marginLeft="8px">
              <ChakraIcon icon="arrow-drop-down-16" />
            </Flex>
          </CurrentContainer>
        </Button>

        {isOpen && (
          <Content alignRight={alignRight}>
            {children({ closeDropdown: this.closeDropdown })}
          </Content>
        )}
      </Container>
    );
  }
}

NavbarDropdown.propTypes = {
  headline: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  alignRight: PropTypes.bool,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
};

NavbarDropdown.defaultProps = {
  alignRight: false,
  onClose: () => {},
};

export default onClickOutside(NavbarDropdown);

const Container = styled.div`
  position: relative;
  min-height: 59px;
`;

const Button = styled(Action)`
  height: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: -8px;
  display: flex;
  flex-direction: column;
  padding-top: 14px;
  align-items: flex-start;
  justify-content: flex-start;

  background: ${({ isOpen }) =>
    isOpen ? theme.colors.backgroundGray : "transparent"};

  color: var(--neutral-grey);

  :disabled {
    color: var(--neutral-disabledgrey);
    cursor: default;
  }
`;

const Headline = styled.div`
  font-family: ${theme.fonts.text};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 12px;
`;

const CurrentContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const CurrentName = styled.div`
  display: inline-block;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: ${theme.fonts.text};
  font-size: 14px;
`;

const Content = styled.div`
  position: absolute;
  z-index: 100;
  min-width: 220px;
  top: 60px;
  ${(props) => props.alignRight && "right: 0"};
`;
