import { useLocation, useRouteMatch } from "react-router-dom";

export default function useTrackingLocation() {
  const routeMatch = useRouteMatch();
  const location = useLocation();

  return {
    routeMatchPath: routeMatch.path,
    routeMatchUrl: routeMatch.url,
    locationPathname: location.pathname,
    locationSearch: location.search,
  };
}
