import AuthApi from "services/AuthApi";

import { Permission } from "app/auth";

import { Role, RoleData } from "../shared/types";

/**
 * @description Creates a new role within a specified company.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-update-role
 * @returns {Role}
 */
export default async function updateRole({
  companyId,
  roleId,
  displayName,
  description,
  permissions,
}: Props): Promise<Role> {
  const result = await AuthApi.put<RoleData>(
    ["v1", "companies", companyId, "roles", roleId],
    {},
    {
      description,
      display_name: displayName,
      permissions,
    }
  );

  return Role.parse(result);
}

updateRole.key = "modules/authentication/actions/companies/updateRole";

type Props = {
  companyId: string;
  roleId: string;
  displayName: string;
  description: string;
  permissions: Permission[];
};
