import React from "react";
import InfoFilledBase from "@material-symbols/svg-400/rounded/info-fill.svg?react";

export type SVGProps = React.SVGProps<SVGSVGElement>;

export const InfoFilled = React.forwardRef(
  (
    { fill = "currentColor", ...props }: SVGProps,
    ref: React.Ref<SVGSVGElement>
  ) => <InfoFilledBase fill={fill} {...props} ref={ref} />
);

InfoFilled.displayName = "InfoFilled";
