import React, { createContext, useCallback, useContext } from "react";

import { sendAmplitudeEvent } from "utils/amplitude/events";
import { get } from "utils/IndexKeyValue";

import { getReferrer, setReferrer } from "./TrackingProvider/referrerActions";
import useTrackingLocation from "./TrackingProvider/useTrackingLocation";
import { normalizeText } from "./TrackingProvider/utils";

export type ContextType = {
  context: string;
};

export const defaultRootContextValue: ContextType = {
  context: "",
};

export const Context = createContext<ContextType>(defaultRootContextValue);

const useTrackingContext = () => {
  const { context } = useContext(Context);

  return context;
};

export const useTracking = () => {
  const trackingContext = useTrackingContext();
  const { routeMatchPath, routeMatchUrl, locationPathname, locationSearch } =
    useTrackingLocation();

  const trackEvent = useCallback(
    (
      eventName: string,
      eventProperties: Record<string, unknown> = {}
    ): void => {
      // assume route is still loading
      if (routeMatchPath === "/" && routeMatchUrl === "/") {
        return;
      }

      const [referrer, pathReferrer, routeReferrer] = getReferrer();

      const commonEventProperties: Record<string, unknown> = {
        url: window.location.origin + locationPathname + locationSearch,
        referrer,
        path: routeMatchUrl,
        pathReferrer,
        route: routeMatchPath,
        routeReferrer,
        host: window.location.host,
      };

      const indexKey = get();

      if (indexKey) {
        commonEventProperties.indexKey = indexKey;
      }

      if (trackingContext) {
        commonEventProperties.context = trackingContext;
      }

      sendAmplitudeEvent({
        eventName,
        eventProperties: {
          ...commonEventProperties,
          ...eventProperties,
        },
      });
    },
    [
      routeMatchUrl,
      routeMatchPath,
      locationPathname,
      locationSearch,
      trackingContext,
    ]
  );

  return { trackEvent };
};

const TrackingProvider: React.FC<
  React.PropsWithChildren<{ context: string }>
> = ({ children, context }) => {
  const parentContext = useTrackingContext();

  const newContext = parentContext ? parentContext + " / " + context : context;

  return (
    <Context.Provider value={{ context: newContext }}>
      {children}
    </Context.Provider>
  );
};

export { getReferrer, setReferrer, normalizeText };

export enum Events {
  PageTransition = "Page Transition",
  ButtonClick = "Button Click",
  LinkClick = "Link Click",
}

export default TrackingProvider;
