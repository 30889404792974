import React from "react";
import styled from "@emotion/styled";

import BaseLink from "components/Modules/Link";
// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme from "components/theme";
import { isFlagEnabled } from "utils/featureFlags";
import { FeatureFlags } from "utils/generatedFeatures";
import colors from "app/designSystem/theme/foundations/colors";
import { ChakraIcon } from "app/designSystem/components";

const isDesignSystemButtonV2 = isFlagEnabled(FeatureFlags.DesignSystemButtonV2);

function Link({ children, ...props }: Props) {
  return (
    <BaseLink {...props}>
      {children}
      <ChakraIcon icon="arrow-drop-left-16" />
    </BaseLink>
  );
}

type Props = {
  children: React.ReactNode;
} & React.ComponentProps<typeof BaseLink>;

const LinkWithChevron = styled(Link)`
  color: ${isDesignSystemButtonV2
    ? colors.blue[700]
    : theme.colors.headerBlack} !important;

  &:hover {
    color: ${isDesignSystemButtonV2
      ? colors.blue[700]
      : theme.colors.headerBlack};
  }
`;

export default LinkWithChevron;
