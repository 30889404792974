import * as z from "zod";

export type ItemMetaData = {
  [key: string]: unknown;
  deactivated: boolean;
  description?: string | null | undefined;
  facets?: Record<string, string[] | number[]> | null | undefined;
  groupIds?: string[] | null | undefined;
  imageUrl?: string | null | undefined;
  url?: string | null | undefined;
};

export const ItemMetaData = {
  /**
   * zod does not correctly distinct undefined and not defined values
   * @see https://github.com/colinhacks/zod/issues/635
   */
  schema: z
    .object({
      deactivated: z.boolean().catch(false),
      description: z.string().nullish(),
      facets: z
        .record(z.union([z.string().array(), z.number().array()]))
        .nullish(),
      group_ids: z.string().array().nullish(),
      image_url: z.string().nullish(),
      url: z.string().nullish(),
    })
    .passthrough()
    .transform((data) => {
      const { group_ids, image_url, ...rest } = data;

      const result: ItemMetaData = rest;
      if ("group_ids" in data) {
        result.groupIds = group_ids;
      }
      if ("image_url" in data) {
        result.imageUrl = image_url;
      }

      return result;
    }),
};

export type ItemMetaDataData = z.input<typeof ItemMetaData.schema>;

export type Item = z.infer<typeof Item.schema>;

export const Item = {
  schema: z
    .object({
      data: ItemMetaData.schema.nullish(),
      id: z.string(),
      name: z.string(),
      suggested_score: z.number().nullish(),
    })
    .transform(({ data, id, name, suggested_score }) => ({
      data,
      id,
      name,
      suggestedScore: suggested_score,
    })),
};

export type ItemData = z.input<typeof Item.schema>;

export const ListResponse = z
  .object({
    items: Item.schema.array(),
    total_count: z.number(),
  })
  .transform(({ items, total_count }) => ({
    items,
    totalCount: total_count,
  }));

export type ListResponse = z.infer<typeof ListResponse>;
export type ListResponseData = z.input<typeof ListResponse>;

export type CreateResponseData = z.input<typeof CreateResponse.schema>;
export type CreateResponse = z.output<typeof CreateResponse.schema>;

export type CreateItemPayload = {
  name: string;
  id: string;
  data: {
    url: string;
    imageUrl?: string | undefined;
    description?: string | undefined;
  };
};

export type CreateItemPayloadData = {
  name: string;
  id: string;
  data: {
    url: string;
    image_url?: string | undefined;
    description?: string | undefined;
  };
};

export const CreateItemPayloadData = {
  fromPayload(payload: CreateItemPayload): CreateItemPayloadData {
    return {
      name: payload.name,
      id: payload.id,
      data: {
        url: payload.data.url,
        image_url: payload.data.imageUrl,
        description: payload.data.description,
      },
    };
  },
};

export const CreateResponse = {
  schema: z
    .object({
      task_id: z.coerce.number(),
      task_status_path: z.string(),
    })
    .transform((data) => ({
      taskId: data.task_id,
      taskStatusPath: data.task_status_path,
    })),
};

export type UpdateItemPayload = {
  name: string;
  id: string;
  data?: {
    url: string;
    imageUrl?: string | undefined;
    description?: string | undefined;
  };
};

export type UpdateItemPayloadData = {
  name: string;
  id: string;
  data?: {
    url: string;
    image_url?: string | undefined;
    description?: string | undefined;
  };
};

export const UpdateItemPayloadData = {
  fromPayload(payload: UpdateItemPayload): UpdateItemPayloadData {
    const result: UpdateItemPayloadData = {
      name: payload.name,
      id: payload.id,
    };

    if (payload.data && payload.data.url) {
      result.data = {
        url: payload.data.url,
        image_url: payload.data.imageUrl,
        description: payload.data.description,
      };
    }

    return result;
  },
};

export type UpdateResponse = z.output<typeof UpdateResponse.schema>;
export type UpdateResponseData = z.input<typeof UpdateResponse.schema>;

export const UpdateResponse = CreateResponse;
