export default {
  black: "#181C25",
  "grey-dark": "#505A75",
  "grey-dark-2": "#737D91",
  "grey-dark-3": "#A0A0A8",
  "grey-dark-disabled": "#B4B9C3",
  "grey-2": "#BEC1CB",
  "grey-light-3": "#d3d3d3",
  grey: "#8F97A7",
  "grey-shadow": "#8F97A775",
  "grey-disabled": "#CED1D8",
  "grey-light-blue": "#E1E5F1",
  "grey-light-green": "#E4EBEF",
  "grey-light-green-2": "#E9F0F3",
  "grey-light-disabled": "#F5F6F7",
  "grey-verylight-green": "#EEF2F5",
  "grey-light-green-transparent": "#E4EBEFA3",
  "grey-pale": "#F7F9FA",
  "grey-pale-2": "#EFF4F6",
  white: "#FFFFFF",
  "white-disabled": "#F5F6F7",
  success: "#4ABB8B",
  "success-2": "#568500",
  "success-shadow": "#4ABB8B75",
  "success-light": "#EBF3DC",
  warning: "#FFC44D",
  "warning-shadow": "#FFC44D75",
  danger: "#E0182D",
  "danger-shadow": "#E0182D75",
  "danger-light": "#fad1d5",
  "danger-dark": "#BF1728",
  "danger-dark-2": "#4C1E27",
  "green-light": "#F1FAF6",
  "green-light-2": "#daf1e7",
  "old-green": "#4ABB8B",
  "green-dark": "#2B8F65",
  "blue-light": "#BCDCFE",
  "blue-light-1": "#EBF2FF",
  "blue-light-2": "#CCE2FA",
  "old-blue": "#1F89FB",
  "blue-dark": "#196EC9",
  "blue-dark-shadow": "#196EC975",
  "yellow-light": "#FFC44D",
  "yellow-very-light": "#FFF3DB",
  "old-yellow": "#FFAB00",
  "yellow-dark": "#E69A00",
  "turquoise-light-2": "#79E2F2",
  "turquoise-light-1": "#bdf1f9",
  turquoise: "#00B8D9",
  "turquoise-dark": "#008DA6",
  "purple-light": "#998DD9",
  "purple-light-2": "#E8E5F6",
  "purple-light-3": "#7F71CB",
  purple: "#6554C0",
  "purple-dark": "#403294",
  "magenta-light": "#F55F89",
  "magenta-light-2": "#FDF7F8",
  magenta: "#F3376B",
  "magenta-dark": "#C22C56",

  // Brands
  "brands-violet": "#CBB9EB",
  "brands-pink": "#E5BEC3",
  "brands-lightblue": "#A3D3FF",
  "brands-beige": "#F4D592",
  "brands-green": "#6D8C9F",

  //overlay
  "overlay-white": "#E2E8F0",

  // Information architecture
  "sidebar-blue": "#085DB8",

  //Skeleton
  "grey-light-disabled-2": "#EAEBED",

  // new design system foundation colors
  gray: {
    25: "#FCFCFC",
    50: "#F7F7F9",
    100: "#EFEFF4",
    200: "#D9D9E0",
    300: "#BEBEC6",
    400: "#A0A0A8",
    500: "#7F7F8D",
    600: "#5E5E6F",
    700: "#424252",
    800: "#2B2B39",
    900: "#1B1B26",
    1000: "#0F0F17",
  },
  green: {
    25: "#EBFBEE",
    50: "#D3F9D8",
    100: "#B2F2BB",
    200: "#8CE99A",
    300: "#69DB7C",
    400: "#51CF66",
    500: "#40C057",
    600: "#37B24D",
    700: "#2F9E44",
    800: "#2B8A3E",
  },
  red: {
    25: "#FFF5F5",
    50: "#FFE3E3",
    100: "#FFC9C9",
    200: "#FFA8A8",
    300: "#FF8787",
    400: "#FF6B6B",
    500: "#FA5252",
    600: "#F03E3E",
    700: "#E03131",
    800: "#C92A2A",
  },
  blue: {
    25: "#E7F5FF",
    50: "#D0EBFF",
    100: "#A5D8FF",
    200: "#74C0FC",
    300: "#4DABF7",
    400: "#339AF0",
    500: "#228BE6",
    600: "#1C7ED6",
    700: "#1971C2",
    800: "#1864AB",
  },
  yellow: {
    25: "#FFF9DB",
    50: "#FFF3BF",
    100: "#FFEC99",
    200: "#FFE066",
    300: "#FFD43B",
    400: "#FCC419",
    500: "#FAB005",
    600: "#F59F00",
    700: "#F08C00",
    800: "#E67700",
  },
};
