import React, { FC, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Slide } from "@chakra-ui/react";
import { z } from "zod";

import reportError from "services/ReportError";

// TODO: Replace this import with date-fns
// eslint-disable-next-line no-restricted-imports
import dayjs from "utils/dayjs";
import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import MaintainanceBanner from "./MaintainanceBanner";

const MaintainanceBannerWrapper: FC<Props> = ({ startTime, endTime }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const isMaintainanceBannerEnabled = isFlagEnabled(
    FeatureFlags.SearchandizingMaintenanceBanner
  );

  const isMaintainanceActive = isFlagEnabled(
    FeatureFlags.SearchandizingMaintenance
  );

  const container = document.getElementById("maintainance-banner");

  useEffect(() => {
    if (!isMaintainanceBannerEnabled || !container) {
      return;
    }

    const lastSeen = getLastSeen();

    if (lastSeen) {
      const { closedTimestamp, savedStartTime, savedEndTime } = lastSeen;

      const hasSameTime =
        isSameTime(savedStartTime, startTime) &&
        isSameTime(savedEndTime, endTime);

      if (hasSameTime) {
        return;
      }

      const now = dayjs.utc();

      const seenDuringLast24Hours =
        closedTimestamp && now.diff(closedTimestamp, "days") < 1;

      // we need to check additionally for the same time
      // in order to not neglect the previous return and let
      // the banner to be displayed
      if (seenDuringLast24Hours && hasSameTime) {
        return;
      }
    }

    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [isMaintainanceBannerEnabled, container, startTime, endTime]);

  if (shouldHideBanner(isMaintainanceBannerEnabled, isVisible, container)) {
    return null;
  }

  return ReactDOM.createPortal(
    <Slide
      direction="top"
      in={isVisible}
      style={{ zIndex: 20, position: "relative" }}
    >
      <MaintainanceBanner
        onClose={() => {
          localStorage.setItem(
            "maintainance_banner_last_seen",
            JSON.stringify({
              closedTimestamp: dayjs.utc().valueOf(),
              savedStartTimestamp: startTime.valueOf(),
              savedEndTimestamp: endTime.valueOf(),
            })
          );
        }}
        startTime={startTime}
        endTime={endTime}
        isActive={isMaintainanceActive}
      />
    </Slide>,
    container!
  );
};

type Props = {
  startTime: Date;
  endTime: Date;
};

type LastSeenValue = {
  closedTimestamp: Date;
  savedStartTime: Date | null;
  savedEndTime: Date | null;
};

const LastSeenSchema = z.object({
  closedTimestamp: z.number().transform(timestampToUTCDate),
  savedStartTimestamp: z.number().transform(timestampToUTCDate),
  savedEndTimestamp: z.number().transform(timestampToUTCDate),
});

function timestampToUTCDate(val: number): Date {
  return dayjs(val).utc().toDate();
}

function isSameTime(savedTimestamp: Date | null, currentTime: Date): boolean {
  return Boolean(
    savedTimestamp && dayjs(currentTime).utc().isSame(savedTimestamp)
  );
}

function shouldHideBanner(
  isFlagEnabled: boolean,
  isVisible: boolean,
  container: HTMLElement | null
) {
  return !isFlagEnabled || !isVisible || !container;
}

function getLastSeen(): LastSeenValue | null {
  const lastSeen = localStorage.getItem("maintainance_banner_last_seen");

  if (!lastSeen) {
    return null;
  }

  let parsedLastSeen: z.infer<typeof LastSeenSchema> | undefined;

  try {
    parsedLastSeen = JSON.parse(lastSeen) as z.infer<typeof LastSeenSchema>;
  } catch (err) {
    clearStorage(err as Error);
    return null;
  }

  const result = LastSeenSchema.safeParse(parsedLastSeen);

  if (!result.success) {
    clearStorage(result.error);
    return null;
  }

  return {
    ...result.data,
    savedStartTime: result.data.savedStartTimestamp,
    savedEndTime: result.data.savedEndTimestamp,
  };
}

function clearStorage(error: Error) {
  localStorage.removeItem("maintainance_banner_last_seen");
  reportError(
    `maintainance_banner_last_seen local storage key contains invalid value - ${error}`
  );
}

export default MaintainanceBannerWrapper;
