// @refactoring Forbid usage of `ramda` (https://constructor.slab.com/posts/deprecating-ramda-and-adopting-remeda-wlks8rn7)
// eslint-disable-next-line local-rules/no-ramda
import { splitEvery } from "ramda";
import { Required } from "utility-types";

import Api from "services/Api";
import * as indexes from "modules/indexes";
import * as types from "modules/items/types";
import * as itemsV2 from "modules/itemsV2";
import * as variationsV2 from "modules/variationsV2";

export async function get({
  id,
  indexKey,
  section,
}: GetArgs): Promise<types.Item | null> {
  const response = await itemsV2.list({
    indexKey,
    id: [id],
    section,
  });

  const [itemV2] = response.items;

  if (!itemV2) {
    return null;
  }

  const item = types.Item.fromV2Item(itemV2);

  const variations = await variationsV2.listAllPages({
    indexKey,
    section,
    itemIds: [id],
    numResultsPerPage: 100,
    capPage: 5,
  });

  item.variations = variations.map(types.ItemVariation.fromVariationV2);

  return item;
}

type GetArgs = {
  id: string;
  indexKey: string;
  section: string;
};

export const getKey = (indexKey: string, itemId: string) =>
  `${indexKey}-${itemId}-modules/items/get`;

export async function list({
  signal,
  ...options
}: Options): Promise<types.ListResponse> {
  const result = await Api.get<types.ListResponseData>("/api/items", options, {
    signal,
  });

  return {
    items: result.items.map(types.ItemData.toItem),
    totalCount: result.total_count,
  };
}

type Options = {
  signal?: AbortSignal;
  indexKey: string;
  section: string;
  id?: string[];
  page?: number;
  numResultsPerPage?: number;
};

export const listKey = "modules/items/list";

// listFromIndexes returns items from multiple indexes in following format:
// {
//   [indexName]: {
//     [section]: {
//       [itemId]: Item
//     }
//   }
// }
//
// If section is not present in request, the default index search section will
// be used and "_" will be used as section key in the response. Requested index
// keys and sections will always present in the response, even if the item was
// not found.
export async function listFromIndexes(
  request: ListFromIndexesRequest[]
): Promise<ItemsByIndexSection> {
  const sections = request.find(({ section }) => !section)
    ? (await indexes.list()).reduce<Record<string, string>>(
        (result, index) => ({
          ...result,
          [index.indexKey]: index.defaultSearchSection,
        }),
        {}
      )
    : {};

  const group = request.reduce<Record<string, string[]>>(
    (result, { indexKey, section, id }) => {
      const key = JSON.stringify({ indexKey, section });
      return { ...result, [key]: [...(result[key] ? result[key] : []), id] };
    },
    {}
  );

  const response = await Promise.all(
    Object.entries(group)
      .map(
        ([key, value]) =>
          [JSON.parse(key), value] as [
            { indexKey: string; section: string },
            string[]
          ]
      )
      .filter(([{ indexKey, section }]) => section || sections[indexKey])
      .map(async ([{ indexKey, section }, ids]) => {
        return {
          indexKey,
          section,
          items: (
            await list({
              indexKey,
              section: section || sections[indexKey],
              id: ids,
              numResultsPerPage: 100,
            })
          ).items,
        };
      })
  );

  const result: ItemsByIndexSection = {};

  request.forEach(({ indexKey, section }) => {
    result[indexKey] = result[indexKey] || {};
    result[indexKey][section || "_"] = result[indexKey][section || "_"] || {};
  });

  response.forEach(({ indexKey, section, items }) => {
    items.forEach((item) => {
      result[indexKey][section || "_"][item.id] = item;
    });
  });

  return result;
}

type ListFromIndexesRequest = {
  indexKey: string;
  section?: string;
  id: string;
};

export type ItemsByIndexSection = Record<
  string,
  Record<string, Record<string, types.Item>>
>;

export const listFromIndexesKey = "modules/items/listFromIndexesKey";

const MAX_ITEMS_PER_REQUEST = 100;

export async function listManyItems(
  options: Required<Options, "id">
): Promise<types.ListResponse> {
  const results = await Promise.all(
    splitEvery(MAX_ITEMS_PER_REQUEST, options.id).map((ids) =>
      list({ ...options, id: ids, numResultsPerPage: MAX_ITEMS_PER_REQUEST })
    )
  );

  return {
    items: results.flatMap((result) => result.items),
    totalCount: results.length > 0 ? results[0].totalCount : 0,
  };
}
