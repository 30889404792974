import React from "react";
import styled from "@emotion/styled";

// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import Icon from "components/Modules/Icon";
// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import { Action } from "components/Modules/Button";

export default function Close(
  props: React.ComponentProps<typeof CloseElement>
) {
  return (
    <CloseElement
      type="button"
      aria-label="Close"
      title="Close modal"
      {...props}
    >
      <CloseIcon icon="x-large" />
    </CloseElement>
  );
}

const CloseIcon = styled(Icon)`
  vertical-align: middle;
`;

const CloseElement = styled(Action)`
  padding: 5px;
  line-height: 0;
  background-color: transparent;
  text-decoration: none;
  vertical-align: unset;
  border: none;
  cursor: pointer;
  display: flex;
  :hover {
    border-radius: 4px;
    background: #e4ebef;
  }
`;
