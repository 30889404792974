import React from "react";
import { IconButton as ChakraIconButton } from "@chakra-ui/react";

import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";
import { Spinner } from "app/designSystem/components/Spinner";

import { Events, useTracking } from "app/providers/TrackingProvider";
import { isFlagEnabled } from "utils/featureFlags";
import { FeatureFlags } from "utils/generatedFeatures";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./IconButton.styles";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { IconButtonProps } from "./IconButton.types";

const getSpinnerColor = (
  variant: IconButtonProps["variant"],
  isDesignSystemButtonV2: boolean
) => {
  if (isDesignSystemButtonV2) {
    return "gray.400";
  }

  return variant === "ghost" ? "grey-dark" : "white";
};

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      icon,
      ariaLabel,
      variant = "outline",
      isDisabled = false,
      isLoading = false,
      onClick,
      title,
      dataTestId,
      type,
      ...rest
    },
    ref
  ) => {
    const { trackEvent } = useTracking();
    const isDesignSystemButtonV2 = isFlagEnabled(
      FeatureFlags.DesignSystemButtonV2
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      trackEvent(Events.ButtonClick, {
        title: ariaLabel || title,
        kind: variant,
      });

      onClick?.(event);
    };

    return (
      <ChakraIconButton
        ref={ref}
        variant={variant}
        aria-label={ariaLabel}
        isDisabled={isDisabled}
        isLoading={isLoading}
        title={title}
        type={type}
        data-testid={dataTestId}
        icon={
          icon && (
            <ChakraIcon
              icon={icon}
              {...(isDesignSystemButtonV2
                ? {}
                : styles.Icon(variant, isDisabled))}
            />
          )
        }
        onClick={handleClick}
        spinner={
          <Spinner
            size="sm"
            color={getSpinnerColor(variant, isDesignSystemButtonV2)}
          />
        }
        {...(isDesignSystemButtonV2
          ? {}
          : styles.IconButton(variant, isDisabled))}
        {...rest}
      />
    );
  }
);

IconButton.displayName = "IconButton";
