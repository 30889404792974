import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useFormik } from "formik";
import Dropdown from "react-bootstrap/Dropdown";
// TODO: Replace this import with zod
// eslint-disable-next-line no-restricted-imports
import * as Yup from "yup";
// Disabling linter for next line to deprecate the direct imports from react-query
// TODO: Do not use react-query outside of app/javascript/app/queries/**/*
// eslint-disable-next-line no-restricted-imports
import { useMutation } from "@tanstack/react-query";
import { isPresent } from "ts-is-present";

import * as currentUser from "modules/currentUser";
import { Index } from "modules/indexes";
// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import Icon from "components/Modules/Icon";
import { Body, Footer } from "components/Modules/Modal";
// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import Button from "components/Modules/Button";
// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import FormGroup from "components/Modules/FormGroup";
import OptionsMenu, { OptionButton } from "components/Modules/OptionsMenu";
import RequestBox from "components/Modules/RequestBox";
import { Checkbox } from "app/designSystem/components";

import useListIndexes from "app/queries/indexes/useListIndexes";

import useLoadIndexesItemCounts from "app/hooks/useLoadIndexesItemCounts";

import AutocompleteCompany from "./Body/AutocompleteCompany";

export default function Form({
  indexes: originalIndexes,
  allowChangingDefaultIndex = true,
  isDefaultValue,
  onCancel,
  onSelect,
  onSelectAll,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const { defaultIndexKey } = currentUser.get() || { defaultIndexKey: null };

  const {
    data: indexes,
    isFetching: indexesLoading,
    error: indexesError,
  } = useListIndexes({ originalIndexes });

  const { onView, onRefresh, indexesItemCounts } = useLoadIndexesItemCounts({
    indexes,
    enabled: isOpen,
  });

  const defaultIndex =
    (indexes && indexes.find((index) => index.indexKey === defaultIndexKey)) ||
    null;

  const getFormDefaults = () => ({
    index: defaultIndex,
    allIndexes: false,
    isDefault:
      isDefaultValue === undefined ? defaultIndex !== null : isDefaultValue,
  });

  useEffect(() => {
    formik.setValues(getFormDefaults());
    // @refactoring Forbid deactivation of hook dependencies (https://constructor.slab.com/posts/rfc-remove-deactivation-of-the-es-lint-rule-for-hook-dependenciesoli-vk98awgw)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultIndex]);

  const {
    mutateAsync: updateUser,
    isLoading,
    error,
  } = useMutation<
    currentUser.CurrentUser,
    Error,
    Parameters<typeof currentUser.update>[0]
  >((data) => currentUser.update(data));

  const formik = useFormik<{
    index: Index | null;
    allIndexes: boolean;
    isDefault: boolean;
  }>({
    validationSchema: Yup.object().shape({
      allIndexes: Yup.boolean(),
      index: Yup.object()
        .nullable()
        .when("allIndexes", {
          is: false,
          then: Yup.object().required("Please, select an index"),
        }),
    }),
    initialValues: getFormDefaults(),
    onSubmit: async (data) => {
      if (onSelectAll && data.allIndexes) {
        onSelectAll();
      } else {
        const newIndexKey = data.index!.indexKey;
        const isDefaultInitial = newIndexKey === defaultIndexKey;

        if (allowChangingDefaultIndex && isDefaultInitial !== data.isDefault) {
          try {
            await updateUser({
              defaultIndexKey: data.isDefault ? newIndexKey : null,
            });
          } catch (ignoredError) {
            // error happened by "error" variable in the outer scope
            return;
          }
        }

        onSelect(data.index!);
      }
    },
  });

  return (
    <RequestBox
      spinnerContainerProps={{ minHeight: 256 }}
      fetching={indexesLoading}
      error={indexesError}
      shouldDelaySpinner
    >
      {indexes && (
        <form onSubmit={formik.handleSubmit}>
          <Body
            css={css`
              padding-bottom: 24px;
            `}
          >
            {indexes.length === 0 ? (
              <span>
                Your index service is currently not configured. Please contact{" "}
                <a href="mailto:support@constructor.io">
                  support@constructor.io
                </a>{" "}
                for help with your configuration.
              </span>
            ) : (
              <>
                <FormGroup
                  id="autocomplete_company_id"
                  label="Index"
                  errors={[
                    formik.errors.index,
                    error && (error.message || error.toString()),
                  ].filter(isPresent)}
                  touched={!!formik.touched.index}
                  css={css`
                    :last-of-type {
                      margin-bottom: 42px;
                    }
                  `}
                >
                  <DropdownStyled
                    onToggle={(isOpen) => {
                      setIsOpen(isOpen);
                      onRefresh(!isOpen);
                    }}
                  >
                    {/*
                      Custom className is needed for the backward
                      compatibility with non-react pages
                    */}
                    <Toggle
                      title="Select index"
                      className="index-selection-dropdown"
                    >
                      {formik.values.allIndexes && "All Indexes"}

                      {!formik.values.allIndexes && formik.values.index?.name}

                      {!formik.values.allIndexes &&
                        !formik.values.index?.name && (
                          <span
                            css={css`
                              color: var(--neutral-lightgrey);
                            `}
                          >
                            Select index...
                          </span>
                        )}
                      <Icon
                        icon="chevron-down"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "8px",
                        }}
                      />
                    </Toggle>

                    <OptionsMenuStyled>
                      <Dropdown.Menu
                        style={{
                          width: "100%",
                          border: "none",
                          transform: "none !important",
                        }}
                      >
                        {onSelectAll && (
                          <Item
                            onSelect={() => {
                              formik.setValues({
                                ...formik.values,
                                allIndexes: true,
                                index: null,
                              });
                            }}
                          >
                            <div>All Indexes</div>
                          </Item>
                        )}

                        {indexes.map((index) => (
                          <AutocompleteCompany
                            key={index.indexKey}
                            index={index}
                            onSelect={() =>
                              formik.setValues({
                                ...formik.values,
                                allIndexes: false,
                                index,
                              })
                            }
                            totalItemsCount={
                              indexesItemCounts[index.indexKey]?.totalItemsCount
                            }
                            onView={onView}
                          />
                        ))}
                      </Dropdown.Menu>
                    </OptionsMenuStyled>
                  </DropdownStyled>
                </FormGroup>

                {allowChangingDefaultIndex && formik.values.index && (
                  <div>
                    <Default>
                      <Checkbox
                        name="isDefault"
                        onChange={formik.handleChange}
                        checked={formik.values.isDefault}
                        title="Default index is preselected on login"
                      >
                        <DefaultLabel>Make this my default index</DefaultLabel>
                      </Checkbox>
                    </Default>
                  </div>
                )}
              </>
            )}
          </Body>

          <Footer>
            <Button kind="secondary" type="button" onClick={onCancel}>
              Discard
            </Button>

            <Button
              kind="primary"
              type="submit"
              loading={isLoading || formik.isSubmitting}
            >
              Select
            </Button>
          </Footer>
        </form>
      )}
    </RequestBox>
  );
}

type Props = {
  indexes?: Index[];
  allowChangingDefaultIndex?: boolean;
  isDefaultValue?: boolean;
  onCancel: () => void;
  onSelect: (index: Index) => void;
  onSelectAll?: () => void;
};

const Item = styled(OptionButton)``.withComponent(Dropdown.Item);

const OptionsMenuStyled = styled(OptionsMenu)`
  .dropdown-menu {
    display: none;
    transform: none !important;
    top: auto !important;
    max-height: 256px;
    overflow: auto;

    z-index: 1;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: white;
    background-clip: padding-box;
    border-radius: 4px;
    box-shadow: var(--heavy-shadow);
  }
`;

const DropdownStyled = styled(Dropdown)`
  position: relative;

  &.dropdown.show ${OptionsMenuStyled} .dropdown-menu {
    display: block;
  }
`;

const Toggle = styled(Dropdown.Toggle)`
  width: 100%;
  padding: 10px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: white;
  background-image: none;
  border: 2px solid var(--neutral-greenishgrey);
  border-radius: 6px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  outline: none;
  box-shadow: none;
  display: block;
  font-family: var(--font-text);
  color: rgb(39, 46, 54);
  position: relative;

  &.btn {
    color: #272e36;
    text-align: left;
    background: white;
    border: 2px solid var(--neutral-greenishgrey);

    :focus {
      border-color: var(--primary-green);
    }

    :focus,
    :hover {
      background: white;
      color: #272e36;
    }
  }
`;

const Default = styled.label`
  display: block;
  margin-top: 24px;
`;

const DefaultLabel = styled.div`
  display: inline-block;
  margin-left: 8px;
`;
