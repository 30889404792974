import React from "react";

import {
  Events,
  useTracking,
  normalizeText,
} from "app/providers/TrackingProvider";

import * as UI from "components/Modules/Button/shared/UI";

/**
 * TODO: deprecated Use the design system `Button` component instead.
 */
const ButtonWithoutTracking = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<Props>
>(function ButtonComponent(
  {
    loading = false,
    size = "default",
    kind = "text",
    onClick,
    children,
    ...props
  },
  ref
) {
  return (
    <UI.ButtonElement
      disabled={props.disabled || loading}
      {...props}
      kind={kind}
      size={size}
      onClick={onClick}
      ref={ref}
    >
      <UI.Content loading={loading}>{children}</UI.Content>
      {loading ? <UI.SpinnerElement /> : null}
    </UI.ButtonElement>
  );
});

const Button = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<Props>
>(function ButtonComponent(
  { size = "default", kind = "text", onClick, ...props },
  ref
) {
  const { trackEvent } = useTracking();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    trackEvent(Events.ButtonClick, {
      title: props.title,
      text: normalizeText(event.currentTarget.innerText),
      kind,
      size,
    });

    onClick?.(event);
  };

  return (
    <ButtonWithoutTracking
      {...props}
      kind={kind}
      size={size}
      onClick={handleClick}
      ref={ref}
    />
  );
});

type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  kind?: UI.Kind;
  size?: keyof (typeof UI)["sizes"];
  loading?: boolean;
  title?: string;
  className?: string;
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
};

export default Button;

export { ButtonWithoutTracking };
export { Content, ButtonElement } from "./Button/shared/UI";
export { default as Link } from "./Button/Link";
export { default as Action } from "./Button/Action";
export { default as Interact } from "./Button/Interact";
