const REFERRER = "referrerReact";
const PATH_REFERRER = "pathReferrerReact";
const ROUTE_REFERRER = "routeReferrerReact";

export const setReferrer = ({
  referrer,
  pathReferrer,
  routeReferrer,
}: {
  referrer: string;
  pathReferrer: string;
  routeReferrer: string;
}) => {
  // Due to the fact we have some pages that are handled by client-side routing
  // and some of the pages that rendered on server (that leads to page reload)
  // we should stick to more persistent storage (session) and we can not rely
  // on window object
  sessionStorage.setItem(REFERRER, referrer);
  sessionStorage.setItem(PATH_REFERRER, pathReferrer);
  sessionStorage.setItem(ROUTE_REFERRER, routeReferrer);
};

export const getReferrer = (): [
  string | null,
  string | null,
  string | null
] => {
  return [
    sessionStorage.getItem(REFERRER),
    sessionStorage.getItem(PATH_REFERRER),
    sessionStorage.getItem(ROUTE_REFERRER),
  ];
};
