export enum AuthOperation {
  Read = "r",
  Write = "w",
  ReadWrite = "rw",
}

export enum AuthAuthScope {
  AuthRole = "auth.role",
  AuthRoleAssign = "auth.role-assign",
  AuthUser = "auth.user",
  AuthUserResendConfirmation = "auth.user-resend-confirmation",
  AuthImpersonate = "auth.session-impersonate",
}

export enum AuthWebsiteScope {
  AbTesting = "ab_testing",
  AttributeEnrichment = "attribute_enrichment",
  Catalog = "catalog",
  Collections = "collections",
  Facets = "facets",
  FacetsRefinedQueries = "facets.refined_queries",
  FacetsRefinedFilters = "facets.refined_filters",
  InternalCompanyTokens = "internal.company_tokens",
  InternalIndexes = "internal.indexes",
  InternalCopyIndexes = "internal.indexes.copy",
  Quizzes = "quizzes",
  Recommendations = "recommendations",
  Redirects = "redirects",
  Searchabilities = "searchabilities",
  SearchandisingCampaigns = "searchandising.campaigns",
  SearchandisingRecommendations = "searchandising.recommendations",
  SearchandisingRefinedCollections = "searchandising.refined_collections",
  SearchandisingRefinedFilters = "searchandising.refined_filters",
  SearchandisingRefinedTags = "searchandising.refined_tags",
  SearchandisingRefinedQueries = "searchandising.refined_queries",
  SponsoredListings = "sponsored_listings",
  Synonyms = "synonyms",
}
