import React from "react";

import { createConfirmation, confirmable } from "app/providers/ConfirmProvider";

import { Gate as Modal } from "components/Modules/Modal";

import Dialog, { Props as DialogProps } from "./Confirmation/Dialog";

export { default as useConfirmation } from "./Confirmation/useConfirmation";

// this file exports only final usable "confirm" function; if you need
// Confirmation or ConfirmationConfirmable classes to be used somewhere,
// add them to export

function Confirmation<FooterProps = {}>({
  show = true,
  onDismiss = () => {},
  dismiss = () => {},
  ...props
}: Props<FooterProps>) {
  return (
    <Modal
      isOpen={show}
      onRequestClose={() => {
        if (!props?.options?.confirmOnly) {
          dismiss();
          onDismiss();
        }
      }}
      closeTimeoutMS={0}
      className="ReactModal__Content cio-modal cio-confirmation"
    >
      <Dialog onDismiss={onDismiss} dismiss={dismiss} {...props} />
    </Modal>
  );
}

type Props<FooterProps> = DialogProps<FooterProps> & {
  show?: boolean;
};

const ConfirmationConfirmable = confirmable(Confirmation);
const confirm = createConfirmation(ConfirmationConfirmable);

export default confirm;
