import React, { useState, useMemo, useEffect } from "react";

import ErrorBoundary from "components/Modules/ErrorBoundary";
import { Gate } from "components/Modules/Modal";

import useValueRef from "./useValueRef";

export default function useModal({
  show = false,
  onClose = () => {},
}: Args = {}) {
  const [isOpen, setIsOpen] = useState(show);
  const isOpenRef = useValueRef(isOpen);
  const closeRef = useValueRef(onClose);

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const ModalGate = useMemo(() => {
    const Component = (
      props: Omit<
        React.ComponentProps<typeof Gate>,
        "isOpen" | "onRequestClose"
      >
    ) => (
      <ErrorBoundary>
        <Gate
          isOpen={isOpenRef.current}
          onRequestClose={() => {
            closeRef.current();
            setIsOpen(false);
          }}
          {...props}
        />
      </ErrorBoundary>
    );

    Component.displayName = "ModalGate";

    return Component;
    // @refactoring Forbid deactivation of hook dependencies (https://constructor.slab.com/posts/rfc-remove-deactivation-of-the-es-lint-rule-for-hook-dependenciesoli-vk98awgw)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    ModalGate,
    (value?: boolean) =>
      // eslint-disable-next-line no-negated-condition
      setIsOpen((current) => (value !== undefined ? value : !current)),
  ] as const;
}

type Args = {
  show?: boolean;
  onClose?: () => void;
};
