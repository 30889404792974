import { MfaApi } from "services/AuthApi";

export type ValidateMFATokenParams = {
  userId: string;
  emailMFAToken?: string;
};

/**
 * @description
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Users/operation/v1-auth-users-validate-email-mfa-token
 * @returns {ValidateMFATokenParams}
 */
export default async function validateMFAToken({
  userId,
  emailMFAToken,
}: ValidateMFATokenParams): Promise<boolean> {
  try {
    await MfaApi.post<ValidateMFATokenData>(
      ["v1", "users", userId, "validate-email-mfa-tokens"],
      undefined,
      emailMFAToken ? { email_mfa_token: emailMFAToken } : undefined
    );
    return true;
  } catch {
    return false;
  }
}

validateMFAToken.key = "modules/authentication/actions/users/validateMFAToken";

type ValidateMFATokenErrorType = {
  code: string;
  message: string;
  source: string;
};

export type ValidateMFATokenData = {
  errors: ValidateMFATokenErrorType[];
  message: string;
  trace_id: string;
};

export type ValidateMFAToken = {
  errors: ValidateMFATokenErrorType[];
  message: string;
  traceId: string;
};

export const ValidateMFAToken = {
  fromValidateMFATokenData: (data: ValidateMFATokenData): ValidateMFAToken => {
    const validateMFAToken: ValidateMFAToken = {
      errors: data.errors.map((error) => ({
        code: error.code,
        message: error.message,
        source: error.source,
      })),
      message: data.message,
      traceId: data.trace_id,
    };
    return validateMFAToken;
  },
};
