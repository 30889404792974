import React from "react";
import styled from "@emotion/styled";

// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import Icon from "components/Modules/Icon";

import { CompanyInfo } from "../../types";
import { PLATFORMS } from "../../constants";

export default function Platforms({
  productKey,
  companyInfo,
}: {
  productKey: string;
  companyInfo: CompanyInfo;
}) {
  return (
    <PlatformsContainer>
      {PLATFORMS.filter(({ platformOptions }) => {
        // Any matches are sufficient to draw the given platform logo.
        // We do this because "backend", "frontend UI", and "frontend API"
        // are all considered "web" platforms
        return platformOptions.some((specificPlatform) => {
          const entireName = `${productKey}${specificPlatform}`;
          return entireName in companyInfo && !!companyInfo[entireName];
        });
      }).map((platform) => (
        <Circle key={platform.platformKey} data-testid={platform.platformKey}>
          <Icon icon={platform.iconName} />
        </Circle>
      ))}
    </PlatformsContainer>
  );
}

const PlatformsContainer = styled.div`
  display: flex;
  justify-content: left;
  gap: 8px;
`;

// using flex styles to center inner div
const Circle = styled.span`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background: var(--cio-colors-grey-light-green-transparent);
  border-radius: 50%;
`;
