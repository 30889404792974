import React, { useState } from "react";
import { css } from "@emotion/react";

// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import Button from "components/Modules/Button";
import * as Modal from "components/Modules/Modal";
import { AlertFailure } from "app/designSystem/components";

export default function Dialog<FooterProps = {}>({
  title,
  message,
  content,
  action,
  actionTitle,
  actionClassName,
  proceed = () => {},
  footerComponent,
  footerProps,
  cancelTitle = "Cancel",
  options = { confirmOnly: false },
  onDismiss = () => {},
  dismiss = () => {},
}: Props<FooterProps>) {
  const doDismiss = () => {
    dismiss();
    onDismiss();
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const doAction = () => {
    if (!action) {
      proceed();
      onDismiss();
      return;
    }

    setLoading(true);

    // `proceed` will unmount the component and the confirmation call will resolve.
    // state setting must be done prior to calling it (if necessary to do at all)
    action()
      .then((resolved) => {
        setLoading(false);
        proceed(resolved);
        onDismiss();
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const Footer = footerComponent;

  return (
    <Modal.Modal title={title} description={message}>
      {content && (
        <Modal.Body
          css={css`
            padding: 24px 30px;
          `}
        >
          {content}
        </Modal.Body>
      )}
      {error && <AlertFailure error={error} />}
      {Footer ? (
        <Footer
          {...footerProps!}
          loading={loading}
          onConfirm={doAction}
          closeModal={dismiss}
          cancel={() => doDismiss()}
        />
      ) : (
        <Modal.Footer
          css={css`
            background-color: var(--neutral-greenishgrey);
          `}
        >
          {actionTitle && (
            <Button
              kind="danger"
              className={actionClassName}
              type="button"
              onClick={doAction}
              loading={loading}
            >
              {actionTitle}
            </Button>
          )}

          {!options.confirmOnly && (
            <Button type="button" kind="secondary" onClick={() => doDismiss()}>
              {cancelTitle}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal.Modal>
  );
}

export type Props<FooterProps> = {
  action: () => Promise<unknown>;
  actionTitle?: string;
  actionClassName?: string;
  cancelTitle?: string;
  dismiss?: () => void;
  message?: React.ReactNode;
  content?: React.ReactNode;
  proceed?: (resolved?: unknown) => void;
  title?: string;
  options?: {
    confirmOnly: boolean;
  };
  footerComponent?: React.FC<FooterProps>;
  footerProps?: FooterProps;
  onDismiss?: () => void;
};
