import React, { createContext, useState } from "react";

import { Collection } from "modules/collections";

export type CollectionContextType = {
  createdCollection: Collection | null;
  setCreatedCollection: React.Dispatch<React.SetStateAction<Collection | null>>;
};

export const CollectionContext = createContext<CollectionContextType>({
  createdCollection: null,
  setCreatedCollection: () => {},
});

export const CollectionContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [createdCollection, setCreatedCollection] = useState<Collection | null>(
    null
  );

  return (
    <CollectionContext.Provider
      value={{ createdCollection, setCreatedCollection }}
    >
      {children}
    </CollectionContext.Provider>
  );
};
