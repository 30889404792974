import { useCallback, useMemo } from "react";

import { ListFacet } from "./types";

export default function useFacetsVisibilityGetters(facetsInfo: ListFacet[]) {
  const { hiddenFacetNames, protectedFacetNames } = useMemo(() => {
    const hiddenFacetNames = getFacetNamesObject(
      facetsInfo.filter((facet) => facet.hidden).map((facet) => facet.name)
    );

    const protectedFacetNames = getFacetNamesObject(
      facetsInfo.filter((facet) => facet.protected).map((facet) => facet.name)
    );

    return { hiddenFacetNames, protectedFacetNames };
  }, [facetsInfo]);

  const isPublic = useCallback(
    (facetName: string) =>
      !hiddenFacetNames[facetName] && !protectedFacetNames[facetName],
    [hiddenFacetNames, protectedFacetNames]
  );

  const isHidden = useCallback(
    (facetName: string) => !!hiddenFacetNames[facetName],
    [hiddenFacetNames]
  );

  const isProtected = useCallback(
    (facetName: string) => !!protectedFacetNames[facetName],
    [protectedFacetNames]
  );

  return {
    isPublic,
    isHidden,
    isProtected,
  };
}

function getFacetNamesObject(facetNames: string[]) {
  return facetNames.reduce((prev, facetName) => {
    prev[facetName] = true;
    return prev;
  }, {} as Record<string, boolean>);
}
