import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  Fragment,
  useState,
  useRef,
} from "react";
import { Box, Tag } from "@chakra-ui/react";
// @refactoring Forbid usage of `ramda` (https://constructor.slab.com/posts/deprecating-ramda-and-adopting-remeda-wlks8rn7)
// eslint-disable-next-line local-rules/no-ramda
import { difference, last } from "ramda";

import { Tooltip } from "app/designSystem/components/Tooltip";

import LabelTag from "./MaskedSelectBox/LabelTag";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as helpers from "./MultiSelect.helpers";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./MultiSelect.styles";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as types from "./MultiSelect.types";

export default function MaskedSelectBox({
  isShowingEditLink,
  onRemoveHandler,
  selectedItems,
  joinSeparator,
  wordSeparator,
}: types.MaskedSelectBoxProps) {
  const orderedItems = selectedItems.sort(
    (a, b) => a.label.length - b.label.length
  );

  const [visibleItems, setVisibleItems] =
    useState<types.MaskedSelectBoxItem[]>(orderedItems);

  const maskedBoxRef = useRef<HTMLDivElement | null>(null);
  const orderedItemsRef = useRef(orderedItems);
  orderedItemsRef.current = orderedItems;

  /*
   * Recalculates which labels should be visible. It assumes that all labels are
   * currently visible and check which ones fit the container.
   */
  const updateVisibleLabels = useCallback(() => {
    setVisibleItems(
      helpers.calculateVisibleLabels({
        orderedItems: orderedItemsRef.current,
        maskedBox: maskedBoxRef.current,
        isShowingEditLink,
      })
    );
  }, [isShowingEditLink]);

  const orderedItemIds = orderedItems.map((item) => item.id).join(";");
  const visibleItemIds = visibleItems.map((item) => item.id).join(";");

  /*
   * When selected items change (and therefore ordered items change) we want to
   * reset visible items to show all of them. This will force a refresh of which
   * ones are visible.
   */
  useEffect(() => setVisibleItems(orderedItemsRef.current), [orderedItemIds]);

  /*
   * Here, we re-calculate which labels are visible according to the current
   * masked box width, before painting the components.
   */
  useLayoutEffect(() => {
    updateVisibleLabels();
  }, [updateVisibleLabels, visibleItemIds]);

  /*
   * Window resizing might affect the width of the container, so we recalculate
   * which labels are visible.
   */
  useEffect(() => {
    window.addEventListener("resize", updateVisibleLabels);
    return () => window.removeEventListener("resize", updateVisibleLabels);
  }, [updateVisibleLabels]);

  const hiddenItemsCount = selectedItems.length - visibleItems.length;
  const hasHiddenItems = !!hiddenItemsCount;

  const tooltipText = difference(orderedItems, visibleItems)
    .map(({ label }) => label)
    .join(joinSeparator);

  return (
    <Box {...styles.maskedBoxContainer} ref={maskedBoxRef}>
      {visibleItems.map(({ id, label }) => {
        const isLastItem = last(visibleItems).id === id;
        return (
          <Fragment key={id}>
            <LabelTag label={label} onRemove={() => onRemoveHandler(id)} />

            {wordSeparator && (!isLastItem || hasHiddenItems) && (
              <Tag {...styles.wordSeparatorLabel}>{wordSeparator}</Tag>
            )}
          </Fragment>
        );
      })}

      {hasHiddenItems && (
        <Tooltip label={tooltipText}>
          <Tag {...styles.labelTagAlternative}>
            {visibleItems.length > 0
              ? `${wordSeparator ? "" : "& "}${hiddenItemsCount} More`
              : `${hiddenItemsCount} selected`}
          </Tag>
        </Tooltip>
      )}
    </Box>
  );
}
