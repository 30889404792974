import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

const isTypographyV2 =
  typeof window !== "undefined" &&
  isFlagEnabled(FeatureFlags.DesignSystemTypographyV2);

export const fonts = {
  body: isTypographyV2 ? "Roboto Flex, sans-serif" : "Lato, sans-serif",
  heading: isTypographyV2 ? "Roboto Flex, sans-serif" : "Work Sans, sans-serif",
  mono: "Source Code Pro, monospace",
  inter: "Inter, sans-serif",
  timesNewRoman: "Times New Roman, serif",
  number: "Work Sans, sans-serif",
};

export const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

export const lineHeights = {
  1: "4px",
  2: "8px",
  3: "12px",
  4: "16px",
  5: "20px",
  6: "24px",
  7: "28px",
  8: "32px",
  9: "36px",
  10: "40px",
  11: "44px",
  12: "48px",
  13: "52px",
  14: "56px",
  15: "60px",
  16: "64px",
  17: "68px",
  18: "72px",
  19: "76px",
  20: "80px",
};

// Text styles combine several properties in one block that can be used with
// the `textStyle` prop. One thing of note is that these don't work with the
// `Heading` component, so use `Text` instead.
// Ref: https://chakra-ui.com/docs/features/text-and-layer-styles#text-styles
export const textStyles = {
  // new design system text styles
  h1: {
    fontSize: "26px",
    lineHeight: "36px",
    letterSpacing: "-1%",
    fontFamily: "heading",
    fontWeight: "regular",
  },
  "h1-medium": {
    fontSize: "26px",
    lineHeight: "36px",
    letterSpacing: "-1%",
    fontFamily: "heading",
    fontWeight: "medium",
  },
  h2: {
    fontSize: "23px",
    lineHeight: "32px",
    letterSpacing: "-1%",
    fontFamily: "heading",
    fontWeight: "regular",
  },
  "h2-medium": {
    fontSize: "23px",
    lineHeight: "32px",
    letterSpacing: "-1%",
    fontFamily: "heading",
    fontWeight: "medium",
  },
  h3: {
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "-1%",
    fontFamily: "heading",
    fontWeight: "regular",
  },
  "h3-medium": {
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "-1%",
    fontFamily: "heading",
    fontWeight: "medium",
  },
  h4: {
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "-1%",
    fontFamily: "heading",
    fontWeight: "regular",
  },
  "h4-medium": {
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "-1%",
    fontFamily: "heading",
    fontWeight: "medium",
  },
  p1: {
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "body",
    fontWeight: "regular",
  },
  "p1-medium": {
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "body",
    fontWeight: "medium",
  },
  p2: {
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "body",
    fontWeight: "regular",
  },
  "p2-medium": {
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "body",
    fontWeight: "medium",
  },
  // TODO: replace "new-caption" with "caption"
  "new-caption": {
    fontSize: "13px",
    lineHeight: "15px",
    fontFamily: "body",
    fontWeight: "regular",
  },
  "caption-medium": {
    fontSize: "13px",
    lineHeight: "15px",
    fontFamily: "body",
    fontWeight: "medium",
  },

  // old textStyles
  heading1: {
    fontFamily: "heading",
    fontSize: "28px",
    lineHeight: 8,
    fontWeight: "medium",
  },
  heading2: {
    fontFamily: "heading",
    fontSize: "24px",
    lineHeight: 7,
    fontWeight: "medium",
  },
  heading3: {
    fontFamily: "heading",
    fontSize: "20px",
    lineHeight: 6,
    fontWeight: "medium",
  },
  heading4: {
    fontFamily: "heading",
    fontSize: "16px",
    lineHeight: 5,
    fontWeight: "medium",
  },
  "heading4-uppercase": {
    fontFamily: "heading",
    fontSize: "16px",
    lineHeight: 5,
    fontWeight: "medium",
    textTransform: "uppercase",
    letterSpacing: "1.5px",
  },
  body1: {
    fontFamily: "body",
    fontSize: "15px",
    lineHeight: 5,
    fontWeight: "regular",
  },
  "body1-italic": {
    fontFamily: "body",
    fontSize: "15px",
    lineHeight: 5,
    fontWeight: "regular",
    fontStyle: "italic",
  },
  body2: {
    fontFamily: "body",
    fontSize: "14px",
    lineHeight: 5,
    fontWeight: "regular",
  },
  "body-caption": {
    fontSize: "9px",
    fontWeight: "700",
    lineHeight: 4,
    letterSpacing: "0.5px",
    fontFamily: "body",
  },
  "body2-italic": {
    fontFamily: "body",
    fontSize: "14px",
    lineHeight: 5,
    fontWeight: "regular",
    fontStyle: "italic",
  },
  body3: {
    fontFamily: "body",
    fontSize: "13px",
    lineHeight: 5,
    fontWeight: "regular",
  },
  "body3-italic": {
    fontFamily: "body",
    fontSize: "13px",
    lineHeight: 5,
    fontWeight: "regular",
    fontStyle: "italic",
  },
  caption: {
    fontFamily: "body",
    fontSize: "12px",
    lineHeight: 4,
    fontWeight: "regular",
  },
  "caption-italic": {
    fontFamily: "body",
    fontSize: "12px",
    lineHeight: 4,
    fontWeight: "regular",
    fontStyle: "italic",
  },
  numberBig: {
    fontFamily: isTypographyV2 ? "number" : "heading",
    fontSize: "42px",
    lineHeight: 12,
    fontWeight: "light",
  },
  numberMedium: {
    fontFamily: isTypographyV2 ? "number" : "heading",
    fontSize: "30px",
    lineHeight: 9,
    fontWeight: "light",
  },
  numberSmall: {
    fontFamily: isTypographyV2 ? "number" : "heading",
    fontSize: "16px",
    lineHeight: 5,
    fontWeight: "light",
  },
  label: {
    fontFamily: "body",
    fontSize: "14px",
    lineHeight: 5,
    fontWeight: "bold",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  // TODO: replace this with buttonLabel or vise-versa
  subheadline: {
    fontFamily: "body",
    fontSize: "12px",
    lineHeight: 4,
    fontWeight: "bold",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  buttonLabel: {
    fontFamily: "body",
    fontSize: "12px",
    lineHeight: 4,
    fontWeight: "bold",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  chartValue: {
    fontFamily: "mono",
    fontSize: "12px",
    lineHeight: 3,
    fontWeight: "regular",
  },
  code: {
    fontFamily: "mono",
    fontSize: "14px",
    lineHeight: 5,
    fontWeight: "regular",
    whiteSpace: "pre-wrap",
  },
  codeSmall: {
    fontFamily: "mono",
    fontSize: "12px",
    lineHeight: 4,
    fontWeight: "regular",
    whiteSpace: "pre-wrap",
  },
  inputLabel: {
    fontFamily: "body",
    fontSize: "13px",
    lineHeight: 4,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  productNames: {
    fontFamily: "inter",
    fontSize: "18px",
    lineHeight: 6,
    fontWeight: "semibold",
    letterSpacing: "-1px",
    textTransform: "capitalize",
  },
  calculations: {
    fontFamily: "timesNewRoman",
    fontSize: "13px",
    lineHeight: 5,
    fontWeight: "regular",
    fontStyle: "italic",
  },
  display: {
    fontFamily: "heading",
    fontSize: "48px",
    lineHeight: 14,
    fontWeight: "bold",
  },
  numbers3: {
    fontFamily: isTypographyV2 ? "number" : "heading",
    fontSize: "16px",
    lineHeight: 5,
    fontWeight: "regular",
  },
  numbers4: {
    fontFamily: isTypographyV2 ? "number" : "heading",
    fontSize: "12px",
    lineHeight: 3,
    fontWeight: "medium",
  },
};
