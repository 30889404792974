import React, { createContext, useContext } from "react";

import { TokenValidateResponse } from "modules/authentication/actions/tokens/validate";
import useTokenValidate from "app/queries/authentication/useTokenValidate";
import RequestBox from "components/Modules/RequestBox";
import { isAuthenticationServiceSession } from "utils/authentication";

export type AuthTokenContextType = {
  token: TokenValidateResponse | null;
};

export const AuthTokenContext = createContext<AuthTokenContextType | null>(
  null
);

export default function AuthTokenContextProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const isAuthServiceEnabled = isAuthenticationServiceSession();

  const {
    data: token,
    isFetching,
    error,
  } = useTokenValidate([], {
    enabled: isAuthServiceEnabled,
  });

  return isAuthServiceEnabled ? (
    <RequestBox error={error} fetching={isFetching} overlay>
      {token && !isFetching && !error ? (
        <AuthTokenContext.Provider value={{ token }}>
          {children}
        </AuthTokenContext.Provider>
      ) : null}
    </RequestBox>
  ) : (
    <>{children}</>
  );
}

export function useAuthToken() {
  const isAuthServiceEnabled = isAuthenticationServiceSession();
  const context = useContext(AuthTokenContext);

  if (!isAuthServiceEnabled) {
    return {
      isAuthServiceEnabled: false,
      token: null,
    };
  }

  if (!context) {
    throw new Error(
      "Token context is not available. Wrap your component in Auth Token Provider."
    );
  }

  const { token } = context;

  return {
    isAuthServiceEnabled,
    token,
  };
}
