import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Accordion,
  Box,
  Button,
  Divider,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import AnnounceKit, { AnnounceKitHandle } from "announcekit-react";

import * as amplitude from "utils/amplitude/events";

import { getIsHistorySupported as isHistorySupported } from "app/components/shared/shared/utils";
import { PageGroup } from "app/components/shared/shared/types";
import usePageLayout from "app/components/shared/usePageLayout";
import useSidebar from "app/components/shared/useSidebar";
import { ActionGroupProps } from "app/components/shared/actionGroupProps";

import ActionGroup from "./shared/ActionGroup";
import ExpandCollapseButton from "./NavigationSidebar/ExpandCollapseButton";
import UserMenu from "./NavigationSidebar/UserMenu";
import MenuGroup, { MenuItem } from "./NavigationSidebar/MenuGroup";

const OPEN_SIDEBAR_DELAY = 500;
const CLOSE_SIDEBAR_DELAY = 1000;

export default function NavigationSidebar({
  currentPath,
  isSidebarExpanded,
  onToggleSidebar,
  currentAccordionIndex,
  ...actionGroupProps
}: Props) {
  const { help, analytics, workspace, integration, accounts } = usePageLayout();
  const { sidebarHoverHelper } = useSidebar();
  const [accordionIndex, setAccordionIndex] = useState(currentAccordionIndex);
  const [unreadCount, setUnreadCount] = useState(0);
  // Used so that the sidebar doesn't collapse during navigation
  const [isHovered, setIsHovered] = sidebarHoverHelper;
  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: !!isHovered,
    onClose: () => setIsHovered(false),
    onOpen: () => setIsHovered(true),
  });
  const isSidebarOpen = isSidebarExpanded || isOpen;

  const onAccordionClick = (pageGroup: PageGroup) => {
    const index = accordionIndex === pageGroup.index ? -1 : pageGroup.index;
    amplitude.sendAmplitudeEvent({
      eventName: amplitude.AmplitudeEvents.IaSidebarMainNavigation,
      eventProperties: {
        label: pageGroup.analyticsLabel,
        action: index === -1 ? "collapse" : "expand",
      },
    });
    setAccordionIndex(index);
  };

  // When wrapping the button with the announce kit wrapper, the button style
  // doesn't change on hover or sidebar expand/collapse. So using a ref to open
  // the widget on button click.
  const announceKitRef = React.createRef<AnnounceKitHandle>();

  useEffect(() => {
    announceKitRef.current &&
      announceKitRef.current
        .unread()
        .then((count) => setUnreadCount(count as number));
  });

  // Normal onMouseEnter and onMouseLeave events won't work as for each page, the
  // sidebar is reinitialized and the timers are lost. Moreover, since the pages
  // are lazily loaded, there's another re-initialization that happens in the page
  // loading state. So, if you move the mouse out of the component between these
  // events, the listener is lost. To overcome the issue, a combination of useEffect
  // and onMouseMoveCapture is used to trigger the close event at the right time.
  const closetimeoutRef = useRef<NodeJS.Timeout>();
  const openTimeoutRef = useRef<NodeJS.Timeout>();

  const onMouseEnter = useCallback(() => {
    closetimeoutRef.current && clearTimeout(closetimeoutRef.current);

    if (openTimeoutRef.current) {
      return;
    }

    openTimeoutRef.current = setTimeout(() => {
      onOpen();
    }, OPEN_SIDEBAR_DELAY);
  }, [onOpen]);

  const onMouseLeave = useCallback(() => {
    if (openTimeoutRef.current) {
      clearTimeout(openTimeoutRef.current);
      openTimeoutRef.current = undefined;
    }

    closetimeoutRef.current = setTimeout(() => {
      onClose();
      setAccordionIndex(currentAccordionIndex);
    }, CLOSE_SIDEBAR_DELAY);
  }, [onClose, currentAccordionIndex]);

  useEffect(onMouseLeave, [onMouseLeave]);

  useEffect(() => {
    return () => {
      if (openTimeoutRef.current) {
        clearTimeout(openTimeoutRef.current);
      }
      if (closetimeoutRef.current) {
        clearTimeout(closetimeoutRef.current);
      }
    };
  }, []);

  return (
    <Box
      position="relative"
      height="100%"
      w="max-content"
      zIndex={5}
      aria-label="sidebar"
    >
      <ExpandCollapseButton
        onClick={() => {
          onToggleSidebar();
          onClose();
        }}
        isOpen={isSidebarOpen}
        isExpanded={isSidebarExpanded}
      />
      <Box
        height="100%"
        bgColor="white"
        overflow="hidden"
        boxShadow="feedback"
        transition="300ms ease-in-out"
        transitionProperty="width, padding"
        width={isSidebarOpen ? "236px" : "76px"}
        padding="16px"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={(e) => e.stopPropagation()}
        onMouseMoveCapture={() => !isHovered && onMouseEnter()}
      >
        <Accordion index={isSidebarOpen ? accordionIndex : -1} height="100%">
          <VStack
            height="100%"
            spacing="12px"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <VStack
              spacing="4px"
              flexGrow={1}
              alignItems="flex-start"
              overflowY="auto"
              css={{
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                "&::-webkit-scrollbar": { display: "none" },
              }}
            >
              <MenuItem
                icon="home-16"
                isSidebarExpanded={isSidebarOpen}
                label="Home"
                href="/dashboard"
                as={isHistorySupported("/dashboard", currentPath) ? Link : "a"}
                isActive={
                  currentPath === "/dashboard" || currentPath === "/dashboard/"
                }
                onClick={() =>
                  amplitude.sendAmplitudeEvent({
                    eventName:
                      amplitude.AmplitudeEvents.IaSidebarMainNavigation,
                    eventProperties: { label: "Home" },
                  })
                }
              />

              {[analytics, workspace, integration].map((pageGroup) => (
                <MenuGroup
                  key={pageGroup.label}
                  pageGroup={pageGroup}
                  isSidebarExpanded={isSidebarOpen}
                  onExpand={() => onAccordionClick(pageGroup)}
                  isExpanded={accordionIndex === pageGroup.index}
                />
              ))}
            </VStack>

            <VStack
              w="full"
              alignItems="flex-start"
              display={{ sm: "flex", md: "none" }}
              flexDirection="column-reverse"
              gap="12px"
            >
              <ActionGroup {...actionGroupProps} />
            </VStack>
            <Divider color="overlay-white" />

            <VStack spacing="4px" alignItems="flex-start">
              <MenuGroup
                pageGroup={help}
                isSidebarExpanded={isSidebarOpen}
                onExpand={() => onAccordionClick(help)}
                isExpanded={accordionIndex === help.index}
              />
              <MenuItem
                label="Product updates"
                isSidebarExpanded={isSidebarOpen}
                icon="megaphone-16"
                href=""
                as={Button}
                showIndicator={unreadCount > 0}
                onClick={async () => {
                  announceKitRef.current &&
                    (await announceKitRef.current.open());
                  amplitude.sendAmplitudeEvent({
                    eventName:
                      amplitude.AmplitudeEvents.IaSidebarMainNavigation,
                    eventProperties: { label: "Product_Updates" },
                  });
                }}
              />
              <Box display="none">
                <AnnounceKit
                  ref={announceKitRef}
                  widget="https://announcekit.app/widgets/v2/24lZny"
                  widgetStyle={{
                    marginLeft: "-22px",
                    marginTop: "-13px",
                    transform: "scale(0.7)",
                  }}
                />
              </Box>
            </VStack>

            <Divider color="overlay-white" />

            <UserMenu
              isSidebarExpanded={isSidebarOpen}
              isExpanded={accordionIndex === accounts.index}
              onExpand={() => onAccordionClick(accounts)}
            />
          </VStack>
        </Accordion>
      </Box>
    </Box>
  );
}

type Props = {
  currentPath: string;
  currentAccordionIndex: number;
  isSidebarExpanded: boolean;
  onToggleSidebar: () => void;
} & ActionGroupProps;
