import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";

import {
  Events,
  normalizeText,
  useTracking,
} from "app/providers/TrackingProvider";

import * as UI from "./shared/UI";

function Link({
  children,
  onClick,
  to,
  href,
  loading = false,
  kind = "text",
  size = "default",
  ...props
}: React.PropsWithChildren<Props>) {
  const { trackEvent } = useTracking();

  const LinkComponent = to ? RouterLinkElement : LinkElement;
  const tabIndex = props.disabled ? -1 : props.tabIndex;

  const handleClick: Props["onClick"] = (event) => {
    if (props.disabled) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      trackEvent(Events.LinkClick, {
        href: to || href,
        title: props.title,
        text: normalizeText(event.currentTarget.innerText),
        size,
      });

      onClick?.(event);
    }
  };

  // doing cast to fix type issue
  // since component is defined based on
  // "to" prop
  const targetLinkProps = (
    to ? { to } : href ? { href } : {}
  ) as TargetLinkProps;

  return (
    <LinkComponent
      disabled={loading}
      size={size}
      kind={kind}
      {...props}
      {...targetLinkProps}
      tabIndex={tabIndex}
      onClick={handleClick}
    >
      <UI.Content loading={loading}>{children}</UI.Content>
      {loading && <UI.SpinnerElement />}
    </LinkComponent>
  );
}

type Props = {
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  kind?: UI.Kind;
  size?: keyof (typeof UI)["sizes"];
  loading?: boolean;
  title?: string;
  className?: string;
  type?: "button" | "reset" | "submit";
  href?: string;
  to?: string;
  disabled?: boolean;
  target?: string;
  download?: string;
  underline?: boolean;
  tabIndex?: number | undefined;
};

type TargetLinkProps =
  | { to: string; href: never }
  | { to: never; href: string }
  | { to: never; href?: never };

export default Link;

const disabledStyle = css`
  background-color: var(--primary-disabled);
  color: var(--neutral-disabledgrey);
  cursor: not-allowed;
  box-shadow: none;

  :hover:not(:disabled),
  :focus:not(:disabled),
  :active:not(:disabled) {
    cursor: not-allowed;
    background-color: var(--primary-disabled);
    color: var(--neutral-disabledgrey);
    box-shadow: none;
  }
`;

const LinkElement = styled(UI.ButtonElement)<{ underline?: boolean }>`
  :hover,
  :focus {
    text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  }

  /* disabled state is duplicated to correctly disable links */
  ${({ disabled }) => disabled && disabledStyle}
`.withComponent("a");

const RouterLinkElement = styled(UI.ButtonElement)`
  :hover,
  :focus {
    text-decoration: none;
  }

  /* disabled state is duplicated to correctly disable links */
  ${({ disabled }) => disabled && disabledStyle}
`.withComponent(RouterLink);
