import React from "react";
import {
  useStyleConfig,
  Button,
  Text,
  HStack,
  Box,
  forwardRef,
  LayoutProps,
  VStack,
} from "@chakra-ui/react";
import { Listbox } from "@headlessui/react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  limitShift,
  Placement,
} from "@floating-ui/react";

import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";

/**
 * Container for the menu options.
 */
export const Menu = forwardRef<ListMenuProps, typeof VStack>(
  ({ children, ...props }, ref) => {
    const styles = useStyleConfig("internal.Menu");

    return (
      <VStack ref={ref} __css={styles} alignItems="stretch" {...props}>
        {children}
      </VStack>
    );
  }
);

type ListMenuProps = Pick<LayoutProps, "width" | "w" | "maxWidth" | "maxW">;

/**
 * Individual menu items.
 */
export const MenuItem = forwardRef(
  ({ children, selected = false, ...props }, ref) => {
    const styles = useStyleConfig("internal.MenuItem");

    return (
      <Box
        ref={ref}
        aria-selected={selected}
        as="button"
        __css={styles}
        {...props}
      >
        {/*
          We use an internal box for the padding so that the ::before
          pseudo-element that creates the separation line is correctly
          positioned at the edge of the outer box.
        */}
        <Box
          __css={{
            px: "20px",
            py: "12px",
          }}
          maxWidth="full"
        >
          {children}
        </Box>
      </Box>
    );
  }
);

/**
 * Dropdown typically used for filtering.
 *
 * **This is a work in progress**. It doesn't implement all the functionality
 * described on Figma.
 */
export default function FilterDropdownChip<T extends string>({
  value,
  label = (value) => value,
  onChange,
  options,
  disabled = false,
  placement = "bottom-start",
}: Props<T>) {
  const styles = useStyleConfig("FilterDropdownChip");
  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [
      offset(8),
      flip(),
      shift({ crossAxis: true, limiter: limitShift() }),
    ],
  });

  return (
    <Listbox value={value} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <>
          <Listbox.Button
            as={Button}
            __css={styles}
            isDisabled={disabled}
            ref={refs.setReference}
          >
            <HStack spacing="4px">
              <Text m={0}>{label(value)}</Text>
              <ChakraIcon
                icon={
                  open && !disabled
                    ? "link-specific-chevron-up-16"
                    : "link-specific-chevron-down-16"
                }
              />
            </HStack>
          </Listbox.Button>

          {open && !disabled && (
            <Listbox.Options
              as={Menu}
              ref={refs.setFloating}
              style={floatingStyles}
              maxWidth="240px"
              overflowWrap="break-word"
              static
              zIndex="dropdown"
            >
              {options.map((option) => (
                <Listbox.Option as={MenuItem} key={option} value={option}>
                  {label(option)}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          )}
        </>
      )}
    </Listbox>
  );
}

type Props<T extends string> = {
  /**
   * Currently selected value.
   */
  value: T;
  /**
   * Optionally, allows turning a value into some other string for display
   * purposes. The same function is used for showing the currently selected
   * value in the chip itself and in the options list.
   */
  label?: (value: T) => string;
  /**
   * Called with the new value when a new one is selected.
   */
  onChange: (newValue: T) => void;
  /**
   * List of options to select from. It is expected that they will be unique.
   */
  options: T[];
  /**
   * Whether the dropdown is disabled.
   */
  disabled?: boolean;
  /**
   * Placement of the menu with respect to the chip.
   */
  placement?: Placement;
};
