import React from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Button,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";

import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";
import ThemeProvider from "app/providers/ThemeProvider";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { getStyles } from "./SelectOperator.styles";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { SelectOperatorProps } from "./SelectOperator.types";

const DEFAULT_OPERATOR_LIST: SelectOperatorProps["operatorList"] = [
  {
    value: "and",
    label: "AND",
  },
  {
    value: "or",
    label: "OR",
  },
];

export function SelectOperator({
  isAllowChange = false,
  isDisabled,
  onChange,
  operator = "if",
  colorScheme,
  operatorList = DEFAULT_OPERATOR_LIST,
  textVariant = "default",
}: SelectOperatorProps) {
  const styles = getStyles({
    isDisabled,
    operator,
    colorScheme,
    textVariant,
    isAllowChange,
  });

  const selectedItem = operatorList!.find((item) => item.value === operator);
  // Also used to display currently selected value
  const selectedValue = selectedItem?.value || operator;

  if (!isAllowChange) {
    return <Box {...styles.button}>{selectedValue}</Box>;
  }

  return (
    // TODO: ChakraProvider is used here to pass the `resetCSS` prop, which in
    // turn enables Chakra's CSS-reset affecting the whole page where this
    // component is rendered. We should remove it.
    <ThemeProvider resetCSS>
      <Menu autoSelect={false}>
        <MenuButton
          title="Change operator"
          isDisabled={isDisabled}
          as={Button}
          {...styles.button}
        >
          <Flex alignItems="center" justifyContent="center">
            <Text minW="30px">{selectedValue}</Text>
            <ChakraIcon ml="4px" icon="arrow-drop-down-16" />
          </Flex>
        </MenuButton>
        <MenuList {...styles.menuList}>
          {operatorList!.map((item, index) => {
            return (
              <MenuItem
                key={item.value}
                onClick={() => {
                  onChange?.(item.value);
                }}
                name={item.value}
                {...styles.menuItemWrapper({
                  selected: item.value === operator,
                })}
              >
                {index !== 0 && <MenuDivider {...styles.divider} />}
                <Box {...styles.menuItem}>{item.label}</Box>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </ThemeProvider>
  );
}
