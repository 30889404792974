import ApiErrors from "services/ApiErrors";

/**
 * Generic custom error - all errors that we generate and expect in the app
 * should extend this class.
 * To create a custom error, extend this class and ensure you duplicate the
 * prototype chain trick in the constructor.
 */
export class AppError extends Error {
  /**
   * The notify attribute is to decide if we should report a custom app error
   */
  notify: boolean;
  // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: Record<string, any>;

  constructor(
    message: string,
    notify: boolean = true,
    // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata: Record<string, any> = {}
  ) {
    super(message);
    this.notify = notify;
    this.metadata = metadata;

    // All error classes must set the prototype explicitly as long as we are
    // transpiling to ES5. More info at
    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, AppError.prototype);
  }

  /**
   * Whether this error is an expected part of the app's flow (e.g. creating
   * something that already exists, item not found) or not (an unexpected
   * error).
   */
  isExpected(): boolean {
    return !this.notify;
  }

  /**
   * Makes the wrapped function to throw an error that would be displayed
   * without the wrapper "our team is already notified about this problem" and
   * that would not be reported to sentry. Under the hood, converts "ApiErrors",
   * that is thrown by Api.get, Api.post, and others to AppError. Used best
   * together with `ErrorModifier.regExp`.
   *
   * Usage:
   *
   * ```
   * wrap(createObjectInApi, [
   *   [
   *     (message) => message.match(/Object already exists/),
   *     (message, payload) => message + ": " + payload.objectName,
   *   ]
   * ])
   *
   * wrap(createObjectInApi, [
   *   ErrorModifier.regExp(/Object already exists/, "Object exists!"),
   * ])
   * ```
   *
   * @param callback The function to wrap
   * @param matchers An array of regular expressions that match the error of
   * three forms:
   * 1. A regular expression that matches the error message.
   * 2. A regular expression that matches the error message and a string that
   *    should replace the error message.
   * 3. A regular expression that matches the error message and a function that
   *    takes the error message and the original arguments of the function and
   *    returns the new error message.
   */
  static wrap4xxApiErrors<Args extends unknown[], Result>(
    callback: (...args: Args) => Promise<Result>,
    matchers: ErrorModifier<Args>[]
  ): (...args: Args) => Promise<Result> {
    const wrapped = async (...args: Args) => {
      try {
        return await callback(...args);
      } catch (error) {
        if (
          error instanceof ApiErrors &&
          error.status >= 400 &&
          error.status < 500
        ) {
          throw convertError(error, matchers, { args });
        }

        throw error;
      }
    };

    Object.defineProperty(wrapped, "name", {
      value: callback.name,
    });

    return wrapped;
  }
}

export type ErrorModifier<Args extends unknown[]> = [
  (message: string) => boolean,
  (message: string, ...args: Args) => string
];

export const ErrorModifier = {
  regExp: <Args extends unknown[]>(
    regExp: RegExp,
    convert?: string | ((message: string, ...args: Args) => string)
  ): ErrorModifier<Args> => [
    (message) => regExp.test(message) !== null,
    // eslint-disable-next-line no-negated-condition
    convert !== undefined
      ? typeof convert === "string"
        ? () => convert
        : convert
      : (message, ..._args) => message,
  ],
};

function convertError<Args extends unknown[]>(
  error: ApiErrors<{ message: string }>,
  expected: ErrorModifier<Args>[],
  { args }: { args: Args }
): AppError | unknown {
  const message = error.message || error.errors?.message;

  for (const value of expected) {
    const [tester, modifier] = value;
    if (tester(message)) {
      return new AppError(modifier(message, ...args), false);
    }
  }

  return error;
}
/**
 * When we already handled the error by Sentry and we only need to show the error to the user
 */
export class DisplayError extends AppError {
  constructor(message: string, metadata: Record<string, unknown> = {}) {
    super(message, false, metadata);
    Object.setPrototypeOf(this, DisplayError.prototype);
  }
}

/**
 * When the user tries to interact with the Rails app but is unauthenticated.
 * They might have logged out on a different tab, or their session might have
 * expired.
 */
export class UserLoggedOutError extends AppError {
  constructor(
    message: string = "You are not currently logged in - please sign in again.",
    metadata: Record<string, unknown> = {}
  ) {
    super(message, false, metadata);
    Object.setPrototypeOf(this, UserLoggedOutError.prototype);
  }
}

/**
 * When the user tries to access a resource for which they do not have
 * permission.
 */
export class ForbiddenError extends AppError {
  constructor(
    message: string = "You don't have the necessary access rights to view this page. Please contact your administrator to request access.",
    metadata: Record<string, unknown> = {}
  ) {
    super(message, false, metadata);
    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }
}

export class NotFoundError extends AppError {
  constructor(
    message: string = "The item you have requested is not found. Please try again.",
    // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata: Record<string, any> = {}
  ) {
    super(message, false, metadata);
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}

export class ConflictError extends AppError {
  constructor(
    message: string = "The item you wish to add already exists. " +
      "Please try again with a different item.",
    metadata: Record<string, unknown> = {}
  ) {
    super(message, false, metadata);
    Object.setPrototypeOf(this, ConflictError.prototype);
  }
}

export class AbortedError extends AppError {
  constructor(
    message: string = "The request was aborted. Please try again.",
    // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata: Record<string, any> = {}
  ) {
    super(message, false, metadata);
    Object.setPrototypeOf(this, AbortedError.prototype);
  }
}

/**
 * Thrown when the backend and the frontend see different currently selected
 * companies.
 */
export class CompanyMismatchError extends AppError {
  constructor() {
    super(
      "We've noticed you have changed companies on a different tab, so we are reloading the page for you",
      false
    );
    Object.setPrototypeOf(this, CompanyMismatchError.prototype);
  }
}

/**
 * Client-side error to handle error returned by PasswordAttemptTracker
 */
export class SignOutOnTooManyPasswordAttemptsError extends AppError {
  constructor() {
    super(
      "You've exceeded the maximum number of password attempts. For your security, you've been logged out.",
      false
    );
    Object.setPrototypeOf(
      this,
      SignOutOnTooManyPasswordAttemptsError.prototype
    );
  }
}
