import React from "react";
import { css } from "@emotion/react";

// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import Icon, { IconName } from "components/Modules/Icon";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Default from "./Default";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { Content } from "./UI";

export default function Info({
  tooltip,
  icon = "info-dark",
  className,
  ...props
}: Props & React.ComponentProps<typeof Default>) {
  return (
    <Default
      followCursor={false}
      tooltip={
        typeof tooltip === "string" ? <Content>{tooltip}</Content> : tooltip
      }
      interactive
      {...props}
    >
      <Icon
        role="img"
        icon={icon}
        className={className}
        css={css`
          margin-left: 4px;
          display: inline-block;
          cursor: pointer;
        `}
      />
    </Default>
  );
}

type Props = {
  icon?: IconName;
};
