import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { IconButtonProps } from "./IconButton.types";

const isDesignSystemButtonV2 = isFlagEnabled(FeatureFlags.DesignSystemButtonV2);

export const IconButton = (
  variant: IconButtonProps["variant"],
  isLoading: boolean
) => {
  const borderColor = variant === "ghost" ? "white" : "grey-light-blue";
  const hoverBgColor = variant === "danger" ? "danger" : "grey-light-blue";
  const hoverColor = variant === "danger" ? "white" : "grey-dark";

  return {
    p: "6px",
    h: "32px",
    minHeight: "32px",
    w: "32px",
    minW: "32px",
    color: "grey-dark",
    border: "2px solid",
    borderRadius: "4px",
    borderColor: borderColor,
    bgColor: "white",
    cursor: isLoading ? "not-allowed" : "pointer",
    _hover: {
      bgColor: hoverBgColor,
      color: hoverColor,
      borderColor: hoverBgColor,
      _active: {
        borderColor: "grey-dark",
        bgColor: hoverBgColor,
        color: hoverColor,
      },
      _focus: {
        borderColor: "grey-dark",
        bgColor: hoverBgColor,
        color: hoverColor,
      },
      _disabled: {
        borderColor: variant === "ghost" ? "transparent" : "gray.100",
        bgColor: variant === "ghost" ? "transparent" : "gray.50",
        cursor: "not-allowed",
      },
    },
    _disabled: {
      borderColor: variant === "ghost" ? "transparent" : "gray.100",
      bgColor: variant === "ghost" ? "transparent" : "gray.50",
      cursor: "not-allowed",
    },
    _active: {
      borderColor: "grey-dark",
      bgColor: hoverBgColor,
      color: hoverColor,
    },
    _focus: {
      borderColor: "grey-dark",
      bgColor: hoverBgColor,
      color: hoverColor,
    },
  };
};

export const Icon = (
  variant: IconButtonProps["variant"],
  isDisabled: boolean
) => {
  if (isDisabled) {
    if (variant === "ghost") {
      return { color: isDesignSystemButtonV2 ? "gray.400" : "grey-disabled" };
    }

    if (variant === "outline") {
      return { backgroundColor: "gray.50", borderColor: "gray.100" };
    }

    return { color: "grey" };
  }

  return {
    color: "currentColor",
  };
};
