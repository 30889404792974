import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";

import createPersistedState from "vendor/use-persisted-state";
import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

import useCurrentUser from "app/hooks/useCurrentUser";
import { ChakraIcon } from "app/designSystem/components";

const useBannerClosed = createPersistedState<Record<string, Boolean>>(
  "IA_DEFAULT_SWITCH_CLOSED"
);

export default function IARefreshBanner() {
  const { id: userId } = useCurrentUser();
  const [allStates, setAllStates] = useBannerClosed();

  const bannerClosed = !!allStates?.[userId];
  const bannerEnabled = isFlagEnabled(FeatureFlags.EnableIaDefaultSwitch);

  const onClick = () => setAllStates((prev) => ({ ...prev, [userId]: true }));

  return bannerClosed || !bannerEnabled ? null : (
    <Box w="full" px="32px" py="20px" color="black" bg="grey-dark">
      <HStack w="full" justifyContent="space-between">
        <HStack spacing="0" gap="4px" textStyle="body1" color="white">
          <Text m="0" fontWeight="bold">
            Updated navigation coming soon!
          </Text>
          <Text m="0">
            You will automatically be switched to the new experience on May 1st.
          </Text>
        </HStack>
        <ChakraIcon
          aria-label="Close banner"
          color="white"
          icon="close-16"
          cursor="pointer"
          onClick={onClick}
        />
      </HStack>
    </Box>
  );
}
