import React from "react";
import styled from "@emotion/styled";

// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme from "components/theme";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { Body } from "./UI";
import Close from "./Header/Close";
export { default as Close } from "./Header/Close";

export default function Header({
  title,
  subTitle,
  description,
  children,
  onClose,
  ...props
}: HeaderProps) {
  return (
    <HeaderElement {...props}>
      <Title>
        {title}
        <SubTitleContainer>
          {subTitle}
          {onClose ? <Close onClick={onClose} /> : null}
        </SubTitleContainer>
      </Title>

      {description ? <Description>{description}</Description> : null}
      {children}
    </HeaderElement>
  );
}

type HeaderProps = {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
  onClose?: () => void;
};

export const HeaderElement = styled.div`
  padding: 24px 32px;
  & + ${Body} {
    border-top: 2px solid ${theme.colors.neutralGreenishgrey};
  }
`;

export const Title = styled.h4`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: ${theme.colors.headerBlack};
  font-family: ${theme.fonts.header};
  margin: 0px;
`;

export const Description = styled.div`
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 15px;
  color: ${theme.colors.textGrayBlue};
`;

export const SubTitle = styled.div`
  font-family: var(--font-text);
  font-style: italic;
  font-size: 14px;
  text-align: right;
  color: var(--neutral-lightgrey);
`;

export const SubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
