import React, { useContext } from "react";
import {
  Box,
  HStack,
  PopoverContent,
  VStack,
  Link,
  Drawer,
  DrawerBody,
  DrawerContent,
} from "@chakra-ui/react";
import { sortBy, pipe } from "remeda";
import { Link as ReactLink } from "react-router-dom";

import useFeatureToggle from "app/hooks/useFeatureToggle";
import { FeatureFlags } from "utils/generatedFeatures";

import * as amplitude from "utils/amplitude/events";
import URI from "utils/urijs";
import RootContext from "app/providers/RootContext";

import usePageLayout from "app/components/shared/usePageLayout";
import useSidebar from "app/components/shared/useSidebar";
import {
  Switch,
  ListMenu,
  ListMenuItem,
  Tooltip,
} from "app/designSystem/components";
import { switchCompany } from "app/components/PageLayout/NavigationSidebar/UserMenu/switchCompany";
import { Company } from "app/components/PageLayout/NavigationSidebar/UserMenu/types";
import useUpdateCurrentUser from "app/queries/currentUser/useUpdateCurrentUser";
import { usePageLayoutWrapperContext } from "app/components/PageLayout/PageLayoutWrapper/PageLayoutWrapperContext";
import useGetCompaniesByUser from "app/queries/authentication/useGetCompaniesByUser";
import { authServiceUrl } from "services/AuthApi";
import { useAuthToken } from "app/providers/AuthTokenContext";
import useAdminAuthPermission from "app/hooks/authentication/useAdminAuthPermission";
import useManageAuthTokens from "app/hooks/authentication/useManageAuthTokens";
import { isAuthenticationServiceSession } from "utils/authentication";

const UserMenuWrapper = ({
  isDesktopView,
  children,
  onClose,
  isOpen,
}: {
  isDesktopView: boolean;
  children: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
}) => {
  return isDesktopView ? (
    <PopoverContent w="360px">{children}</PopoverContent>
  ) : (
    <Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
      <DrawerContent mt="60px" maxW="360px" background="transparent">
        <DrawerBody boxShadow="feedback" p="0" w="95%" maxW="400px" mx="auto">
          {children}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default function UserMenuList({
  isInAccounts,
  onClose,
  isOpen,
  isSidebarExpanded,
  onExpand,
}: Props) {
  const { currentCompany, companies, setIsIaResponsePageLayoutActive } =
    useContext(RootContext);

  const { accounts, animationHelper } = usePageLayout();
  const { isDesktopView, sidebarHoverHelper } = useSidebar();
  const isNewPageLayoutByDefaultEnabled = useFeatureToggle(
    FeatureFlags.EnableIaResponsivePageLayoutByDefault
  );
  const [, , removeHover] = sidebarHoverHelper;
  const [, , removeAnimation] = animationHelper;

  const { mutate: updateCurrentUser } = useUpdateCurrentUser();

  const { setIsLayoutUpdating, setUpdateMessage } =
    usePageLayoutWrapperContext();

  const { token } = useAuthToken();
  const { canImpersonate } = useAdminAuthPermission();
  const { isImpersonating, stopImpersonation, logout } = useManageAuthTokens();

  const onResponsiveLayoutChange = () => {
    setIsLayoutUpdating(true);
    updateCurrentUser(
      { isIaResponsivePageLayoutActive: false },
      {
        onSuccess: (currentUser) => {
          setTimeout(() => {
            setIsLayoutUpdating(false);
            setIsIaResponsePageLayoutActive(
              currentUser.isIaResponsivePageLayoutActive
            );
          }, 1500);
        },
      }
    );
  };

  const onLogout = async () => {
    amplitude.sendAmplitudeEvent({
      eventName: amplitude.AmplitudeEvents.IaSidebarMainNavigation,
      eventProperties: {
        label: `${accounts.analyticsLabel}:Log_Out`,
      },
    });
    removeHover();
    removeAnimation();
    logout();
  };

  const sortByName = sortBy<Company>((x) =>
    pipe(x.name, (name) => name.toLowerCase())
  );

  const onAccountClick = () => {
    onClose();
    amplitude.sendAmplitudeEvent({
      eventName: amplitude.AmplitudeEvents.IaSidebarMainNavigation,
      eventProperties: {
        label: `${accounts.analyticsLabel}:Account`,
      },
    });
    isInAccounts && onExpand();
  };

  const authServiceEnabled = isAuthenticationServiceSession();

  const { data: userCompaniesData } = useGetCompaniesByUser(
    [{ userId: token?.userId || "" }],
    {
      enabled: authServiceEnabled && !!token?.userId,
    }
  );

  const accountsItems = (
    <ListMenuItem onClick={onAccountClick}>Account</ListMenuItem>
  );

  return (
    <UserMenuWrapper
      isDesktopView={isDesktopView}
      isOpen={isOpen && isSidebarExpanded}
      onClose={onClose}
    >
      <ListMenu>
        {!isNewPageLayoutByDefaultEnabled && (
          <Box bgColor="blue-dark" padding="20px">
            <VStack alignItems="flex-start" textTransform="none" spacing="2px">
              <HStack justifyContent="space-between" w="full">
                <Box fontWeight="bold" color="white" textStyle="body2">
                  Sidebar navigation
                </Box>
                <Switch isChecked onChange={onResponsiveLayoutChange} />
              </HStack>
              <Box textStyle="body3" color="grey-light-blue">
                Try the new experience and give feedback.
              </Box>
            </VStack>
          </Box>
        )}
        {!authServiceEnabled &&
          companies.length > 1 &&
          sortByName(companies).map((company) => (
            <Link
              key={company.id}
              _hover={{ textDecoration: "none" }}
              href={URI("/dashboard")
                .setSearch("company_id", String(company.id))
                .resource()}
            >
              <ListMenuItem
                onClick={() =>
                  amplitude.sendAmplitudeEvent({
                    eventName:
                      amplitude.AmplitudeEvents.IaSidebarMainNavigation,
                    eventProperties: {
                      label: `${accounts.analyticsLabel}:Switch_Company`,
                      currentCompany: currentCompany.name,
                      newCompany: company.name,
                    },
                  })
                }
                sx={{ fontWeight: 700 }}
              >
                {company.name}
              </ListMenuItem>
            </Link>
          ))}
        {/* We do not allow users who are impersonating to switch companies, otherwise the
        authenticate would fail, because they're not the actual user. */}
        {authServiceEnabled &&
          (userCompaniesData || []).length > 1 &&
          userCompaniesData
            ?.sort((company1, company2) => {
              if (company1.displayName > company2.displayName) {
                return 1;
              }

              return -1;
            })
            .map(
              (company) =>
                token?.companyId !== company.id && (
                  <Tooltip
                    key={company.id}
                    label="Company switching is disabled while impersonating another user."
                    isDisabled={!isImpersonating}
                    openDelay={0}
                    closeDelay={0}
                    shouldWrapChildren
                  >
                    <ListMenuItem
                      disabled={isImpersonating}
                      onClick={() => {
                        setIsLayoutUpdating(true);
                        setUpdateMessage("Switching companies");
                        amplitude.sendAmplitudeEvent({
                          eventName:
                            amplitude.AmplitudeEvents.IaSidebarMainNavigation,
                          eventProperties: {
                            label: `${accounts.analyticsLabel}:Switch_Company`,
                            currentCompany: currentCompany.name,
                            newCompany: company.displayName,
                          },
                        });
                        switchCompany({ companyId: company.id });
                      }}
                      sx={{ fontWeight: 700 }}
                    >
                      {company.displayName}
                    </ListMenuItem>
                  </Tooltip>
                )
            )}
        {canImpersonate && (
          <Link
            _hover={{ textDecoration: "none" }}
            href={`${authServiceUrl}/v2/ui/admin/sign_in?oauth_initiate_redirect_uri=${encodeURIComponent(
              `${window.location.origin}/impersonation/initiate`
            )}&impersonate_company_type=default`}
          >
            <ListMenuItem>Impersonate</ListMenuItem>
          </Link>
        )}
        {isImpersonating && (
          <Link _hover={{ textDecoration: "none" }} href="/impersonation/stop">
            <ListMenuItem onClick={stopImpersonation}>
              Stop Impersonating
            </ListMenuItem>
          </Link>
        )}
        {isInAccounts ? (
          accountsItems
        ) : authServiceEnabled ? (
          <Box
            _hover={{ textDecoration: "none" }}
            to="/dashboard/accounts_v2/profile"
            as={ReactLink}
          >
            {accountsItems}
          </Box>
        ) : (
          <Link
            _hover={{ textDecoration: "none" }}
            href="/dashboard/accounts/profile"
          >
            {accountsItems}
          </Link>
        )}
        <Link _hover={{ textDecoration: "none" }} href="/users/sign_out">
          <ListMenuItem onClick={onLogout}>Log out</ListMenuItem>
        </Link>
      </ListMenu>
    </UserMenuWrapper>
  );
}

type Props = {
  isInAccounts: boolean;
  onClose(): void;
  isOpen: boolean;
  isSidebarExpanded: boolean;
  onExpand(): void;
};
