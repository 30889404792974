import { DeepPartial } from "utility-types";

import * as searchabilities from "modules/searchabilities/types";
import * as search from "types/search";
import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";
import * as dates from "utils/datesApi";

export const facetTypes = ["range", "multiple", "hierarchical"] as const;
export const facetRangeTypes = ["static"] as const;
export const facetRangeFormats = ["boundaries", "options"] as const;
export const facetRangeInclusive = ["above", "below"] as const;
export const facetSortOrders = ["relevance", "value", "num_matches"] as const;

type FacetType = (typeof facetTypes)[number];
type FacetRangeType = (typeof facetRangeTypes)[number];
type FacetRangeFormat = (typeof facetRangeFormats)[number];
type FacetRangeInclusive = (typeof facetRangeInclusive)[number];
type FacetSortOrder = (typeof facetSortOrders)[number];

export type Facet = {
  displayName: string | null;
  hidden: boolean;
  protected: boolean;
  matchType: "any" | "all" | "none";
  name: string;
  position: number | null;
  sortOrder: FacetSortOrder;
  sortDescending: boolean;
  options: Option[];
  type: FacetType;
  rangeType: FacetRangeType | null;
  rangeFormat: FacetRangeFormat | null;
  rangeInclusive: FacetRangeInclusive | null;
  rangeLimits: number[] | null;
  data: Record<string, unknown>;
  countable: boolean;
  createdAt: Date | null;
  updatedAt: Date | null;
};

export type ListFacet = Omit<Facet, "options">;

// only used in test env and 'components/Facets/shared/DefaultContext.ts'
// and the default context is also only used in tests
export const EmptyFacet: Facet = {
  displayName: "",
  hidden: false,
  protected: false,
  matchType: "any",
  name: "",
  position: 0,
  rangeFormat: null,
  rangeInclusive: null,
  rangeLimits: null,
  rangeType: null,
  sortDescending: false,
  options: [],
  sortOrder: "value",
  type: "multiple",
  data: {},
  countable: true,
  createdAt: null,
  updatedAt: null,
};

export type Option = {
  data: Record<string, unknown>;
  displayName: string | null;
  hidden: boolean;
  position: number | null;
  value: string;
  valueAlias: string | null;
};

export const EmptyOption: Option = {
  data: {},
  displayName: "",
  hidden: false,
  position: null,
  value: "",
  valueAlias: null,
};

export type List = {
  facets: ListFacet[];
  totalCount: number;
};

export type OptionList = {
  facetOptions: Option[];
  totalCount: number;
};

export type FacetData = {
  display_name: string | null;
  hidden: boolean;
  protected: boolean;
  match_type: "any" | "all" | "none";
  name: string;
  position: number | null;
  range_format: "boundaries" | "options" | null;
  range_inclusive: "above" | "below" | null;
  range_limits: number[] | null;
  range_type: "static" | null;
  sort_order: "relevance" | "value" | "num_matches";
  sort_descending: boolean;
  type: "range" | "multiple" | "hierarchical";
  options: OptionData[];
  data: Record<string, unknown>;
  countable: boolean;
  created_at?: string | null;
  updated_at?: string | null;
};

export type ListFacetData = Omit<FacetData, "options">;

export type OptionData = {
  data: Record<string, unknown>;
  display_name: string | null;
  hidden: boolean;
  position: number | null;
  value: string;
  value_alias: string | null;
};

export type ListData = {
  facets: ListFacetData[];
  total_count: number;
};

export type OptionListData = {
  facet_options: OptionData[];
  total_count: number;
};

export function isNameEscaped(name: string) {
  return name.includes("\\.");
}

export function getEscapedName(name: string) {
  return name.replace(/\./g, "\\.");
}

export function getUnescapedName(name: string) {
  return name.replace(/\\./g, ".");
}

export const Facet = {
  getNameFromSearchability(searchabilityName: string): string {
    return searchabilityName.split(/(?<!\\)\./).at(-1)!;
  },

  fromData: (data: FacetData): Facet => {
    const facet_no_options = ListFacet.fromData(data);
    const facet: Facet = {
      ...facet_no_options,
      options: data.options.map(Option.fromData),
    };

    return facet;
  },

  toData: (facet: DeepPartial<Facet>): DeepPartial<FacetData> => {
    const data: DeepPartial<FacetData> = {
      display_name: facet.displayName,
      hidden: facet.hidden,
      protected: facet.protected,
      match_type: facet.matchType,
      name: facet.name,
      position: facet.position,
      range_format: facet.rangeFormat,
      range_inclusive: facet.rangeInclusive,
      range_limits: facet.rangeLimits,
      range_type: facet.rangeType,
      sort_descending: facet.sortDescending,
      sort_order: facet.sortOrder,
      type: facet.type,
      data: facet.data,
      countable: facet.countable,
      created_at: facet.createdAt
        ? dates.convertToApiFormat(facet.createdAt as Date)
        : undefined,
      updated_at: facet.updatedAt
        ? dates.convertToApiFormat(facet.updatedAt as Date)
        : undefined,
    };

    if (facet.options) {
      data.options = facet.options.map(Option.toData);
    }

    return data;
  },
};

export const ListFacet = {
  fromData: (data: ListFacetData): ListFacet => {
    const facet: ListFacet = {
      displayName: data.display_name,
      hidden: data.hidden,
      protected: data.protected,
      matchType: data.match_type,
      name: data.name,
      position: data.position,
      rangeFormat: data.range_format,
      rangeInclusive: data.range_inclusive,
      rangeLimits: data.range_limits,
      rangeType: data.range_type,
      sortDescending: data.sort_descending,
      sortOrder: data.sort_order,
      type: data.type,
      data: data.data,
      countable: data.countable,
      createdAt: data.created_at
        ? dates.convertFromApiFormat(data.created_at)
        : null,
      updatedAt: data.updated_at
        ? dates.convertFromApiFormat(data.updated_at)
        : null,
    };

    return facet;
  },

  fromSearchability: (
    searchability: searchabilities.Searchability
  ): ListFacet => {
    const isCountableFalseByDefaultEnabled = isFlagEnabled(
      FeatureFlags.EnableCountableFalseByDefault
    );

    return {
      displayName: null,
      hidden: false,
      protected: false,
      matchType: "any",
      name: Facet.getNameFromSearchability(searchability.name),
      position: null,
      sortOrder: "relevance",
      sortDescending: false,
      type: "multiple",
      rangeType: null,
      rangeFormat: null,
      rangeInclusive: null,
      rangeLimits: null,
      data: {},
      countable: !isCountableFalseByDefaultEnabled,
      createdAt: null,
      updatedAt: null,
    };
  },
};

export const Option = {
  fromData: (data: OptionData): Option => {
    return {
      data: data.data,
      displayName: data.display_name,
      hidden: data.hidden,
      position: data.position,
      value: data.value,
      valueAlias: data.value_alias,
    };
  },

  toData: (option: Partial<Option>): Partial<OptionData> => {
    return {
      data: option.data,
      display_name: option.displayName,
      hidden: option.hidden,
      position: option.position,
      value: option.value,
      value_alias: option.valueAlias,
    };
  },

  fromSearchOption: (option: search.FacetOption): Option => {
    return {
      data: {},
      displayName: option.displayName,
      hidden: false,
      position: null,
      value: option.value,
      valueAlias: null,
    };
  },
};
