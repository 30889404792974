import {
  NextPageCursorData,
  Role,
  RoleData,
} from "modules/authentication/actions/shared/types";
import AuthApi from "services/AuthApi";
import { convertFromApiFormat } from "utils/datesApi";

type GetCompanyUserRolesParams = {
  companyId: string;
};

/**
 * @description Get roles assigned to user for the current company
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-get-roles
 * @returns {GetCompanyUserRolesResponse[]}
 */
export default async function getCompanyUserRoles({
  companyId,
}: GetCompanyUserRolesParams): Promise<Role[]> {
  const result = await AuthApi.get<GetCompanyUserRolesResponseData>([
    "v1",
    "companies",
    companyId,
    "roles",
  ]);

  return GetCompanyUserRolesResponse.fromGetUserRolesResponseData(result);
}

getCompanyUserRoles.key =
  "modules/authentication/actions/companies/getCompanyUserRoles";

export type GetCompanyUserRolesResponseData = NextPageCursorData & {
  roles: RoleData[];
};

const GetCompanyUserRolesResponse = {
  fromGetUserRolesResponseData: (
    data: GetCompanyUserRolesResponseData
  ): Role[] =>
    data.roles.map((role) => ({
      createdAt: role.created_at ? convertFromApiFormat(role.created_at) : null,
      defaultAdminRole: role.default_admin_role,
      defaultBasicRole: role.default_basic_role,
      description: role.description,
      displayName: role.display_name,
      id: role.id,
      modifiedAt: role.modified_at
        ? convertFromApiFormat(role.modified_at)
        : null,
      modifiedBy: role.modified_by,
      permissions: role.permissions,
    })),
};
