import AuthApi, { AdminAuthApi } from "services/AuthApi";
import { getAuthServiceToken } from "utils/authentication";

export async function deleteAuthToken(): Promise<null> {
  const cnstrcToken = getAuthServiceToken();

  await AuthApi.delete<void>(["tokens"], {}, { bearer_token: cnstrcToken });
  return Promise.resolve(null);
}

export async function deleteAdminToken() {
  const adminToken = document.querySelector<HTMLMetaElement>(
    '[name="cnstrc_admin_token"]'
  );

  if (adminToken?.content) {
    await AdminAuthApi.delete<void>(
      ["tokens"],
      {},
      { bearer_token: adminToken.content }
    );
  }

  return Promise.resolve(null);
}
