import React from "react";
import styled from "@emotion/styled";
import { SerializedStyles } from "@emotion/react";

// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme from "components/theme";
import InfoTooltip from "components/Modules/InfoTooltip";

const LabelWrapper = styled.label`
  display: block;
  margin-bottom: 4px;
`;

export const LabelTitle = styled.span`
  color: ${theme.colors.textGrayDark};
  font-weight: 400;
  font-size: 13px;
  font-weight: bold;
`;

function Label({ children, help, labelStyle, ...props }: Props) {
  return (
    <LabelWrapper {...props}>
      <LabelTitle css={labelStyle}>{children}</LabelTitle>
      {help ? <InfoTooltip tooltip={help} /> : null}
    </LabelWrapper>
  );
}

type Props = React.ComponentProps<typeof LabelWrapper> & {
  children?: React.ReactNode;
  help?: string;
  labelStyle?: SerializedStyles;
};

export default Label;
