import { millisecondsInMinute } from "date-fns";
import { Required, DeepPartial } from "utility-types";

import Api from "services/Api";

import { Index } from "modules/indexes";
import { NotFoundError } from "modules/appError";
import reportError from "services/ReportError";

import * as types from "./types";
import { wait } from "./actions/utils";

export async function list({
  index,
  ...query
}: ListOptions): Promise<types.List> {
  return Api.get<types.ListData>(["api", "facets"], {
    indexKey: index.indexKey,
    section: index.defaultSearchSection,
    ...query,
  }).then((response) => ({
    totalCount: response.total_count,
    facets: response.facets.map((facet) => types.ListFacet.fromData(facet)),
  }));
}

list.key = "modules/facets/list";

export async function listAll({ index }: Options): Promise<types.List> {
  let page = 1;

  const result = await list({ index, page, numResultsPerPage: 1000 });

  while (result.facets.length < result.totalCount) {
    page++;

    if (page === 15) {
      // Safeguard so we'll know if users consistently use facets with tons of
      // configurations, in order to optimize.
      reportError(
        `Requesting 15 or more pages when listing facet group configuration. Index key: ${index.indexKey}`
      );
    }

    const pageResult = await list({ index, page, numResultsPerPage: 1000 });
    result.facets = [...result.facets, ...pageResult.facets];
  }

  return result;
}

listAll.key = "modules/facets/listAll";

export async function getFacet(
  facetName: string,
  { index }: Options
): Promise<types.Facet | undefined> {
  try {
    const facetData = await Api.get<types.FacetData>(
      ["api", "facets", facetName],
      {
        indexKey: index.indexKey,
        section: index.defaultSearchSection,
      }
    );

    return types.Facet.fromData(facetData);
  } catch (error) {
    if (error instanceof NotFoundError) {
      return undefined;
    }

    throw error;
  }
}

getFacet.key = "modules/facets/get";

export async function listOptions(
  facetName: string,
  { index, ...query }: ListOptions
): Promise<types.OptionList> {
  const response = await Api.get<types.OptionListData>(
    ["api", "facets", facetName, "options"],
    {
      indexKey: index.indexKey,
      section: index.defaultSearchSection,
      ...query,
    }
  );

  return {
    facetOptions: response.facet_options.map((option) =>
      types.Option.fromData(option)
    ),
    totalCount: response.total_count,
  };
}

export async function listAllOptions(
  facetName: string,
  { index }: Options
): Promise<types.OptionList> {
  let page = 1;

  const result = await listOptions(facetName, {
    index,
    page,
    numResultsPerPage: 1000,
  });

  while (result.facetOptions.length < result.totalCount) {
    page++;

    if (page === 15) {
      // Safeguard so we'll know if users consistently use facets with tons of
      // configurations, in order to optimize.
      reportError(
        `Requesting 15 or more pages when listing facet option configuration. Index key: ${index.indexKey}, facet group name: ${facetName}`
      );
    }

    const pageResult = await listOptions(facetName, {
      index,
      page,
      numResultsPerPage: 1000,
    });
    result.facetOptions = [...result.facetOptions, ...pageResult.facetOptions];
  }

  return result;
}

listAllOptions.key = "modules/facets/listAllOptions";

export async function getOption(
  facetName: string,
  optionValue: string,
  { index }: Options
) {
  try {
    const facet_option = await Api.get<types.OptionData>(
      ["api", "facets", facetName, "options", optionValue],
      {
        indexKey: index.indexKey,
        section: index.defaultSearchSection,
      }
    );
    return types.Option.fromData(facet_option);
  } catch (error) {
    if (error instanceof NotFoundError) {
      return undefined;
    }

    throw error;
  }
}

type ListOptions = Options & {
  page?: number;
  numResultsPerPage?: number;
};

export async function create(
  data: Required<DeepPartial<types.Facet>, "type" | "name">,
  { index }: Options
): Promise<types.Facet> {
  const facet = await Api.post<types.FacetData>(
    ["api", "facets"],
    { indexKey: index.indexKey, section: index.defaultSearchSection },
    types.Facet.toData(data)
  );

  return types.Facet.fromData(facet);
}

export async function deleteFacet(
  facetName: string,
  { index }: Options
): Promise<void> {
  await Api.delete(["api", "facets", facetName], {
    indexKey: index.indexKey,
    section: index.defaultSearchSection,
  });
}

export async function createOption(
  facetName: string,
  data: Partial<types.Option>,
  { index, skipRebuildIndex }: OptionOptions
): Promise<types.Option> {
  const option = await Api.post<types.OptionData>(
    ["api", "facets", facetName, "options"],
    {
      indexKey: index.indexKey,
      section: index.defaultSearchSection,
      skipRebuildIndex,
    },
    types.Option.toData(data)
  );

  return types.Option.fromData(option);
}

export async function update(
  name: string,
  data: Partial<types.Facet>,
  { index }: Options
): Promise<types.Facet> {
  const facet = await Api.patch<types.FacetData>(
    ["api", "facets", name],
    { indexKey: index.indexKey, section: index.defaultSearchSection },
    types.Facet.toData(data)
  );

  return types.Facet.fromData(facet);
}

export async function updateOption(
  facetName: string,
  value: string,
  data: Partial<types.Option>,
  { index, skipRebuildIndex }: OptionOptions
): Promise<types.Option> {
  const option = await Api.patch<types.OptionData>(
    ["api", "facets", facetName, "options", value],
    {
      indexKey: index.indexKey,
      section: index.defaultSearchSection,
      skipRebuildIndex,
    },
    types.Option.toData(data)
  );

  return types.Option.fromData(option);
}

type Options = { index: Index };

type OptionOptions = {
  index: Index;
  skipRebuildIndex?: boolean;
};

// creates or updates option depending on it's existence in facet
export async function saveOption(
  facet: types.Facet,
  value: string,
  data: Partial<types.Option>,
  options: OptionOptions
): Promise<types.Option> {
  const foundOption = await getOption(facet.name, value, {
    index: options.index,
  });
  return foundOption
    ? await updateOption(facet.name, value, data, options)
    : await createOption(facet.name, { value, ...data }, options);
}

/**
 * Sets all the given options as being aliased to the given valueAlias.
 * If an option wasn't previously aliased to the given valueAlias, it will be
 * aliased and hidden.
 * If an option was already aliased to the given valueAlias, it will remain
 * untouched.
 * If another option for the given facet was already aliased to the given
 * valueAlias, it will be unaliased and made visible.
 *
 * @param facet The facet to alias options for.
 * @param valueAlias The alias to set for the options.
 * @param options The options to alias to `valueAlias`.
 */
export async function setFacetMergeOptions(
  facet: types.Facet,
  valueAlias: string,
  options: types.Option[],
  {
    onChange,
    ...args
  }: OptionOptions & { onChange?: (option: types.Option) => void }
): Promise<types.Option[]> {
  const results: types.Option[] = [];
  const currentOptions = (facet.options || []).filter(
    (option) => option.valueAlias === valueAlias
  );

  const newOptions = options.filter((option) =>
    currentOptions.every(
      (currentOptions) => currentOptions.value !== option.value
    )
  );

  // Each call generates 2 requests. The limit in API is 100 requests per minute.
  // 45 calls per minute should be safe.
  const delay = millisecondsInMinute / 45;

  for (const option of newOptions) {
    await wait(delay);

    const data: Partial<types.Option> = {
      hidden: true,
      valueAlias: valueAlias,
    };

    // TODO: This can be done in parallel since each option can be saved
    // independently
    const result = await saveOption(facet, option.value, data, args);
    results.push(result);

    if (onChange) {
      onChange(result);
    }
  }

  const removedOptions = currentOptions.filter((currentOption) =>
    options.every((mergedOption) => mergedOption.value !== currentOption.value)
  );

  for (const option of removedOptions) {
    await wait(delay);

    const data: Partial<types.Option> = { hidden: false, valueAlias: null };
    const result = await saveOption(facet, option.value, data, args);
    results.push(result);

    if (onChange) {
      onChange(result);
    }
  }

  return results;
}
