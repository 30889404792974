import * as actions from "modules/authentication/actions";

import getQueryHook from "app/hooks/getQueryHook";
import {
  Company,
  NextPageCursor,
} from "modules/authentication/actions/shared/types";
import {
  GetCompaniesByUserParams,
  GetCompaniesByUserResponse,
} from "modules/authentication/actions/users/getCompaniesByUser";

const useGetCompaniesByUser = getQueryHook(
  actions.getCompaniesByUser.key,
  () => async (args: Parameters<typeof actions.getCompaniesByUser>[0]) => {
    const { userId } = args;

    if (!userId) {
      return null;
    }

    let nextPageCursor: NextPageCursor["nextPageCursor"] = "";
    let data: Company[] = [];

    while (nextPageCursor !== null) {
      const params: GetCompaniesByUserParams = { userId, nextPageCursor };
      const response: GetCompaniesByUserResponse =
        await actions.getCompaniesByUser(params);

      data = [...data, ...response.companies];
      nextPageCursor = response.nextPageCursor;
    }

    return data;
  },
  {
    refetchOnMount: true,
  }
);

export default useGetCompaniesByUser;
