import { MfaApi } from "services/AuthApi";

export type GenerateMFATokenParams = {
  userId: string;
};

/**
 * @description Generate a token for MFA and sends it to the user's email address.
 * The token is invalidated after being used once.
 * Generating a new token invalidates the previous one.
 * To authenticate, authorization header must contain valid token.
 * This endpoint can only be called with user token, and can not be called with pregenerated token.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Users/operation/v1-auth-users-create-email-mfa-tokens
 * @returns {GenerateMFATokenParams}
 */
export default async function generateMFAToken({
  userId,
}: GenerateMFATokenParams): Promise<void> {
  return await MfaApi.post([
    "v1",
    "users",
    userId,
    "generate-email-mfa-tokens",
  ]);
}

generateMFAToken.key = "modules/authentication/actions/users/generateMFAToken";
