import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

import { Box, Text, VStack, HStack } from "@chakra-ui/react";

// TODO: Replace this import with date-fns
// eslint-disable-next-line no-restricted-imports
import moment from "modules/moment";

import URI from "utils/urijs";
// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme, { styles } from "components/theme";
import NavbarDropdown from "components/Modules/NavbarDropdown";
import DateRangePicker from "components/Modules/DateRangePicker";
// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import Button from "components/Modules/Button";

import DateInput from "./DateRangeFilter/DateInput";
import OptionsList from "./DateRangeFilter/OptionsList";

const DATE_FORMAT = "YYYY-MM-DD";

export default function DateRangeFilter({
  startDate: startDateString,
  endDate: endDateString,
  onChange = ({ startDate, endDate }) => {
    window.location.href = URI()
      .setSearch("start_date", startDate.format(DATE_FORMAT))
      .setSearch("end_date", endDate.format(DATE_FORMAT))
      .toString();
  },
  headline = DEFAULT_HEADLINE,
  singleSelection = false,
}: Props) {
  const initialStartDate = moment(startDateString, DATE_FORMAT);
  const initialEndDate = moment(endDateString, DATE_FORMAT);

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  useEffect(() => {
    setStartDate(moment(startDateString, DATE_FORMAT));
    setEndDate(moment(endDateString, DATE_FORMAT));
  }, [startDateString, endDateString]);

  const validDateRange = moment(endDate).isSameOrAfter(startDate);

  let displayDate = initialStartDate.format("MMM D, YYYY");
  if (!initialStartDate.isSame(initialEndDate, "day")) {
    displayDate += ` - ${initialEndDate.format("MMM D, YYYY")}`;
  }

  const onSelectionChange = (newSelection: {
    from: moment.Moment;
    to: moment.Moment;
  }) => {
    setStartDate(newSelection.from);
    setEndDate(newSelection.to);
  };

  const selectRange = (from: moment.Moment, to?: moment.Moment) => {
    onSelectionChange({ from: from, to: to || from });
  };

  const onApply = () => {
    onChange({ startDate, endDate });
  };

  const reset = () => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
  };

  const getOption = ({
    startDate,
    endDate,
    label,
  }: {
    startDate: moment.Moment;
    endDate?: moment.Moment;
    label: string;
  }) => {
    return (
      <Option
        onClick={() =>
          selectRange(
            startDate,
            !singleSelection && endDate ? endDate : undefined
          )
        }
      >
        <Text
          as="span"
          display="inline-flex"
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="center"
          width="205px"
        >
          {label}
          {singleSelection && (
            <Text
              as="span"
              display="inline-block"
              marginLeft="auto"
              fontSize="13px"
              fontWeight="400"
              lineHeight="16px"
              color="grey"
            >
              {startDate.format("MMM D, YYYY")}
            </Text>
          )}
        </Text>
      </Option>
    );
  };

  return (
    <NavbarDropdown
      headline={headline}
      name={displayDate}
      onClose={reset}
      alignRight
    >
      {({ closeDropdown }: { closeDropdown: () => void }) => (
        <Container>
          <Body>
            <Box display="flex" flexDirection="column" marginRight="10px">
              <DatesDisplay>
                <HStack spacing="28px" alignItems="flex-start">
                  <DateInput
                    className="form-control cio-form__control"
                    value={startDate}
                    onChange={(newDate) => setStartDate(newDate)}
                  />
                  {!singleSelection && (
                    <VStack align="stretch">
                      <DateInput
                        className="form-control cio-form__control"
                        value={endDate}
                        onChange={(newDate) => setEndDate(newDate)}
                        isError={!validDateRange}
                      />
                      {!validDateRange && (
                        <Box color="danger" fontSize="12px">
                          End date cannot be earlier than start date
                        </Box>
                      )}
                    </VStack>
                  )}
                </HStack>
              </DatesDisplay>
              <Box marginBottom="14px" marginTop="16px">
                <DateRangePicker
                  numberOfMonths={
                    singleSelection ? 1 : DEFAULT_NUMBER_OF_MONTHS
                  }
                  startDate={startDate.toDate()}
                  endDate={endDate.toDate()}
                  toMonth={new Date()}
                  disabledDays={{ after: new Date() }}
                  onSelectionChanged={(newSelection: {
                    from: Date;
                    to: Date;
                  }) => {
                    if (
                      moment(newSelection.from).isAfter(moment(), "day") ||
                      moment(newSelection.to).isAfter(moment(), "day")
                    ) {
                      return;
                    }

                    onSelectionChange({
                      from: moment(newSelection.from),
                      to: singleSelection
                        ? moment(newSelection.from)
                        : moment(newSelection.to),
                    });
                  }}
                />
              </Box>
            </Box>
            <PeriodsContainer>
              <OptionsList>
                {getOption({
                  startDate: moment(),
                  label: "Today",
                })}

                {getOption({
                  startDate: moment().subtract(1, "day"),
                  label: "Yesterday",
                })}

                {getOption({
                  startDate: moment().subtract(7, "days"),
                  endDate: moment().subtract(1, "day"),
                  label: "Last 7 days",
                })}

                {getOption({
                  startDate: moment().subtract(30, "days"),
                  endDate: moment().subtract(1, "day"),
                  label: "Last 30 days",
                })}

                {getOption({
                  startDate: moment().startOf("month"),
                  endDate: moment().isSame(moment().startOf("month"), "day")
                    ? moment()
                    : moment().subtract(1, "day"),
                  label: "This month",
                })}

                {getOption({
                  startDate: moment().subtract(1, "month").startOf("month"),
                  endDate: moment().subtract(1, "month").endOf("month"),
                  label: "Last month",
                })}

                {singleSelection &&
                  getOption({
                    startDate: initialStartDate,
                    endDate: initialEndDate,
                    label: "Custom range",
                  })}
              </OptionsList>
            </PeriodsContainer>
          </Body>
          <Footer>
            <Button size="small" kind="secondary" onClick={closeDropdown}>
              Discard
            </Button>

            <Button
              disabled={!validDateRange}
              kind="primary"
              size="small"
              title={validDateRange ? "" : "Invalid date range"}
              onClick={() => {
                onApply();
                closeDropdown();
              }}
            >
              Apply
            </Button>
          </Footer>
        </Container>
      )}
    </NavbarDropdown>
  );
}

type Props = {
  singleSelection?: boolean;
  headline?: string;
  startDate: string;
  endDate: string;
  onChange?: ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  }) => void;
};

const Option = styled(OptionsList.Option)`
  color: #505a75;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
`;

const Container = styled.div`
  ${styles.box};
  overflow: hidden;
`;

const Body = styled.div`
  padding: 30px;
  display: flex;
`;

const DatesDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const PeriodsContainer = styled.div``;

const Footer = styled.div`
  background-color: ${theme.colors.backgroundGrayDark};
  padding: 22px 30px;
  text-align: right;
`;

const DEFAULT_HEADLINE = "Date";

const DEFAULT_NUMBER_OF_MONTHS = 2;
