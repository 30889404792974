import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import "focus-visible/dist/focus-visible";
import { enableMapSet } from "immer";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as Sentry from "@sentry/react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import * as IndexKeyId from "utils/IndexKeyId";
import * as IndexKeyValue from "utils/IndexKeyValue";
import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

import { Cache as CurrentIndexCache } from "app/hooks/useCurrentIndex";
import useHighchartsReflow from "app/hooks/useHighchartsReflow";

import GlobalStyle from "components/Modules/GlobalStyle";
import NewVersionNotification from "components/NewVersionNotification";
import DelightedSurvey from "components/DelightedSurvey";
import MaintainanceBannerWrapper from "components/shared/MaintainanceBannerWrapper";
import SessionWatcher from "components/SessionWatcher";

import ReactQueryProvider from "app/providers/ReactQuery";

import MainRoutes from "app/pages/main";
import SearchandizingRoutes from "app/pages/searchandizing";
import RecommendationRoutes from "app/pages/recommendations";
import NotificationCampaignsRoutes from "app/pages/notificationCampaigns";
import QuizzesRoutes from "app/pages/quizzes";
import AnalyticsRoutes from "app/pages/analytics";
import IntegrationRoutes from "app/pages/integration";

import "utils/amplitude";

import ThemeProvider from "./providers/ThemeProvider";
import ToastProvider from "./providers/ToastProvider";
import RootContext, { useRootContext } from "./providers/RootContext";
import InternationalizeProvider from "./providers/InternationalizeProvider";
import RouterProvider from "./providers/RouterProvider";
import QueryParamsProvider from "./providers/QueryParamsProvider";
import LoadingStateConfigProvider, {
  DEFAULT_SPINNER_DELAY,
} from "./providers/LoadingStateConfigProvider";
import ConfirmProvider from "./providers/ConfirmProvider";
import useAssetsFromS3CDNIfEnabled from "./utils/useAssetsFromS3CDNIfEnabled";
import AuthTokenContextProvider from "./providers/AuthTokenContext";

function Routes() {
  useHighchartsReflow();

  return (
    <>
      <MainRoutes />
      <SearchandizingRoutes />
      <RecommendationRoutes />
      <NotificationCampaignsRoutes />
      <QuizzesRoutes />
      <AnalyticsRoutes />
      <IntegrationRoutes />
    </>
  );
}

// @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Configuration(props: any) {
  useEffect(() => {
    const saveIndexKey = () =>
      (isFlagEnabled(FeatureFlags.EnableAcidDeprecate)
        ? IndexKeyValue
        : IndexKeyId
      ).dumpToCookies();
    window.addEventListener("focus", saveIndexKey);
    return () => window.removeEventListener("focus", saveIndexKey);
  });

  const { children, ...rootContextProps } = props;
  const rootContext = useRootContext(rootContextProps);

  return (
    <RootContext.Provider value={rootContext}>
      <ThemeProvider>
        <ReactQueryProvider>
          <ReactQueryDevtools />
          <CurrentIndexCache>
            <InternationalizeProvider>
              <RouterProvider>
                <LoadingStateConfigProvider
                  minimumSpinnerDelay={DEFAULT_SPINNER_DELAY}
                >
                  <QueryParamsProvider>
                    <DndProvider backend={HTML5Backend}>
                      <AuthTokenContextProvider>
                        {children}
                      </AuthTokenContextProvider>
                    </DndProvider>
                  </QueryParamsProvider>
                </LoadingStateConfigProvider>
              </RouterProvider>
            </InternationalizeProvider>
          </CurrentIndexCache>
        </ReactQueryProvider>
      </ThemeProvider>
    </RootContext.Provider>
  );
}

// @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function App(props: any) {
  enableMapSet();

  // Allows JS assets to be downloaded from S3 CDN
  useAssetsFromS3CDNIfEnabled();

  return (
    <Configuration {...props}>
      <ToastProvider>
        <ConfirmProvider>
          <DelightedSurvey />
          <GlobalStyle />
          <NewVersionNotification />
          <MaintainanceBannerWrapper
            startTime={new Date(Date.UTC(2025, 2, 11, 12, 0))}
            endTime={new Date(Date.UTC(2025, 2, 11, 13, 0))}
          />
          <SessionWatcher>
            <Switch>
              <Routes />
            </Switch>
          </SessionWatcher>
        </ConfirmProvider>
      </ToastProvider>
    </Configuration>
  );
}

export default Sentry.withErrorBoundary(App, {});
