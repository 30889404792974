import AuthApi from "services/AuthApi";

import { Role, RoleData } from "../shared/types";

/**
 * @description Creates a new role within a specified company.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-get-role
 * @returns {Role}
 */
export default async function getRole({
  companyId,
  roleId,
}: Props): Promise<Role> {
  const result = await AuthApi.get<RoleData>([
    "v1",
    "companies",
    companyId,
    "roles",
    roleId,
  ]);

  return Role.parse(result);
}

getRole.key = "modules/authentication/actions/companies/getRole";

type Props = {
  companyId: string;
  roleId: string;
};
