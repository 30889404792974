import React, { useEffect, useState } from "react";

import { Box, Flex, Text } from "@chakra-ui/react";

// Mutation in this component is not intended to be used anywhere else in the codebase, so creating
// a re-usable hook for it would not be necessary. Instead, we are using it directly in this component
// to simplify the code and avoid unnecessary abstraction
// eslint-disable-next-line no-restricted-imports
import { useMutation } from "@tanstack/react-query";

import BaseModal from "app/designSystem/components/Modal/BaseModal/BaseModal";
import { Alert, Button } from "app/designSystem/components";
import { fetchCurrentUser } from "modules/currentUser";
import * as routes from "utils/routes";
// TODO: Replace this import with date-fns
// eslint-disable-next-line no-restricted-imports
import dayjs from "utils/dayjs";

const SessionExpirationWarningModal: React.FC<Props> = ({
  sessionExpiresAt,
}: Props) => {
  const [remainingTime, setRemainingTime] = useState(() =>
    calculateRemainingTime(sessionExpiresAt)
  );

  // To refresh the session we need to do any request to server. We are fetching user here with
  // useMutation as this suits better in this case provinding error handling and loading state.
  const { error, mutate, isLoading } = useMutation(() => fetchCurrentUser());

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(calculateRemainingTime(sessionExpiresAt));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [sessionExpiresAt]);

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  return (
    <BaseModal
      isOpen
      onClose={() => {
        mutate();
      }}
      title="Are you still there?"
      isCloseable={false}
      body={
        <Flex direction="column" pt="38px" mb="-32px">
          <Text
            color="grey"
            fontSize={28}
            fontWeight={600}
            lineHeight={8}
            m={0}
            mb="16px"
            role="timer"
            textAlign="center"
          >
            {minutes}:{seconds.toString().padStart(2, "0")}
          </Text>

          <Text
            color="grey-dark"
            m={0}
            mb={error ? "40px" : "64px"}
            textAlign="center"
            textStyle="body1"
          >
            You will be automatically logged out soon.
            <br />
            Please save any work in progress.
          </Text>

          {!!error && (
            <Box mx="-16px" mb="24px">
              <Alert type="warning" noClose>
                We&apos;re experiencing some turbulence in the internet
                connection. Please refresh or retry to continue.
              </Alert>
            </Box>
          )}
        </Flex>
      }
      rightFooter={
        <>
          <Button
            variant="ghost"
            size="lg"
            onClick={() => {
              window.location.href = routes.signOut().toString();
            }}
          >
            Logout
          </Button>
          <Button
            size="lg"
            isLoading={isLoading}
            onClick={() => {
              mutate();
            }}
          >
            Continue working
          </Button>
        </>
      }
    />
  );
};

export default SessionExpirationWarningModal;

type Props = {
  sessionExpiresAt: Date;
};

function calculateRemainingTime(sessionExpiresAt: Date): number {
  let remainingTime = dayjs(sessionExpiresAt).diff(dayjs(), "seconds");

  if (remainingTime < 0) {
    remainingTime = 0;
  }

  return remainingTime;
}
