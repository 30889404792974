import React from "react";

// the types for the "react-confirm" are not complete
import {
  // @ts-ignore
  createConfirmationCreater,
  // @ts-ignore
  createReactTreeMounter,
  // @ts-ignore
  createMountPoint,
  confirmable,
} from "react-confirm";

const mounter = createReactTreeMounter();
const MountPoint = createMountPoint(mounter);

const ConfirmProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      {children}
      {process.env.NODE_ENV === "test" && <MountPoint />}
    </>
  );
};

const createConfirmation = createConfirmationCreater(mounter);

export { confirmable, createConfirmation, MountPoint };

export default ConfirmProvider;
