import styled from "@emotion/styled";
import { css } from "@emotion/react";

/*
  # Adding new icons
  * Add the `.svg` file on `./Icon`.
  * Add the icon to the object below, along with its intended dimensions and any
    custom style it may require.
  * Prefer cropping icons to the minimum size they require to be shown. Margin
    should be added by the consumers depending on the context.
*/
const ICONS = createIcons({
  add: {
    path: "add.svg",
    width: "24px",
    height: "24px",
    style: css`
      button:disabled & {
        background-image: url(${require("./Icon/add-disabled.svg")});
      }
    `,
  },
  "add-contextual": {
    path: "add-contextual.svg",
    width: "24px",
    height: "24px",
    style: css`
      button:disabled & {
        background-image: url(${require("./Icon/add-disabled.svg")});
      }
    `,
  },
  analytics: {
    path: "analytics.svg",
    width: "16px",
    height: "16px",
  },
  edit: {
    path: "edit.svg",
    width: "12px",
    height: "12px",
  },
  "edit-disabled": {
    path: "edit-disabled.svg",
    width: "16px",
    height: "16px",
  },
  "edit-row": {
    path: "edit-row.svg",
    width: "16px",
    height: "16px",
  },
  "edit-row-action": {
    path: "edit-row-action.svg",
    width: "16px",
    height: "16px",
  },
  "edit-dark": {
    path: "edit-dark.svg",
    width: "12px",
    height: "12px",
  },
  delete: {
    path: "delete.svg",
    width: "16px",
    height: "16px",
    style: css`
      background-size: 16px 16px;

      a:hover &,
      a:focus &,
      button:hover &,
      button:focus & {
        background-image: url(${require("./Icon/delete-hover.svg")});
      }

      a:disabled &,
      button:disabled & {
        background-image: url(${require("./Icon/delete-disabled.svg")});
      }
    `,
  },
  "delete-disabled": {
    path: "delete-disabled.svg",
    width: "16px",
    height: "16px",
  },
  "delete-hollow": {
    path: "delete-hollow.svg",
    width: "16px",
    height: "16px",
  },
  x: {
    path: "x.svg",
    width: "14px",
    height: "14px",
  },
  "x-large": {
    path: "x-large.svg",
    width: "24px",
    height: "24px",
  },
  "x-light": {
    path: "x-light.svg",
    width: "14px",
    height: "14px",
  },
  plus: {
    path: "plus.svg",
    width: "14px",
    height: "14px",
  },
  info: {
    path: "info.svg",
    width: "14px",
    height: "14px",
  },
  "info-dark": {
    path: "info-dark.svg",
    width: "14px",
    height: "14px",
  },
  "alert-info": {
    path: "alert-info.svg",
    width: "16px",
    height: "16px",
  },
  "alert-success": {
    path: "alert-success.svg",
    width: "16px",
    height: "16px",
  },
  "alert-warning": {
    path: "alert-warning.svg",
    width: "16px",
    height: "16px",
  },
  "alert-danger": {
    path: "alert-danger.svg",
    width: "16px",
    height: "16px",
  },
  "alert-info-inverse": {
    path: "alert-info-inverse.svg",
    width: "16px",
    height: "16px",
  },
  left: {
    path: "left.svg",
    width: "6px",
    height: "8px",
  },
  right: {
    path: "right.svg",
    width: "6px",
    height: "8px",
  },
  calendar: {
    path: "calendar.svg",
    width: "20px",
    height: "20px",
  },
  "calendar-grey": {
    path: "calendar-grey.svg",
    width: "16px",
    height: "16px",
  },
  "navigation-dashboard": {
    path: "navigation-dashboard.svg",
    width: "16px",
    height: "16px",
  },
  "navigation-dashboard-active": {
    path: "navigation-dashboard-active.svg",
    width: "16px",
    height: "16px",
  },
  "navigation-analytics": {
    path: "navigation-analytics.svg",
    width: "16px",
    height: "16px",
  },
  "navigation-analytics-active": {
    path: "navigation-analytics-active.svg",
    width: "16px",
    height: "16px",
  },
  "navigation-integration": {
    path: "navigation-integration.svg",
    width: "16px",
    height: "16px",
  },
  "navigation-integration-active": {
    path: "navigation-integration-active.svg",
    width: "16px",
    height: "16px",
  },
  "navigation-docs": {
    path: "navigation-docs.svg",
    width: "16px",
    height: "16px",
  },
  "navigation-docs-active": {
    path: "navigation-docs-active.svg",
    width: "16px",
    height: "16px",
  },
  "navigation-interact": {
    path: "navigation-interact.svg",
    width: "16px",
    height: "16px",
  },
  "navigation-interact-black": {
    path: "navigation-interact-black.svg",
    width: "16px",
    height: "16px",
  },
  "navigation-interact-active": {
    path: "navigation-interact-active.svg",
    width: "16px",
    height: "16px",
  },
  search: {
    path: "search.svg",
    width: "18px",
    height: "18px",
  },
  ["search-light"]: {
    path: "search.svg",
    width: "18px",
    height: "18px",
    style: css`
      opacity: 0.28;
    `,
  },
  chevron: {
    path: "chevron.svg",
    width: "24px",
    height: "24px",
  },
  "chevron-white": {
    path: "chevron-white.svg",
    width: "24px",
    height: "24px",
  },
  "chevron-disabled": {
    path: "chevron-disabled.svg",
    width: "24px",
    height: "24px",
  },
  "chevron-previous": {
    path: "chevron-previous.svg",
    width: "24px",
    height: "24px",
  },
  "chevron-previous-disabled": {
    path: "chevron-previous-disabled.svg",
    width: "24px",
    height: "24px",
  },
  "chevron-up": {
    path: "chevron-up.svg",
    width: "24px",
    height: "24px",
  },
  "chevron-down": {
    path: "chevron-down.svg",
    width: "24px",
    height: "24px",
  },
  "chevron-increase": {
    path: "chevron-increase.svg",
    width: "16px",
    height: "16px",
  },
  "chevron-decrease": {
    path: "chevron-decrease.svg",
    width: "16px",
    height: "16px",
  },
  "chevron-secondary": {
    path: "chevron-secondary.svg",
    width: "24px",
    height: "24px",
  },
  "chevron-secondary-cropped": {
    path: "chevron-secondary-cropped.svg",
    width: "4px",
    height: "7px",
  },
  "chevron-secondary-gray": {
    path: "chevron-secondary-gray.svg",
    width: "24px",
    height: "24px",
  },
  "chevron-expanded": {
    path: "chevron-expanded.svg",
    width: "16px",
    height: "16px",
    style: "margin-right: 8px; left: 4px;",
  },
  "chevron-secondary-down": {
    path: "chevron-secondary-down.svg",
    width: "24px",
    height: "24px",
  },
  "chevron-delimiter": {
    path: "chevron-delimiter.svg",
    width: "16px",
    height: "16px",
  },
  decrease: {
    path: "decrease.svg",
    width: "24px",
    height: "24px",
  },
  increase: {
    path: "increase.svg",
    width: "24px",
    height: "24px",
  },
  new: {
    path: "star.svg",
    width: "16px",
    height: "16px",
  },
  performance: {
    path: "performance.svg",
    width: "24px",
    height: "24px",
  },
  opportunities: {
    path: "opportunities.svg",
    width: "24px",
    height: "24px",
  },
  "one-way": {
    path: "one-way-default.svg",
    width: "12px",
    height: "10px",
  },
  "one-way-selected": {
    path: "one-way-selected.svg",
    width: "12px",
    height: "10px",
  },
  "two-way": {
    path: "two-way-default.svg",
    width: "17px",
    height: "10px",
  },
  "two-way-selected": {
    path: "two-way-selected.svg",
    width: "17px",
    height: "10px",
  },
  "arrow-left": {
    path: "arrow-left.svg",
    width: "24px",
    height: "24px",
  },
  spinner: {
    path: "spinner.svg",
    width: "20px",
    height: "20px",
  },
  logo: {
    path: "logo.svg",
    width: "114px",
    height: "25px",
  },
  close: {
    path: "close.svg",
    width: "16px",
    height: "16px",
  },
  "close-large": {
    path: "close-large.svg",
    width: "24px",
    height: "24px",
  },
  "close-grey": {
    path: "close-grey.svg",
    width: "16px",
    height: "16px",
  },
  back: {
    path: "back.svg",
    width: "24px",
    height: "24px",
  },
  "back-dark": {
    path: "back-dark.svg",
    width: "24px",
    height: "24px",
  },
  "check-alert": {
    path: "check-alert.svg",
    width: "16px",
    height: "16px",
  },
  cart: {
    path: "cart.svg",
    width: "16px",
    height: "16px",
  },
  "magnifying-glass": {
    path: "magnifying-glass.svg",
    width: "16px",
    height: "16px",
  },
  personalization: {
    path: "personalization.svg",
    width: "24px",
    height: "24px",
  },
  "personalization-badge": {
    path: "personalization-badge.svg",
    width: "24px",
    height: "24px",
  },
  slotted: {
    path: "slotted.svg",
    width: "24px",
    height: "24px",
  },
  "slot-arrows": {
    path: "slot-arrows.svg",
    width: "16px",
    height: "16px",
  },
  "slot-arrows-disabled": {
    path: "slot-arrows-disabled.svg",
    width: "16px",
    height: "16px",
  },
  "slotted-arrows": {
    path: "slotted-arrows.svg",
    width: "16px",
    height: "16px",
  },
  "scheduled-slotted": {
    path: "scheduled-slotting.svg",
    width: "24px",
    height: "24px",
  },
  drag: {
    path: "drag.svg",
    width: "16px",
    height: "16px",
  },
  warning: {
    path: "warning.svg",
    width: "16px",
    height: "16px",
  },
  interact: {
    path: "interact.svg",
    width: "12px",
    height: "12px",
  },
  "interact-action": {
    path: "interact-action.svg",
    width: "16px",
    height: "16px",
  },
  "star-gray": {
    path: "star-gray.svg",
    width: "16px",
    height: "16px",
  },
  searchandizing: {
    path: "searchandizing.svg",
    width: "16px",
    height: "16px",
  },
  "searchandizing-action": {
    path: "searchandizing-action.svg",
    width: "16px",
    height: "16px",
    style: css`
      button:disabled &,
      a:disabled &,
      a[disabled] & {
        background-image: url(${require("./Icon/searchandizing-disabled.svg")});
      }
    `,
  },
  "searchandizing-big": {
    path: "searchandizing-big.svg",
    width: "24px",
    height: "24px",
  },
  "calendar-dark": {
    path: "calendar-dark.svg",
    width: "16px",
    height: "16px",
  },
  "calendar-light": {
    path: "calendar-light.svg",
    width: "16px",
    height: "16px",
  },
  "show-more": {
    path: "show-more.svg",
    width: "16px",
    height: "16px",
  },
  "show-less": {
    path: "show-less.svg",
    width: "16px",
    height: "16px",
  },
  searchandize: {
    path: "searchandize.svg",
    width: "16px",
    height: "16px",
  },
  preview: {
    path: "preview.svg",
    width: "16px",
    height: "16px",
  },
  eye: {
    path: "eye.svg",
    width: "16px",
    height: "16px",
  },
  "eye-off": {
    path: "eye-off.svg",
    width: "16px",
    height: "16px",
  },
  "eye-off-disabled": {
    path: "eye-off-disabled.svg",
    width: "16px",
    height: "16px",
  },
  global: {
    path: "global.svg",
    width: "16px",
    height: "16px",
  },
  delay: {
    path: "delay.svg",
    width: "16px",
    height: "16px",
  },
  "circle-success": {
    path: "circle-success.svg",
    width: "24px",
    height: "24px",
  },
  docs: {
    path: "docs.svg",
    width: "16px",
    height: "16px",
  },
  copy: {
    path: "copy.svg",
    width: "16px",
    height: "16px",
  },
  lock: {
    path: "lock.svg",
    width: "16px",
    height: "16px",
  },
  "slotted-lock": {
    path: "slotted-lock.svg",
    width: "16px",
    height: "16px",
  },
  "calendar-warning": {
    path: "calendar-warning.svg",
    width: "20px",
    height: "20px",
  },
  "upload-gray": {
    path: "upload-gray.svg",
    width: "16px",
    height: "16px",
  },
  upload: {
    path: "upload.svg",
    width: "16px",
    height: "16px",
  },
  "plus-grey": {
    path: "plus-grey.svg",
    width: "16px",
    height: "16px",
  },
  "plus-add": {
    path: "plus-add.svg",
    width: "16px",
    height: "16px",
  },
  "plus-add-disabled": {
    path: "plus-add-disabled.svg",
    width: "16px",
    height: "16px",
  },
  json: {
    path: "json.svg",
    width: "16px",
    height: "16px",
  },
  download: {
    path: "download.svg",
    width: "16px",
    height: "16px",
  },
  "plus-dark": {
    path: "plus-dark.svg",
    width: "16px",
    height: "16px",
    style: css`
      button:disabled & {
        background-image: url(${require("./Icon/plus-dark-disabled.svg")});
      }
    `,
  },
  facets: {
    path: "facets.svg",
    width: "16px",
    height: "16px",
  },
  "facets-modify": {
    path: "facets-modify.svg",
    width: "16px",
    height: "16px",
  },
  searchability: {
    path: "searchability.svg",
    width: "16px",
    height: "16px",
  },
  "searchability-grey": {
    path: "searchability-grey.svg",
    width: "16px",
    height: "16px",
  },
  link: {
    path: "link.svg",
    width: "16px",
    height: "16px",
  },
  "chevron-thin": {
    path: "chevron-thin.svg",
    width: "16px",
    height: "16px",
  },
  "magnifying-glass-transparent": {
    path: "magnifying-glass-transparent.svg",
    width: "16px",
    height: "16px",
  },
  filter: {
    path: "filter.svg",
    width: "32px",
    height: "32px",
  },
  grid: {
    path: "grid.svg",
    width: "32px",
    height: "32px",
  },
  "not-found": {
    path: "not-found.svg",
    width: "200px",
    height: "207px",
  },
  chore: {
    path: "chore.svg",
    width: "16px",
    height: "16px",
  },
  guide: {
    path: "guide.svg",
    width: "40px",
    height: "40px",
  },
  "search-sessions": {
    path: "search-sessions.svg",
    width: "16px",
    height: "16px",
  },
  "search-queries": {
    path: "search-queries.svg",
    width: "16px",
    height: "16px",
  },
  "search-clicks": {
    path: "search-clicks.svg",
    width: "16px",
    height: "16px",
  },
  "autosuggest-sessions": {
    path: "autosuggest-sessions.svg",
    width: "16px",
    height: "16px",
  },
  "autosuggest-selects": {
    path: "autosuggest-selects.svg",
    width: "16px",
    height: "16px",
  },
  "browse-views": {
    path: "browse-views.svg",
    width: "16px",
    height: "16px",
  },
  "browse-sessions": {
    path: "browse-sessions.svg",
    width: "16px",
    height: "16px",
  },
  "browse-clicks": {
    path: "browse-clicks.svg",
    width: "16px",
    height: "16px",
  },
  "browse-clicks-through": {
    path: "browse-clicks-through.svg",
    width: "16px",
    height: "16px",
  },
  "recommendations-impressions": {
    path: "recommendations-impressions.svg",
    width: "16px",
    height: "16px",
  },
  "recommendations-sessions": {
    path: "recommendations-sessions.svg",
    width: "16px",
    height: "16px",
  },
  "recommendations-clicks": {
    path: "recommendations-clicks.svg",
    width: "16px",
    height: "16px",
  },
  "dashboard-overview-ctr": {
    path: "dashboard-overview-ctr.svg",
    width: "11px",
    height: "12px",
  },
  "dashboard-overview-views": {
    path: "dashboard-overview-views.svg",
    width: "13px",
    height: "12px",
  },
  export: {
    path: "export.svg",
    width: "16px",
    height: "16px",
  },
  clicks_through: {
    path: "clicks_through.svg",
    width: "16px",
    height: "16px",
  },
  conversions: {
    path: "conversions.svg",
    width: "16px",
    height: "16px",
  },
  "add-purple": {
    path: "add-purple.svg",
    width: "16px",
    height: "16px",
  },
  "chevron-down-white": {
    path: "chevron-down-white.svg",
    width: "8px",
    height: "8px",
  },
  "chevron-down-purple": {
    path: "chevron-down-purple.svg",
    width: "8px",
    height: "8px",
  },
  "add-row": {
    path: "add-row.svg",
    width: "24px",
    height: "24px",
  },
  "add-row-disabled": {
    path: "add-row-disabled.svg",
    width: "24px",
    height: "24px",
  },
  "delete-row": {
    path: "delete-row.svg",
    width: "24px",
    height: "24px",
    style: css`
      button:disabled & {
        background-image: url(${require("./Icon/delete-row-disabled.svg")});
      }
    `,
  },
  product: {
    path: "product.svg",
    width: "40px",
    height: "40px",
  },
  notification: {
    path: "notification.svg",
    width: "16px",
    height: "16px",
  },
  "notification-active": {
    path: "notification-active.svg",
    width: "16px",
    height: "16px",
  },
  hide: {
    path: "hide.svg",
    width: "24px",
    height: "24px",
  },
  "replace-dark": {
    path: "replace-dark.svg",
    width: "16px",
    height: "16px",
    style: css`
      button:disabled & {
        background-image: url(${require("./Icon/replace-dark-disabled.svg")});
      }
    `,
  },
  "minus-dark": {
    path: "minus-dark.svg",
    width: "16px",
    height: "16px",
    style: css`
      button:disabled & {
        background-image: url(${require("./Icon/minus-dark-disabled.svg")});
      }
    `,
  },
  "map-pin": {
    path: "map-pin.svg",
    width: "16px",
    height: "16px",
  },
  user: {
    path: "user.svg",
    width: "16px",
    height: "16px",
  },
  geopersonalization: {
    path: "geopersonalization.svg",
    width: "104px",
    height: "82px",
  },
  statistics: {
    path: "statistics.svg",
    width: "32px",
    height: "32px",
  },
  "move-to-position": {
    path: "move-to-position.svg",
    width: "16px",
    height: "16px",
  },
  "white-checkmark": {
    path: "white-checkmark.svg",
    width: "16px",
    height: "16px",
  },
  "cognitive-embeddings-light": {
    path: "cognitive-embeddings-light.svg",
    width: "24px",
    height: "24px",
  },
  "cognitive-embeddings-dark": {
    path: "cognitive-embeddings-dark.svg",
    width: "24px",
    height: "24px",
  },
  "platform-android": {
    path: "platform-android.svg",
    width: "20px",
    height: "20px",
  },
  "platform-apple-ios": {
    path: "platform-apple-ios.svg",
    width: "20px",
    height: "20px",
  },
  "platform-web": {
    path: "platform-web.svg",
    width: "20px",
    height: "20px",
  },
  "product-autosuggest-small": {
    path: "product-autosuggest-small.svg",
    width: "24px",
    height: "24px",
  },
  "product-browse-small": {
    path: "product-browse-small.svg",
    width: "24px",
    height: "24px",
  },
  "product-collections-small": {
    path: "product-collections-small.svg",
    width: "24px",
    height: "24px",
  },
  "product-recommendations-small": {
    path: "product-recommendations-small.svg",
    width: "24px",
    height: "24px",
  },
  "product-search-small": {
    path: "product-search-small.svg",
    width: "24px",
    height: "24px",
  },
  "product-quizzes-small": {
    path: "product-quizzes-small.svg",
    width: "24px",
    height: "24px",
  },
  "product-content-search-small": {
    path: "product-content-search-small.svg",
    width: "24px",
    height: "24px",
  },
  "user-account": {
    path: "user-account.svg",
    width: "16px",
    height: "16px",
  },
  reset: {
    path: "reset.svg",
    width: "16px",
    height: "16px",
  },
  "reset-disabled": {
    path: "reset-disabled.svg",
    width: "16px",
    height: "16px",
  },
  insight: {
    path: "insight.svg",
    width: "24px",
    height: "24px",
  },
});

/**
 * TODO: deprecated Use the design system `Icon` component instead.
 */
const Icon = styled.span<Props>`
  display: inline-block;
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  vertical-align: middle;
  position: relative;

  ${({ icon, url, size, defaultBackgroundSize = false }) => {
    if (url) {
      const width = size instanceof Array ? size[0] : size;
      const height = size instanceof Array ? size[1] : size;

      return css`
        background-image: url(${url});
        ${size ? `width: ${width}px; height: ${height}px;` : ""}
      `;
    }

    if (!icon) {
      return null;
    }

    const { path, width, height, style } = ICONS[icon];

    const finalWidth = size ? `${size}px` : width;
    const finalHeight = size ? `${size}px` : height;

    return css`
      background-image: url(${require("./Icon/" + path)});
      ${!defaultBackgroundSize &&
      `background-size: ${finalWidth} ${finalHeight};`}
      width: ${finalWidth};
      height: ${finalHeight};
      ${style || ""};
    `;
  }};

  ${({ resize = false }) =>
    resize &&
    css`
      background-size: contain;
    `}
`;

export const AVAILABLE_ICONS = Object.keys(ICONS);
export type IconName = keyof typeof ICONS;

type Props = {
  icon?: IconName;
  url?: string;
  size?: string | number | [number, number];
  resize?: boolean;
  defaultBackgroundSize?: boolean;
};

export default Icon;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Label = styled.div``;

// This utility function ensures that the returned object won't have a key of
// type `string`, but rather only the specific keys specified in the given
// object. In practice, that means that only icons we define explicitly are
// valid keys.
// Taken from https://stackoverflow.com/a/54598743 - if we need it again we can
// generalize it.
function createIcons<T>(icons: {
  [K in keyof T]: {
    path: string;
    width: string;
    height: string;
    style?: string | ReturnType<typeof css>;
  };
}) {
  return icons;
}
