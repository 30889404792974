import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Text } from "@chakra-ui/react";

import * as Table from "components/Modules/Table";

import { CompanyInfo } from "./types";
import { PRODUCT_INFO } from "./constants";
import SubscriptionRow from "./CrabsTable/SubscriptionRow";

// 🦀
export default function CrabsTable({ companyInfo }: Props) {
  return (
    <TableWrapper>
      <StyledTable>
        <Table.THead>
          <Table.Tr>
            <Table.Th padding="0 0 16px">
              <Header>Product</Header>
            </Table.Th>
            <Table.Th padding="0 0 16px">
              <Header>Platforms Included</Header>
            </Table.Th>
            <Table.Th padding="0 0 16px"></Table.Th>
          </Table.Tr>
        </Table.THead>
        <Table.TBody>
          {PRODUCT_INFO.map((product) => (
            <SubscriptionRow
              {...product}
              companyInfo={companyInfo}
              key={product.productKey}
            />
          ))}
        </Table.TBody>
      </StyledTable>
    </TableWrapper>
  );
}

const TableWrapper = styled.div`
  width: 100%;
  padding: 24px 23px 4px;
  background: white;
  border-radius: 4px;
  /* TODO: update when shadow chakra styles are documented */
  box-shadow: 0 2px 1px rgba(80, 92, 117, 0.05);
`;

const StyledTable = styled(Table.TableWithoutTracking)`
  width: 100%;
  margin: 0;

  td {
    padding: 0;
  }

  tbody tr:last-of-type td {
    border-bottom: 0;
  }
`;

const Header = ({ children }: { children: ReactNode }) => (
  <Text
    m={0}
    textStyle="subheadline"
    color="grey-dark"
    whiteSpace="nowrap"
    align="left"
  >
    {children}
  </Text>
);

export type Props = {
  companyInfo: CompanyInfo;
};
