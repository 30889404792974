import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

import {
  CommonPageGroupArgs,
  PageGroup,
} from "app/components/shared/shared/types";
import { getIsHistorySupported } from "app/components/shared/shared/utils";

export default function ({ path }: CommonPageGroupArgs): PageGroup {
  return {
    items: [
      {
        label: "Catalog uploads",
        href: "/dashboard/integration/catalog_uploads",
        activePaths: ["/dashboard/integration/catalog_uploads"],
        isEnabled: true,
        hasVisited: true,
        isSubscribed: true,
        isHistorySupported: getIsHistorySupported(
          "/dashboard/integration/catalog_uploads",
          path
        ),
        breadcrumbs: [{ label: "Integration" }, { label: "Catalog uploads" }],
        analyticsLabel: "Catalog_Uploads",
      },
      {
        label: "Connections",
        href: "/dashboard/integration/connections",
        activePaths: ["/dashboard/integration/connections"],
        isEnabled: isFlagEnabled(FeatureFlags.EnableIntegrationConnectionsPage),
        hasVisited: true,
        isSubscribed: true,
        isHistorySupported: getIsHistorySupported(
          "/dashboard/integration/connections",
          path
        ),
        breadcrumbs: [{ label: "Integration" }, { label: "Connections" }],
        analyticsLabel: "Connections",
      },
      {
        label: "Performance",
        href: "/dashboard/integration/performance",
        activePaths: ["/dashboard/integration/performance"],
        isEnabled: true,
        hasVisited: true,
        isSubscribed: true,
        isHistorySupported: getIsHistorySupported(
          "/dashboard/integration/performance",
          path
        ),
        breadcrumbs: [{ label: "Integration" }, { label: "Performance" }],
        analyticsLabel: "Performance",
      },
      {
        label: "API integration",
        href: "/dashboard/integration/api_tokens",
        activePaths: ["/dashboard/integration/api_tokens"],
        isEnabled: true,
        hasVisited: true,
        isSubscribed: true,
        isHistorySupported: getIsHistorySupported(
          "/dashboard/integration/api_tokens",
          path
        ),
        breadcrumbs: [{ label: "Integration" }, { label: "API integration" }],
        analyticsLabel: "API_Integration",
      },
      {
        label: "FTP credentials",
        href: "/dashboard/integration/ftp_credentials",
        activePaths: ["/dashboard/integration/ftp_credentials"],
        isEnabled: isFlagEnabled(FeatureFlags.FtpPassword),
        hasVisited: true,
        isSubscribed: true,
        isHistorySupported: getIsHistorySupported(
          "/dashboard/integration/ftp_credentials",
          path
        ),
        breadcrumbs: [{ label: "Integration" }, { label: "FTP credentials" }],
        analyticsLabel: "FTP_Credentials",
      },
      {
        label: "API docs",
        href: "https://docs.constructor.com",
        activePaths: ["https://docs.constructor.com"],
        isExternal: true,
        isEnabled: true,
        hasVisited: true,
        isSubscribed: true,
        isHistorySupported: false,
        breadcrumbs: [{ label: "Integration" }, { label: "API docs" }],
        analyticsLabel: "API_Docs",
      },
    ],
    index: 2,
    label: "Integration",
    icon: "terminal-16",
    analyticsLabel: "Integration",
  };
}
