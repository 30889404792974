import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

import useCurrentUser from "app/hooks/useCurrentUser";
import RootContext from "app/providers/RootContext";
import { isAuthenticationServiceSession } from "utils/authentication";
import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

import { Links, Link } from "./shared/MenuUI";

export default function AccountsMenu() {
  const location = useLocation();

  const isShowSubscriptionPage = isFlagEnabled(
    FeatureFlags.EnableSubscriptionPage
  );

  const user = useCurrentUser();
  const { canUpdateCompany } = useContext(RootContext);

  const isAuthenticationServiceEnabled = isAuthenticationServiceSession();

  return (
    <Links>
      {!isAuthenticationServiceEnabled && (
        <Link
          to="/dashboard/accounts/profile"
          isActive={location.pathname.startsWith("/dashboard/accounts/profile")}
        >
          Profile
        </Link>
      )}

      {!user.hasSso && !isAuthenticationServiceEnabled && (
        <Link
          to="/dashboard/accounts/password"
          isActive={location.pathname.startsWith(
            "/dashboard/accounts/password"
          )}
        >
          Password
        </Link>
      )}

      {canUpdateCompany && (
        <>
          {isShowSubscriptionPage && (
            <Link
              to="/dashboard/accounts/subscription"
              isActive={location.pathname.startsWith(
                "/dashboard/accounts/subscription"
              )}
            >
              Subscription
            </Link>
          )}
          <Link
            to="/dashboard/accounts/team"
            isActive={location.pathname.startsWith("/dashboard/accounts/team")}
          >
            Team Members
          </Link>
        </>
      )}
    </Links>
  );
}
