import Api from "services/Api";

import * as types from "./types";

export async function list({
  indexKey,
  numResultsPerPage,
  page,
  section,
  id,
}: {
  indexKey: string;
  numResultsPerPage?: number;
  page?: number;
  section: string;
  id?: string[];
}): Promise<types.ListResponse> {
  const response = await Api.get<types.ListResponseData>(
    ["api", "v2", "items"],
    {
      indexKey,
      num_results_per_page: numResultsPerPage,
      page,
      section,
      id,
    }
  );

  return types.ListResponse.parse(response);
}

list.key = "modules/itemsV2/list";

export async function create(
  params: {
    indexKey: string;
    section: string;
  },
  payload: types.CreateItemPayload
): Promise<types.CreateResponse> {
  const result = await Api.post<types.CreateResponseData>(
    ["api", "v2", "items"],
    params,
    types.CreateItemPayloadData.fromPayload(payload)
  );

  return types.CreateResponse.schema.parse(result);
}

export async function update(
  params: {
    indexKey: string;
    section: string;
  },
  payload: types.UpdateItemPayload
): Promise<types.UpdateResponse> {
  const result = await Api.patch<types.UpdateResponseData>(
    ["api", "v2", "items"],
    params,
    types.UpdateItemPayloadData.fromPayload(payload)
  );

  return types.UpdateResponse.schema.parse(result);
}

export async function destroy({
  id,
  indexKey,
  section,
}: {
  id: string;
  indexKey: string;
  section: string;
}): Promise<void> {
  await Api.delete<types.ListResponseData>(["api", "v2", "items"], {
    indexKey,
    id,
    section,
  });
}
