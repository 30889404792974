import React from "react";
import { useTheme, Box } from "@chakra-ui/react";
import { css } from "@emotion/react";
import classNames from "classnames";

import useCurrentUser from "app/hooks/useCurrentUser";
// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import Button from "components/Modules/Button";
import createPersistedState from "vendor/use-persisted-state";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Default from "./Default";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { Content, Text, HintGlobalStyles } from "./UI";

import "tippy.js/animations/scale-subtle.css";

const HINT_PREFIX = "VISITED_HINTS";
const useHintState = createPersistedState<
  Record<
    // State for hints is stored under a given user id, so if multiple users
    // login using the same browser their states won't be shared.
    string,
    {
      single: Record<string, boolean>;
      grouped: Record<string, Record<string, boolean>>;
    }
  >
>(HINT_PREFIX);

/**
 * Used to control the state of `Hint` components manually. On their own hints
 * will show up once and then not again after being dismissed. This hook offers
 * ways to check if a given hint has already been seen and to reset it. It also
 * allows resetting a whole group at once.
 */
export function useHint() {
  const { id: userId } = useCurrentUser();
  const [hintState, setHintState] = useHintState();

  return {
    getHintState: (key: string, group?: string): boolean => {
      if (group === undefined) {
        return hintState?.[userId]?.single?.[key] ?? false;
      }

      return hintState?.[userId]?.grouped?.[group]?.[key] ?? false;
    },
    setHintState: (key: string, value: boolean, group?: string) => {
      setHintState((prevState) => {
        const newState = { ...prevState };
        if (!newState[userId]) {
          newState[userId] = { single: {}, grouped: {} };
        }

        // eslint-disable-next-line no-negated-condition
        if (group !== undefined) {
          newState[userId] = {
            ...newState[userId],
            grouped: {
              ...newState[userId].grouped,
              [group]: {
                ...newState[userId].grouped[group],
                [key]: value,
              },
            },
          };
        } else {
          newState[userId] = {
            ...newState[userId],
            single: {
              ...newState[userId].single,
              [key]: value,
            },
          };
        }

        return newState;
      });
    },
    resetHintGroup: (group: string) => {
      setHintState((prevState) => {
        const newState = { ...prevState };
        if (!newState[userId]) {
          return newState;
        }
        newState[userId].grouped[group] = {};
        return newState;
      });
    },
  };
}

/**
 * TODO: deprecated Use the design system `Popover Educational` component instead.
 */
export const Hint = ({
  children,
  name,
  group,
  title,
  description,
  cta = "Got it",
  isDisabled = false,
  arrowPlacement = "right",
  className,
  confirmOnRefClick = true,
  ...props
}: Props) => {
  const theme = useTheme();

  const { getHintState, setHintState } = useHint();
  const isVisible = !getHintState(name, group);

  const onConfirm = () => {
    setHintState(name, true, group);
  };

  return (
    <Default
      followCursor={false}
      animation="scale-subtle"
      containerStyle={css`
        overflow: unset;
        box-shadow: unset;
        background-color: inherit;
      `}
      interactive
      disabled={isDisabled}
      placement={
        props.placement
          ? props.placement
          : arrowPlacement === "left"
          ? "bottom-start"
          : "bottom-end"
      }
      duration={[350, 350]}
      css={css`
        background-color: ${theme.colors["grey-dark"]};
        margin-top: 10px;
      `}
      className={classNames("hint-tooltip", className)}
      tooltip={
        <Content
          css={css`
            text-transform: none;
            letter-spacing: normal;
            font-weight: normal;
            width: auto;
            background-color: ${theme.colors["grey-dark"]};
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 6px;
          `}
        >
          <HintGlobalStyles />
          <Text
            css={css`
              color: ${theme.colors.white};
              font-weight: bold;
              font-size: 14px;
            `}
          >
            {title}
          </Text>
          <Text
            css={css`
              color: ${theme.colors.white};
              font-size: 14px;
            `}
          >
            {description}
          </Text>
          <Box mt="15px">
            <Button
              kind="info"
              size="small"
              onClick={onConfirm}
              css={css`
                padding: 8px 16px;
              `}
            >
              {cta}
            </Button>
          </Box>
        </Content>
      }
      visible={isVisible}
      arrow
      {...props}
    >
      {typeof children === "function" ? (
        children({ hideHint: onConfirm })
      ) : (
        <Box
          onClick={confirmOnRefClick ? () => onConfirm() : () => {}}
          role="button"
        >
          {children}
        </Box>
      )}
    </Default>
  );
};

// The type of `children` that the tooltip can work with is not exactly
// `React.ReactNode` so we extract it here.
type TooltipChildren = React.ComponentProps<typeof Default>["children"];

type Props = {
  /**
   * Unique identifier for the hint. Used to persist its state and know if the
   * user has dismissed it already.
   */
  name: string;
  /**
   * Optional identifier to group several hints together. Useful in conjunction
   * with `useHint` to reset a whole group at once.
   */
  group?: string;
  /**
   * Title for the hint, shown in the UI.
   */
  title?: React.ReactNode;
  /**
   * Description for the hint, shown in the UI.
   */
  description: React.ReactNode;
  /**
   * Custom text for the button that will dismiss the hint and mark it as
   * viewed.
   */
  cta?: string;
  /**
   * If `true`, the hint won't show up regardless of whether it's been seen
   * before or not.
   */
  isDisabled?: boolean;
  arrowPlacement?: "left" | "right";
  /**
   * This is `true` by default. If set to `false`, the onConfirm method won't be invoked on
   * clicking the element referenced by the tooltip
   */
  confirmOnRefClick?: boolean;
  /**
   * Children can be provided in two ways. Just passing a React element will
   * wrap the contents in a div in order to detect clicks and know when to mark
   * the hint as viewed.
   * If more flexibility is needed (e.g. the children have absolute positioning
   * and the wrapper does not match them exactly) a function children prop can
   * be used. The function will be given a `hideHint` closure and it will be
   * the consumer's responsibility to call it when the hint should be marked as
   * viewed.
   */
  children:
    | TooltipChildren
    | ((params: { hideHint: () => void }) => TooltipChildren);
} & Omit<React.ComponentProps<typeof Default>, "children" | "tooltip">;
