import React, { lazy } from "react";

import { IntegrationMenu as Menu } from "app/components/Navigation";

const Performance = lazy(() => import("components/Integration/Performance"));

const FtpCredentials = lazy(
  () => import("components/Integration/FtpCredentials")
);

const CatalogUploads = lazy(
  () => import("components/Integration/CatalogUploads")
);

const Connections = lazy(() => import("components/Integration/Connections"));

const ApiTokens = lazy(() => import("./integration/api_tokens"));

import BaseRoute from "./shared/Route";
import Loading from "./shared/Loading";

function Route(props: Omit<React.ComponentProps<typeof BaseRoute>, "loading">) {
  return <BaseRoute {...props} loading={<Loading menu={<Menu />} />} />;
}

export default function integrationRoutes() {
  return (
    <>
      <Route
        exact
        path="/dashboard/integration/performance"
        component={Performance}
      />

      <Route
        exact
        path="/dashboard/integration/ftp_credentials"
        component={FtpCredentials}
      />

      <Route
        exact
        path="/dashboard/integration/api_tokens"
        component={ApiTokens}
      />

      <Route
        exact
        path="/dashboard/integration/catalog_uploads/:id?"
        component={CatalogUploads}
      />

      <Route
        exact
        path="/dashboard/integration/connections"
        component={Connections}
      />
    </>
  );
}
