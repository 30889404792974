import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { css, keyframes } from "@emotion/react";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme from "components/theme";
// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import Icon from "components/Modules/Icon";
import colors from "app/designSystem/theme/foundations/colors";
import { isFlagEnabled } from "utils/featureFlags";
import { FeatureFlags } from "utils/generatedFeatures";

export type Kind =
  | "default"
  | "primary"
  | "info"
  | "secondary"
  | "link"
  | "body-link"
  | "danger"
  | "action-success"
  | "action-danger"
  | "text"
  | "block"
  | "action-success-new"
  | "action";

const isDesignSystemButtonV2 = isFlagEnabled(FeatureFlags.DesignSystemButtonV2);

export const sizes = {
  small: css`
    padding: ${isDesignSystemButtonV2 ? "6px 8px" : "8px 30px"};
    font-size: ${isDesignSystemButtonV2 ? "13px" : "12px"};
  `,
  medium: css`
    height: 46px;
    padding: ${isDesignSystemButtonV2 ? "10px 16px" : "0px 30px"};
    font-size: ${isDesignSystemButtonV2 ? "16px" : "12px"};
  `,
  default: css`
    padding: ${isDesignSystemButtonV2 ? "10px 16px" : "15.5px 30px"};
    font-size: ${isDesignSystemButtonV2 ? "16px" : "12px"};
  `,
};

const kind = css`
  box-shadow: none;
  color: ${theme.colors.backgroundWhite};

  :focus:not(:disabled),
  :hover:not(:disabled) {
    color: ${theme.colors.backgroundWhite};
  }

  & ${Icon} {
    margin: -8px 8px -8px -16px;
  }

  :disabled {
    background-color: ${isDesignSystemButtonV2
      ? colors.gray[50]
      : theme.colors.backgroundPrimaryDisabled};
    color: ${isDesignSystemButtonV2
      ? colors.gray[400]
      : theme.colors.textGrayDarkDisabled};
    ${isDesignSystemButtonV2 && "border-color: transparent;"}
  }
`;

const link = css`
  ${kind};
  padding: 0;
  box-shadow: none;
  letter-spacing: normal;
  text-transform: none;
  color: ${isDesignSystemButtonV2
    ? colors.blue[700]
    : theme.colors.headerBlack};
  background-color: transparent;
  border-radius: 0;
  vertical-align: initial;

  :hover:not(:disabled) {
    text-decoration: underline;
  }

  :focus:not(:disabled) {
    background: ${isDesignSystemButtonV2
      ? colors.white
      : theme.colors.neutralGreenishgrey};
  }

  :hover:not(:disabled),
  :focus:not(:disabled) {
    outline: none;
    color: ${isDesignSystemButtonV2
      ? colors.blue[700]
      : theme.colors.headerBlack};
  }

  :disabled {
    color: ${theme.colors.neutralDisabledGrey};
    background-color: transparent;
  }
`;

const action = css`
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: ${theme.colors.backgroundWhite};

  :disabled {
    cursor: initial;
  }
`;

const text = css`
  background: transparent;
  width: auto;
  margin: 0;
  padding: 0;
  display: inline;
  color: inherit;
  border: none;
  text-transform: none;
  box-shadow: none;
  font-weight: normal;
  letter-spacing: normal;
  text-align: left;
  border-radius: 0;
  font-size: inherit;

  :focus-visible {
    background-color: var(--neutral-greenishligthgrey);
    outline: none;
  }
`;

export const kinds = {
  default: css``,
  primary: css`
    ${kind};
    background-color: ${isDesignSystemButtonV2
      ? colors.gray["900"]
      : theme.colors.backgroundPrimary};
    box-shadow: ${isDesignSystemButtonV2
      ? "0px 2px 2px 0px #0F0F170D, 0px 2px 6px 0px #0F0F171C"
      : "none"};

    :hover:not(:disabled) {
      background-color: ${isDesignSystemButtonV2
        ? colors.gray["800"]
        : theme.colors.primaryDarkGreen};
      box-shadow: ${isDesignSystemButtonV2
        ? "0px 2px 3px 0px #0F0F1714, 0px 1px 5px 0px #0F0F171A, 0px -1px 6px 0px #0F0F1708"
        : "none"};
    }

    :focus:not(:disabled) {
      outline: none;
      background-color: ${isDesignSystemButtonV2
        ? colors.gray["800"]
        : theme.colors.primaryDarkGreen};
      box-shadow: ${isDesignSystemButtonV2
        ? "0px 2px 2px 0px #0F0F170D, 0px 2px 6px 0px #0F0F171A, 0px 0px 0px 2px #0F0F1733"
        : `inset 0 0 0 2px ${theme.colors.darkHighlight}`};
    }

    :disabled {
      box-shadow: none;
      background-color: ${colors.gray["50"]};
      border: 2px solid
        ${isDesignSystemButtonV2 ? "transparent" : colors.gray["100"]};
    }
  `,
  info: css`
    ${kind};
    background-color: ${isDesignSystemButtonV2
      ? colors.white
      : theme.colors.backgroundInfo};
    color: ${isDesignSystemButtonV2
      ? colors.gray[1000]
      : theme.colors.backgroundWhite};
    border: 1px solid
      ${isDesignSystemButtonV2 ? colors.gray["300"] : "transparent"};
    box-shadow: ${isDesignSystemButtonV2
      ? "0px 2px 2px 0px #0F0F170D, 1px 2px 6px 0px #0F0F170A"
      : "none"};

    :hover:not(:disabled) {
      color: ${isDesignSystemButtonV2
        ? colors.gray[1000]
        : theme.colors.textGray};
      background-color: ${isDesignSystemButtonV2
        ? colors.white
        : theme.colors.secondaryBlueDark};
      border: 1px solid
        ${isDesignSystemButtonV2 ? colors.gray["600"] : "transparent"};
      box-shadow: ${isDesignSystemButtonV2
        ? "0px 2px 2px 0px #0F0F170D, 0px 1px 4px 0px #0F0F1714, 0px -1px 6px 0px #0F0F1708"
        : "none"};
    }

    :focus:not(:disabled) {
      outline: none;
      background-color: ${theme.colors.secondaryBlueDark};
      box-shadow: ${isDesignSystemButtonV2
        ? "0px 2px 2px 0px #0F0F170D, 0px 1px 4px 0px #0F0F171A, 0px 0px 0px 2px #0F0F1733"
        : `inset 0 0 0 2px ${theme.colors.backgroundInfo}`};
      color: ${theme.colors.backgroundWhite};
    }

    :disabled {
      box-shadow: none;
    }
  `,
  secondary: css`
    ${kind};
    color: ${isDesignSystemButtonV2
      ? colors.gray[1000]
      : theme.colors.textGray};
    background-color: ${theme.colors.backgroundWhite};
    box-shadow: ${isDesignSystemButtonV2
      ? "0px 2px 2px 0px #0F0F170D, 1px 2px 6px 0px #0F0F170A"
      : `inset 0 0 0 2px ${theme.colors.borderGrayLight}`};
    border: 1px solid
      ${isDesignSystemButtonV2 ? colors.gray["300"] : "transparent"};

    :focus:not(:disabled),
    :hover:not(:disabled) {
      color: ${isDesignSystemButtonV2
        ? colors.gray[1000]
        : theme.colors.textGray};
      background-color: ${isDesignSystemButtonV2
        ? colors.white
        : theme.colors.borderGrayLight};
      border: 1px solid
        ${isDesignSystemButtonV2 ? colors.gray["600"] : "transparent"};
      box-shadow: ${isDesignSystemButtonV2
        ? "0px 2px 2px 0px #0F0F170D, 0px 1px 4px 0px #0F0F1714, 0px -1px 6px 0px #0F0F1708"
        : "none"};
    }

    :focus:not(:disabled) {
      outline: none;
      box-shadow: ${isDesignSystemButtonV2
        ? "0px 2px 2px 0px #0F0F170D, 0px 1px 4px 0px #0F0F171A, 0px 0px 0px 2px #0F0F1733"
        : "inset 0 0 0 2px inherit"};
    }

    :disabled {
      box-shadow: none;
    }
  `,
  link: link,
  ["body-link"]: css`
    ${link};
    color: ${theme.colors.darkHighlight};

    :hover {
      color: ${theme.colors.darkHighlight};
    }
  `,
  danger: css`
    ${kind};
    background-color: ${isDesignSystemButtonV2
      ? colors.red[700]
      : theme.colors.danger};
    box-shadow: ${isDesignSystemButtonV2
      ? "0px 2px 2px 0px #0F0F170D, 0px 2px 6px 0px #0F0F171C"
      : "none"};

    :hover:not(:disabled) {
      background-color: ${isDesignSystemButtonV2
        ? colors.red[600]
        : theme.colors.dangerBackgroundDark};
      box-shadow: ${isDesignSystemButtonV2
        ? "0px 2px 3px 0px #0F0F1714, 0px 1px 5px 0px #0F0F171A, 0px -1px 6px 0px #0F0F1708"
        : "none"};
    }

    :focus:not(:disabled) {
      outline: none;
      background-color: ${isDesignSystemButtonV2
        ? colors.red[800]
        : theme.colors.dangerBackgroundDark};
      box-shadow: ${isDesignSystemButtonV2
        ? "0px 2px 2px 0px #0F0F170D, 0px 2px 6px 0px #0F0F171A, 0px 0px 0px 2px #0F0F1733"
        : `inset 0 0 0 2px ${theme.colors.neutralDisabledGrey}`};
    }

    :disabled {
      box-shadow: none;
    }
  `,
  ["action"]: css`
    ${action};
  `,
  ["action-success"]: css`
    ${action};

    :hover:not(:disabled),
    :focus:not(:disabled) {
      box-shadow: inset 0 0 0 2px ${theme.colors.backgroundGrayHover};
      background: ${theme.colors.backgroundGrayHover};
      color: ${theme.colors.white};
      outline: none;
    }

    :focus:not(:disabled) {
      box-shadow: inset 0 0 0 2px ${theme.colors.backgroundGrayHoverDark};
    }

    :disabled {
      background: ${theme.colors.disabled};
      box-shadow: inset 0 0 0 2px ${theme.colors.disabled};
    }
  `,
  ["action-danger"]: css`
    ${action};

    :hover:not(:disabled),
    :focus:not(:disabled) {
      box-shadow: inset 0 0 0 2px ${theme.colors.danger};
      background: ${theme.colors.danger};
      color: ${theme.colors.white};
      outline: none;
    }

    :focus:not(:disabled) {
      box-shadow: inset 0 0 0 2px ${theme.colors.dangerDark};
    }

    :disabled {
      background-color: ${colors.gray["50"]};
      border-color: ${colors.gray["100"]};
    }
  `,
  ["action-success-new"]: css`
    ${action};

    :hover:not(:disabled),
    :focus:not(:disabled) {
      box-shadow: inset 0 0 0 2px ${theme.colors.backgroundGrayHover};
      background: ${theme.colors.backgroundGrayHover};
      color: ${theme.colors.white};
      outline: none;
    }

    :focus:not(:disabled) {
      box-shadow: inset 0 0 0 2px ${theme.colors.backgroundGrayHoverDark};
    }

    :disabled {
      color: rgba(80, 92, 117);
      opacity: 0.38;
      box-shadow: inset 0 0 0 2px ${theme.colors.disabled};
    }
  `,
  text: text,
  block: css`
    ${text};
    display: block;
    width: 100%;
  `,
};

export const Content = styled("span", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "loading",
})<{ loading: boolean }>`
  opacity: ${({ loading }) => (loading ? "0" : "1")};
  display: flex;
  align-items: center;
`;

const SpinnerAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerElement = styled.div`
  background-size: 16px;
  background-image: url(${require(`../Images/${
    isDesignSystemButtonV2 ? "spinner-dark" : "spinner"
  }.svg`)});
  width: 16px;
  height: 16px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
  animation: ${SpinnerAnimation} 2s linear infinite;
`;

export const ButtonElement = styled.button<{
  size: keyof typeof sizes;
  kind: keyof typeof kinds;
}>`
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: none;
  border-radius: ${isDesignSystemButtonV2 ? "10px" : "9999px"};
  font-family: ${theme.fonts.text};
  color: ${isDesignSystemButtonV2 ? colors.white : theme.colors.textGrayDark};
  text-transform: ${isDesignSystemButtonV2 ? "none" : "uppercase"};

  // box-shadow instead of border to make it an "inset" border and have the
  // same size as buttons without a border
  box-shadow: inset 0 0 0 2px ${theme.colors.borderGrayLight};
  letter-spacing: ${isDesignSystemButtonV2 ? "normal" : "1px"};
  position: relative;
  font-weight: ${isDesignSystemButtonV2 ? 500 : "bold"};
  vertical-align: middle;

  :hover {
    text-decoration: none;
    cursor: pointer;
  }

  :hover:disabled {
    cursor: not-allowed;
  }

  ${({ size }) => sizes[size]}
  ${({ kind }) => kinds[kind]}

  button + &, a + &, span.wrapper & {
    margin-left: 16px;
  }
`;
