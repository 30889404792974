import React, { useRef, useEffect, useCallback } from "react";
import toast, { Toast as HotToast } from "react-hot-toast";

import Toast from "app/designSystem/components/Toast/Toast";

/**
 * Returns utility functions to show different toasts.
 */
export default function useToast(): UseToastReturnValue {
  const idsToDismissRef = useRef<string[]>([]);

  /**
   * Removes a toast
   */
  const removeToasts = useCallback(() => {
    idsToDismissRef.current.forEach((id) => {
      toast.dismiss(id);
    });
  }, [idsToDismissRef]);

  /**
   * Wraps a success or error toast within constructor's
   * toast component
   */
  const addGenericToast = useCallback(
    ({ variant, message, currentToast }: AddGenericToastParams) => {
      return GenericToastWrapper({
        message,
        variant,
        id: currentToast.id,
        closeToast: () => toast.dismiss(currentToast.id),
      });
    },
    []
  );

  /**
   * Handles custom toast
   */
  const addCustomToast = useCallback(
    ({ customToast, currentToast }: AddCustomToastParams) => {
      /**
       * Sometimes the actions to handle a toast is embedded inside
       * the custom html provided. In such cases we need to pass
       * the required actions like `dismissing a toast` as props. For
       * example: app/javascript/components/NewVersionNotification.tsx
       */
      if (typeof customToast === "function") {
        return customToast({
          id: currentToast.id,
          closeToast: () => toast.dismiss(currentToast.id),
        });
      }
      return customToast;
    },
    []
  );

  /**
   * Callback that adds the toast to the UI
   */
  const addToast = useCallback(
    ({
      variant,
      customToast,
      message,
      autoDismiss = true,
      dismissOnUnmount = true,
      customToastId,
    }: AddToastOptions) => {
      const id = toast(
        (currentToast) => {
          if (customToast) {
            return addCustomToast({ customToast, currentToast });
          }

          if (message) {
            return addGenericToast({
              variant,
              message,
              currentToast,
            });
          }

          throw new Error("There is no content for Toast");
        },
        {
          ...(customToastId && { id: customToastId }),
          duration: autoDismiss ? 3000 : Infinity,
        }
      );

      if (dismissOnUnmount) {
        idsToDismissRef.current.push(id);
      }
    },
    [addCustomToast, addGenericToast]
  );

  const generic = useCallback(
    (variant: React.ComponentProps<typeof Toast>["variant"]) => {
      return (message: string, options?: ToastOptions) => {
        return addToast({
          variant,
          autoDismiss: options?.autoDismiss,
          dismissOnUnmount: options?.dismissOnUnmount,
          message,
        });
      };
    },
    [addToast]
  );

  const custom = useCallback(
    (customToast: CustomToast, options?: ToastOptions) => {
      addToast({
        autoDismiss: options?.autoDismiss,
        dismissOnUnmount: options?.dismissOnUnmount,
        customToast,
        customToastId: options?.customToastId,
      });
    },
    [addToast]
  );

  useEffect(() => {
    return () => {
      removeToasts();
    };
  }, [removeToasts]);

  return {
    success: generic("success"),
    failure: generic("error"),
    info: generic("info"),
    custom,
  };
}

type UseToastReturnValue = {
  /** Renders a success toast */
  success: (message: string, options?: ToastOptions) => void;
  /** Renders a failure toast */
  failure: (message: string, options?: ToastOptions) => void;
  /** Renders an informational toast */
  info: (message: string, options?: ToastOptions) => void;
  /**
   * Renders a custom toast. It can be provided either as an already rendered
   * element, or as a function that will be called in order to render the
   * element.
   */
  custom: (customToast: CustomToast, options?: ToastOptions) => void;
};

type AddToastOptions = {
  /**
   * Message for the toast. Either this or `customToast` must be provided.
   */
  message?: string;
  /**
   * An entire custom toast, replacing all predefined styles. Either this or
   * `message` must be provided.
   */
  customToast?: CustomToast;
  /**
   * Variant for the toast UI.
   */
  variant?: React.ComponentProps<typeof Toast>["variant"];
  /**
   * Whether to automatically dismiss the toast after some time (true by default).
   */
  autoDismiss?: boolean;
  /**
   * Whether to dismiss the toast when the component unmounts (true by default).
   * When false, the toast would still be present even when navigating
   * to another page and would be dismissed by timeout.
   */
  dismissOnUnmount?: boolean;
  /**
   * Custom id to use for the toast. Useful to prevent duplication - if you try
   * to show a toast with the same id multiple times it will be shown only once.
   */
  customToastId?: string;
};

type ToastOptions = Pick<
  AddToastOptions,
  "autoDismiss" | "dismissOnUnmount" | "customToastId"
>;

/**
 * This is the type that react-hot-toast considers "renderable". It is not
 * exported so we need to copy it.
 */
type Rendarable = React.JSX.Element | string | null;

type CustomToastParams = {
  /**
   * Id generated for this toast.
   */
  id: string;
  /**
   * Function to call in order to close the custom toast.
   */
  closeToast: () => void;
};

type CustomToast =
  | Rendarable
  | ((customToastParams: CustomToastParams) => Rendarable);

type AddGenericToastParams = Pick<AddToastOptions, "variant"> & {
  message: string;
  currentToast: HotToast;
};

type AddCustomToastParams = {
  customToast: CustomToast;
  currentToast: HotToast;
};

const GenericToastWrapper = ({
  message,
  variant,
}: Pick<AddToastOptions, "variant"> & {
  id: string;
  message: string;
  closeToast: () => void;
}) => {
  return <Toast variant={variant}>{message}</Toast>;
};
