import {
  Box,
  Button as ChakraButton,
  Flex,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import React, { FC, useState } from "react";

import { Button, ChakraIcon } from "app/designSystem/components";
// TODO: Replace this import with date-fns
// eslint-disable-next-line no-restricted-imports
import dayjs from "utils/dayjs";

const MaintainanceBanner: FC<Props> = ({
  onClose,
  startTime,
  endTime,
  isActive,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const durationHours = dayjs(endTime).diff(startTime, "hours");

  const content = isActive ? (
    <>
      👋 <b>Attention:</b> The dashboard will undergo scheduled maintenance on{" "}
      <b>{dayjs(startTime).format("MMMM D at mm A")} (your time)</b> for up to{" "}
      {durationHours} hour, it will not be available during this time. This
      maintenance will help us fix bugs and improve app performance.{" "}
    </>
  ) : (
    <>
      👋 <b>Attention:</b> The dashboard will undergo scheduled maintenance on{" "}
      <b>{dayjs(startTime).format("MMMM D, h:mm A")} (your time)</b> for up to{" "}
      {durationHours} hour, it will not be available during this time. This
      maintenance will help us fix bugs and improve app performance.{" "}
    </>
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Flex
      bgColor="yellow-very-light"
      width="100%"
      padding="14px 32px"
      alignItems="center"
      justifyContent="space-between"
      role="banner"
    >
      <Text as="span" textStyle="body2" color="black">
        {content}
        <Popover
          trigger="hover"
          placement="bottom-end"
          arrowShadowColor="transparent"
        >
          <PopoverTrigger>
            <ChakraButton
              display="inline-block"
              fontWeight="normal"
              boxSize="auto"
              role="button"
              textDecoration="underline"
              variant="unstyled"
              verticalAlign="baseline"
              borderWidth={0}
            >
              See details here
            </ChakraButton>
          </PopoverTrigger>
          <PopoverContent shadow="md">
            <PopoverArrow bgColor="grey-dark" />
            <Box
              position="relative"
              bgColor="grey-dark"
              color="white"
              padding="16px"
              textAlign="left"
              role="tooltip"
              borderRadius="md"
            >
              <Text
                as="span"
                display="inline-block"
                marginBottom="30px"
                textStyle="body2"
              >
                During maintenance, you will not be able to access the dashboard
                for up to {durationHours} hour.
              </Text>
              <Text
                as="span"
                display="inline-block"
                marginBottom="30px"
                textStyle="body2"
              >
                We apologize for any inconvenience and appreciate your patience.
                This update will help us fix bugs and make the app faster and
                more reliable. If you have any questions, please contact your
                Customer Success Manager. <br /> Thank you for your
                understanding!
              </Text>
              <PopoverCloseButton
                as="span"
                display="inline"
                position="relative"
                width="auto"
                height="auto"
                background="transparent"
                outline="none"
                border="none"
                margin="0"
                padding="0"
              >
                <Button variant="secondary">Ok, Thanks</Button>
              </PopoverCloseButton>
            </Box>
          </PopoverContent>
        </Popover>
        .
      </Text>
      <ChakraButton
        display="inline-flex"
        h="auto"
        onClick={() => {
          onClose?.();
          setIsVisible(false);
        }}
        title="Close warning"
        type="button"
        variant="unstyled"
      >
        <ChakraIcon
          boxSize="16px"
          icon="close-24"
          color="grey-dark"
          cursor="pointer"
        />
      </ChakraButton>
    </Flex>
  );
};

type Props = {
  onClose?: () => void;
  startTime: Date;
  endTime: Date;
  isActive?: boolean;
};

export default MaintainanceBanner;
