import styled from "@emotion/styled";

// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme, { styles } from "components/theme";
// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import * as Typography from "components/Modules/Typography";

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 16px;
`;

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const TFoot = styled.tfoot``;

export const Tr = styled.tr`
  ${TBody} & {
    ${styles.shadows.light};
    border-radius: 4px;
  }
`;

export const Th = styled(Typography.TextSubheadlinesSecondary)`
  text-align: left;
`.withComponent("th");

export const NumberHeader = styled(Th)`
  text-align: right;
`;

export const Td = styled.td`
  background: white;
  padding: 16px 0px;
  color: ${theme.colors.textGrayBlue};
  font-size: 14px;

  :first-of-type {
    padding-left: 32px;
    padding-right: 8px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  :last-of-type {
    padding-left: 8px;
    padding-right: 32px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const Text = styled(Td)`
  max-width: 256px;
`;

export const Index = styled(Td)`
  border-bottom: 1px solid transparent;
  text-transform: uppercase;
  color: ${theme.colors.headerGray};
  font-size: 12px;
  margin-top: 2px;
  width: 32px;
  word-break: normal;
`;

export const Number = styled(Td)`
  text-align: right;
  vertical-align: middle;
  white-space: nowrap;
  width: 1px;
`;

export const Actions = styled(Td)`
  vertical-align: middle;
  white-space: nowrap;
  width: 1px;
  padding-left: 32px;
`;
