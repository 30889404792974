import React from "react";
import {
  Slider as ChakraSlider,
  SliderProps as ChakraSliderProps,
  SliderThumb as ChakraSliderThumb,
  SliderFilledTrack as ChakraSliderFilledTrack,
  SliderTrack as ChakraSliderTrack,
} from "@chakra-ui/react";

import { Tooltip } from "app/designSystem/components/Tooltip";

export const Slider = ({
  defaultValue,
  "aria-label": ariaLabel,
  value,
  onChange,
  onChangeStart,
  onChangeEnd,
  focusThumbOnChange = false,
  step,
  tooltipLabel,
  isDisabled,
  isReadOnly,
  name,
  dragOnly,
  min,
  max,
}: Props) => {
  return (
    <ChakraSlider
      aria-label={ariaLabel}
      value={value}
      onChange={onChange}
      onChangeStart={onChangeStart}
      onChangeEnd={onChangeEnd}
      focusThumbOnChange={focusThumbOnChange}
      step={step}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      name={name}
      pointerEvents={dragOnly ? "none" : "auto"}
      min={min}
      max={max}
    >
      <ChakraSliderTrack
        height="6px"
        borderRadius="full"
        backgroundColor="grey-light-blue"
      >
        <ChakraSliderFilledTrack backgroundColor="old-blue" />
      </ChakraSliderTrack>

      <Tooltip
        label={tooltipLabel}
        placement="top"
        isDisabled={tooltipLabel === undefined}
      >
        <ChakraSliderThumb
          shadow="none"
          backgroundColor="old-blue"
          height="16px"
          width="16px"
          border="none"
          outline="none"
          transform="translateY(-50%)"
          _hover={{
            transform: "translateY(-50%) scale(1.25)",
            cursor: "pointer",
          }}
          _active={{ outline: "none", cursor: "grabbing" }}
          _focus={{
            outline: "none",
          }}
          pointerEvents="auto"
        />
      </Tooltip>
    </ChakraSlider>
  );
};

type Props = Pick<
  ChakraSliderProps,
  | "aria-label"
  | "value"
  | "focusThumbOnChange"
  | "onChange"
  | "step"
  | "defaultValue"
  | "onChangeStart"
  | "onChangeEnd"
  | "isDisabled"
  | "isReadOnly"
  | "name"
  | "min"
  | "max"
> & {
  tooltipLabel?: string;
  // disables clicking on the slider track, only dragging the thumb is allowed
  // useful for when we want to force the user to go through the slider by dragging the thumb
  dragOnly?: boolean;
};
