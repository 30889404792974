import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

const isDesignSystemButtonV2 =
  (typeof window !== "undefined" &&
    isFlagEnabled?.(FeatureFlags.DesignSystemButtonV2)) ??
  false;

const variants = {
  primary: {
    bg: isDesignSystemButtonV2 ? "gray.900" : "old-green",
    color: "white",
    letterSpacing: isDesignSystemButtonV2 ? "normal" : "1px",
    boxShadow: isDesignSystemButtonV2
      ? "0px 2px 2px 0px #0F0F170D, 0px 2px 6px 0px #0F0F171C"
      : "none",
    _hover: {
      bg: isDesignSystemButtonV2 ? "gray.800" : "green-dark",
      color: "white",
      boxShadow: isDesignSystemButtonV2
        ? "0px 2px 3px 0px #0F0F1714, 0px 1px 5px 0px #0F0F171A, 0px -1px 6px 0px #0F0F1708"
        : "none",
    },
    _active: {
      bg: isDesignSystemButtonV2 ? "gray.1000" : "green-dark",
      borderColor: isDesignSystemButtonV2 ? "transparent" : "success",
      boxShadow: isDesignSystemButtonV2
        ? "0px 1px 1px 0px #0F0F170D, 0px 1px 2px 0px #0F0F171A"
        : "none",
    },
    _focus: {
      bg: isDesignSystemButtonV2 ? "gray.800" : "green-dark",
      borderColor: isDesignSystemButtonV2 ? "transparent" : "success",
      boxShadow: isDesignSystemButtonV2
        ? "0px 2px 2px 0px #0F0F170D, 0px 2px 6px 0px #0F0F171A, 0px 0px 0px 2px #0F0F1733"
        : "none",
    },
    svg: {
      color: isDesignSystemButtonV2 ? "white" : "green-dark",
    },
  },
  ghost: {
    bg: "white",
    color: isDesignSystemButtonV2 ? "gray.800" : "grey-dark",
    borderColor: isDesignSystemButtonV2 ? "transparent" : "grey-light-blue",
    letterSpacing: isDesignSystemButtonV2 ? "normal" : "1px",
    _hover: {
      bg: isDesignSystemButtonV2 ? "gray.50" : "grey-light-blue",
      color: "grey-dark",
    },
    _active: {
      bg: "grey-light-blue",
      borderColor: isDesignSystemButtonV2 ? "grey.25" : "grey-dark",
    },
    _focus: {
      bg: isDesignSystemButtonV2 ? "grey.200" : "grey-light-blue",
      borderColor: isDesignSystemButtonV2 ? "transparent" : "grey-dark",
      boxShadow: isDesignSystemButtonV2 ? "0px 0px 0px 2px #0F0F1733" : "none",
    },
    svg: {
      me: "1px",
    },
  },
  danger: {
    bg: isDesignSystemButtonV2 ? "red.700" : "danger",
    letterSpacing: isDesignSystemButtonV2 ? "normal" : "1px",
    boxShadow: isDesignSystemButtonV2
      ? "0px 2px 2px 0px #0F0F170D, 0px 2px 6px 0px #0F0F171C"
      : "none",
    color: "white",
    _hover: {
      bg: isDesignSystemButtonV2 ? "red.600" : "danger-dark",
      color: "white",
      boxShadow: isDesignSystemButtonV2
        ? "0px 2px 3px 0px #0F0F1714, 0px 1px 5px 0px #0F0F171A, 0px -1px 6px 0px #0F0F1708"
        : "none",
    },
    _active: {
      bg: "danger-dark",
      borderColor: "danger-dark-2",
      boxShadow: isDesignSystemButtonV2
        ? "0px 1px 1px 0px #0F0F170D, 0px 1px 2px 0px #0F0F171A"
        : "none",
    },
    _focus: {
      bg: isDesignSystemButtonV2 ? "red.800" : "danger-dark",
      borderColor: "danger-dark-2",
      boxShadow: isDesignSystemButtonV2
        ? "0px 2px 2px 0px #0F0F170D, 0px 2px 6px 0px #0F0F171A, 0px 0px 0px 2px #0F0F1733"
        : "none",
    },
  },
  secondary: {
    letterSpacing: isDesignSystemButtonV2 ? "normal" : "1px",
    bg: isDesignSystemButtonV2 ? "white" : "old-blue",
    color: isDesignSystemButtonV2 ? "gray.1000" : "white",
    borderColor: isDesignSystemButtonV2 ? "gray.300" : "transparent",
    boxShadow: isDesignSystemButtonV2
      ? "0px 2px 2px 0px #0F0F170D, 1px 2px 6px 0px #0F0F170A"
      : "none",
    _hover: {
      bg: isDesignSystemButtonV2 ? "gray.50" : "blue-dark",
      color: isDesignSystemButtonV2 ? "gray.1000" : "white",
      borderColor: isDesignSystemButtonV2 ? "gray.600" : "transparent",
      boxShadow: isDesignSystemButtonV2
        ? "0px 2px 2px 0px #0F0F170D, 0px 1px 4px 0px #0F0F1714, 0px -1px 6px 0px #0F0F1708"
        : "none",
    },
    _active: {
      bg: isDesignSystemButtonV2 ? "white" : "blue-dark",
      borderColor: isDesignSystemButtonV2 ? "gray.600" : "old-blue",
      color: isDesignSystemButtonV2 ? "gray.1000" : "white",
      boxShadow: isDesignSystemButtonV2
        ? "0px 1px 1px 0px #0F0F170D, 0px 1px 2px 0px #0F0F171A, 0px -0.5px 1px 0px #0F0F170F"
        : "none",
    },
    _focus: {
      bg: isDesignSystemButtonV2 ? "gray.200" : "blue-dark",
      borderColor: isDesignSystemButtonV2 ? "gray.600" : "old-blue",
      boxShadow: isDesignSystemButtonV2
        ? "0px 2px 2px 0px #0F0F170D, 0px 1px 4px 0px #0F0F171A, 0px 0px 0px 2px #0F0F1733"
        : "inset 0 0 0 2px ${theme.colors.backgroundInfo}",
    },
    _disabled: {
      borderColor: "transparent",
    },
    svg: {
      color: isDesignSystemButtonV2 ? "gray.1000" : "old-blue",
    },
  },
  outline: {
    letterSpacing: isDesignSystemButtonV2 ? "normal" : "1px",
    bg: "white",
    color: "gray.1000",
    borderColor: "gray.300",
    _hover: {
      bg: "gray.50",
      borderColor: "gray.600",
    },
    _active: {
      bg: "white",
      borderColor: "gray.600",
    },
    _focus: {
      bg: "gray.200",
      borderColor: "gray.600",
      boxShadow: "0px 0px 0px 2px #0F0F1733",
    },
    _disabled: {
      borderColor: "transparent",
    },
  },
  link: {
    fontFamily: "body",
    lineHeight: 5,
    fontWeight: "regular",
    color: isDesignSystemButtonV2 ? "blue.700" : "black",
    bg: "transparent",
    borderRadius: "none",
    pt: "0",
    pb: "0",
    h: "auto",
    minHeight: "auto",
    textTransform: "none",
    letterSpacing: "normal",
    _active: { bg: "transparent", textDecoration: "underline" },
    _focus: { bg: "transparent", textDecoration: "underline" },
    _disabled: {
      bg: "transparent",
      borderColor: "transparent",
      cursor: "not-allowed",
    },
    _hover: {
      color: isDesignSystemButtonV2 ? "blue.700" : "black",
      _disabled: {
        bg: "transparent",
        cursor: "not-allowed",
        borderColor: "transparent",
      },
    },
  },
};

const baseStyle = {
  borderRadius: isDesignSystemButtonV2 ? "10px" : "22px",
  height: "auto",
  width: "auto",
  color: "black",
  border: isDesignSystemButtonV2 ? "1px solid" : "2px solid",
  borderColor: "transparent",
  maxWidth: "100%",
  whiteSpace: "normal",
  cursor: "pointer",
  fontFamily: "body",
  lineHeight: 4,
  fontWeight: isDesignSystemButtonV2 ? "medium" : "bold",
  textTransform: isDesignSystemButtonV2 ? "none" : "uppercase",
  letterSpacing: "normal",
  _focus: {
    boxShadow: "none",
    outline: "none",
  },
  _active: {
    boxShadow: "none",
    outline: "none",
  },
  _disabled: {
    cursor: "not-allowed",
    opacity: isDesignSystemButtonV2 ? "1" : "0.4",
    bg: isDesignSystemButtonV2 ? "gray.25" : "grey-disabled",
    color: isDesignSystemButtonV2 ? "gray.500" : "grey",
    svg: {
      color: isDesignSystemButtonV2 ? "gray.400" : "grey",
    },
  },
  _hover: {
    _disabled: {
      cursor: "not-allowed",
      bg: isDesignSystemButtonV2 ? "gray.50" : "grey-disabled",
      color: isDesignSystemButtonV2 ? "gray.400" : "grey",
      borderColor: "transparent",
      boxShadow: "none",
      svg: {
        color: isDesignSystemButtonV2 ? "gray.400" : "grey",
      },
    },
  },
};

const sizes = {
  lg: {
    px: isDesignSystemButtonV2 ? "16px" : "26px",
    py: isDesignSystemButtonV2 ? "10px" : "12px",
    h: isDesignSystemButtonV2 ? "44px" : "auto",
    minH: "44px",
    fontSize: isDesignSystemButtonV2 ? "16px" : "12px",
  },
  md: {
    px: "12px",
    py: "8px",
    h: "36px",
    minH: "36px",
    fontSize: "14px",
  },
  sm: {
    px: isDesignSystemButtonV2 ? "8px" : "14px",
    py: "6px",
    h: isDesignSystemButtonV2 ? "28px" : "auto",
    minH: "32px",
    borderRadius: isDesignSystemButtonV2 ? "8px" : "22px",
    fontSize: isDesignSystemButtonV2 ? "13px" : "12px",
  },
};

export default {
  variants,
  baseStyle,
  sizes,
};
