import React from "react";
import {
  ModalHeader,
  VStack,
  HStack,
  Text,
  ModalHeaderProps,
  Box,
} from "@chakra-ui/react";

import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";
import { IconButton } from "app/designSystem/components/IconButton";

import { Events, useTracking } from "app/providers/TrackingProvider";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./Header.styles";

export default function Header({
  secondaryInfo,
  isCloseable,
  subtitle,
  title,
  onBack,
  modalHeaderSx = {},
  onClose,
}: Props) {
  const { trackEvent } = useTracking();

  const handleBack = () => {
    trackEvent(Events.ButtonClick, {
      // made-up name since target element has no title
      title: "Back",
    });

    onBack?.();
  };

  return (
    <ModalHeader {...{ ...styles.modalHeaderStyle, ...modalHeaderSx }}>
      <HStack {...styles.mainHorizontalStackStyle}>
        {onBack && (
          <ChakraIcon
            icon="arrow-left-24"
            data-testid="modal-back-arrow"
            onClick={handleBack}
            {...styles.backIconStyle}
          />
        )}

        <VStack {...styles.mainVerticalStackStyle}>
          <HStack justifyContent="space-between" alignItems="baseline" pt="8px">
            <Text {...styles.titleStyle} data-testid="modal-title">
              {title}
            </Text>

            {secondaryInfo && (
              <Text
                {...styles.secondaryInfoStyle}
                data-testid="modal-header-secondary-info"
              >
                {secondaryInfo}
              </Text>
            )}
          </HStack>

          {subtitle && <Text {...styles.subtitleStyle}>{subtitle}</Text>}
        </VStack>

        {isCloseable && onClose && (
          <Box pt="4px">
            <IconButton
              variant="ghost"
              ariaLabel="Close"
              onClick={onClose}
              icon="close-24"
            />
          </Box>
        )}
      </HStack>
    </ModalHeader>
  );
}

type Props = {
  title: string;
  subtitle?: React.ReactNode;
  secondaryInfo?: string;
  isCloseable: boolean;
  onClose?: () => void;
  onBack?: () => void;
  modalHeaderSx?: Partial<ModalHeaderProps>;
};
