import React from "react";
import { useLocation } from "react-router-dom";

import useFeatureToggle from "app/hooks/useFeatureToggle";
import FeatureFlags from "utils/featureFlags";

import useVisitedTabs from "app/components/SubscriptionGuard/useVisitedTabs";

import { Links, Link } from "./shared/MenuUI";
import SponsoredListings from "./Menu/SponsoredListings";
import Facets from "./Menu/Facets";

// To add new menu items you need to place them into Menu directory because the
// complexity of this component is already too high
export default function Menu() {
  const location = useLocation();
  const isRecommendationsEnabled = useFeatureToggle(
    FeatureFlags.Recommendations
  );

  const isCollectionsEnabled = useFeatureToggle(FeatureFlags.Collections);
  const isQuizzesEnabled = useFeatureToggle(FeatureFlags.Quizzes);
  const isNavbarUpsellEnabled = useFeatureToggle(
    FeatureFlags.EnableNavbarUpsell
  );

  const isAttributeEnrichmentEnabled = useFeatureToggle(
    FeatureFlags.AttributeEnrichment
  );

  const { visitedTabs } = useVisitedTabs();

  return (
    <Links>
      <Link
        to="/dashboard"
        isActive={
          location.pathname === "/dashboard" ||
          location.pathname === "/dashboard/"
        }
      >
        Home
      </Link>

      <Link
        to="/dashboard/indexes"
        isActive={
          location.pathname.startsWith("/dashboard/indexes") ||
          location.pathname.startsWith("/dashboard/data_set_items")
        }
      >
        Indexes
      </Link>

      <Link
        to="/dashboard/searchandizing/dynamic"
        isActive={location.pathname.startsWith("/dashboard/searchandizing")}
      >
        Searchandizing
      </Link>

      <Link
        to="/dashboard/synonyms/one"
        isActive={location.pathname.startsWith("/dashboard/synonyms")}
      >
        Synonyms
      </Link>

      <Link
        to="/dashboard/redirect-rules"
        isActive={location.pathname.startsWith("/dashboard/redirect-rules")}
      >
        Redirects
      </Link>

      <Facets />

      {(location.pathname.startsWith("/dashboard/collections") ||
        isCollectionsEnabled ||
        isNavbarUpsellEnabled) && (
        <Link
          to="/dashboard/collections"
          isActive={location.pathname.startsWith("/dashboard/collections")}
          isSubscribed={isCollectionsEnabled}
          isVisited={visitedTabs.collections ?? false}
        >
          Collections
        </Link>
      )}

      {(isRecommendationsEnabled || isNavbarUpsellEnabled) && (
        <Link
          to="/dashboard/recommendations"
          isActive={location.pathname.startsWith("/dashboard/recommendations")}
          isSubscribed={isRecommendationsEnabled}
          isVisited={visitedTabs.recommendations ?? false}
        >
          Recommendations
        </Link>
      )}

      {(location.pathname.startsWith("/dashboard/quizzes") ||
        isQuizzesEnabled ||
        isNavbarUpsellEnabled) && (
        <Link
          to="/dashboard/quizzes"
          isActive={location.pathname.startsWith("/dashboard/quizzes")}
          isSubscribed={isQuizzesEnabled}
          isVisited={visitedTabs.quizzes ?? false}
        >
          Quizzes
        </Link>
      )}

      {isAttributeEnrichmentEnabled && (
        <Link
          to="/dashboard/attribute_enrichment"
          isActive={location.pathname.startsWith(
            "/dashboard/attribute_enrichment"
          )}
        >
          Attribute Enrichment
        </Link>
      )}

      <SponsoredListings />
    </Links>
  );
}
