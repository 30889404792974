import reportError from "services/ReportError";

import * as types from "./types";
import { list } from "./list";

export async function listAllPages({
  indexKey,
  section,
  itemIds,
  numResultsPerPage = 100,
  capPage,
}: ListAllPagesArgs): Promise<types.Variation[]> {
  const response = await list({
    indexKey,
    section,
    itemIds,
    page: 1,
    numResultsPerPage,
  });

  const allPages = Math.ceil(response.totalCount / numResultsPerPage);

  if (typeof capPage === "number" && allPages > capPage) {
    reportError("There are more variation pages to load", { allPages });
  }

  const numOfPages = capPage ? Math.min(capPage, allPages) : allPages;

  const promises: Promise<Awaited<ReturnType<typeof list>>>[] = [];

  for (let i = 2; i <= numOfPages; i++) {
    promises.push(
      list({
        indexKey,
        section,
        itemIds,
        numResultsPerPage,
        page: i,
      })
    );
  }

  const results = await Promise.all(promises);

  return [
    ...response.variations,
    ...results.map((result) => result.variations).flat(),
  ];
}

listAllPages.key = "modules/variations/listAllPages";

type ListAllPagesArgs = Omit<Parameters<typeof list>[0], "page"> & {
  capPage?: number;
};
