module.exports = 
                (() => {
                  // Some images/SVGs, that are downloaded not immediately, but due to various conditions
                  // like disabled state or anything else are not taking the change made in
                  // app/javascript/app/cdn/useAssetsFromS3CDNIfEnabled.ts
                  // That's why we have to implement postTransform function manually so source for
                  // them always matches the desired destination.
                  const staticAssetsCDNUrl = document.querySelector(
                    'meta[name="static_assets_cdn_url"]'
                  )?.content;

                  // The expression looks like "__webpack_public__path + fileName.ext"
                  const fileName =  "2d97d0f85ceddeba13e6664d2c7d43a0.gif";
                  
                  if (!fileName || !staticAssetsCDNUrl) {
                    return __webpack_public_path__ + "2d97d0f85ceddeba13e6664d2c7d43a0.gif";
                  }

                  return staticAssetsCDNUrl + "/packs/" + fileName;
                })()
              ;