import { PageGroup } from "app/components/shared/shared/types";

export default function (): PageGroup {
  return {
    items: [
      {
        label: "Knowledgebase",
        href: "https://docs.constructor.com/",
        activePaths: ["https://docs.constructor.com/"],
        isExternal: true,
        isEnabled: true,
        isHistorySupported: false,
        hasVisited: true,
        isSubscribed: true,
        breadcrumbs: [],
        analyticsLabel: "Knowledgebase",
      },
      {
        label: "API docs",
        href: "https://docs.constructor.com/reference/main-readme",
        activePaths: ["https://docs.constructor.com/reference/main-readme"],
        isExternal: true,
        isEnabled: true,
        isHistorySupported: false,
        hasVisited: true,
        isSubscribed: true,
        breadcrumbs: [],
        analyticsLabel: "API_Docs",
      },
    ],
    index: 3,
    label: "Help",
    icon: "help-circle-hollow-16",
    analyticsLabel: "Help",
  };
}
