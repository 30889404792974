import React, { PropsWithRef, PropsWithChildren } from "react";
import { Link as ReactRouterLink } from "react-router-dom";

import {
  Tabs as ChakraTabs,
  TabList as ChakraTabList,
  TabPanels as ChakraTabPanels,
  Tab as ChakraTab,
  TabPanel as ChakraTabPanel,
  Text,
  Box,
  Flex,
  Link,
} from "@chakra-ui/react";

import {
  tabLinkStyle,
  tabListStyle,
  tabListStickyStyle,
  tabPanelStyle,
  tabSideStyle,
  tabsStyle,
  tabStyle,
  // @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
  // eslint-disable-next-line local-rules/enforce-fractal-pattern,
} from "./Tabs.styles";
import {
  TabProps,
  TabsProps,
  TabListProps,
  TabLinkProps,
  TabPanelProps,
  // @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
  // eslint-disable-next-line local-rules/enforce-fractal-pattern,
} from "./Tabs.types";

export const Tabs = ({
  size = "lg",
  variant = "line",
  align,
  isFitted,
  onChange,
  id,
  index,
  defaultIndex,
  children,
  isLazy,
  ...args // only for MarginProps
}: PropsWithRef<PropsWithChildren<TabsProps>>) => {
  return (
    <ChakraTabs
      size={size}
      variant={variant}
      align={align}
      isFitted={isFitted}
      id={id}
      index={index}
      defaultIndex={defaultIndex}
      onChange={onChange}
      isLazy={isLazy}
      {...tabsStyle}
      {...args} // only for MarginProps
    >
      {React.Children.map(children, (child: React.ReactNode) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            // @ts-expect-error Ignoring typescript compilation errors to unlock the upgrade to TS5
            size,
            variant,
          });
        }
      })}
    </ChakraTabs>
  );
};

/**
 * TabList is the wrapper for all Tab components
 */
export const TabList = ({
  size,
  variant,
  children,
  isSticky,
}: PropsWithRef<PropsWithChildren<TabListProps>>) => {
  return (
    <ChakraTabList
      {...tabListStyle(variant)}
      {...(isSticky && tabListStickyStyle)}
    >
      {React.Children.map(children, (child: React.ReactNode) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            // @ts-expect-error Ignoring typescript compilation errors to unlock the upgrade to TS5
            size,
            variant,
          });
        }
      })}
    </ChakraTabList>
  );
};

/**
 * Tab contains the label for one of the tab that can be toggled
 */
export const Tab = ({
  size,
  icon,
  isDisabled,
  id,
  children,
  onClick,
  variant,
}: PropsWithRef<PropsWithChildren<TabProps>>) => {
  return (
    <ChakraTab
      isDisabled={isDisabled}
      onClick={onClick}
      id={id}
      {...tabStyle(size, icon, variant).tab}
    >
      {icon && <Box {...tabStyle(size, icon).tabIcon}>{icon}</Box>}
      {children}
    </ChakraTab>
  );
};

/**
 * TabPanels are the wrapper for TabPanel components
 */
export const TabPanels = ({
  children,
}: // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
PropsWithChildren<Record<never, any>>) => {
  return <ChakraTabPanels>{children}</ChakraTabPanels>;
};

/**
 * TabPanel contains the content associated with a tab.
 * Each content in TabPanel corresponds to the index in the Tab.
 */
export const TabPanel = ({ children, ...props }: TabPanelProps) => {
  return (
    // {...Props} is needed here to make Tabs show/hidden
    <ChakraTabPanel {...tabPanelStyle} {...props}>
      {children}
    </ChakraTabPanel>
  );
};

/**
 * TabSide is a custom tab component for items at the right side. (only works when tabs are aligned left)
 */
export const TabSide = ({
  children,
}: // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
PropsWithChildren<Record<never, any>>) => {
  return (
    <Flex {...tabSideStyle.tabSideContainer}>
      {typeof children === "string" ? (
        <Text {...tabSideStyle.tabSideText}>{children}</Text>
      ) : (
        children
      )}
    </Flex>
  );
};

/**
 * TabLink is a custom tab component that instead of switching between TabPanels, would be switching between pages
 */
export const TabLink = ({
  href,
  size,
  icon,
  isDisabled,
  isHidden,
  id,
  onClick,
  children,
}: PropsWithChildren<TabLinkProps>) => {
  return (
    <Box display={isHidden ? "none" : "flex"}>
      <Link
        as={ReactRouterLink}
        to={(isDisabled ? undefined : href) || ""}
        {...tabLinkStyle}
      >
        <Tab
          size={size}
          isDisabled={isDisabled}
          id={id}
          icon={icon}
          onClick={onClick}
        >
          {children}
        </Tab>
      </Link>
    </Box>
  );
};
