import React, { lazy } from "react";

import Route from "./shared/MainRoute";

const Home = lazy(() => import("./notification_campaigns/index"));
const NewTriggered = lazy(
  () => import("./notification_campaigns/triggered/new")
);
const NewBroadCast = lazy(
  () => import("./notification_campaigns/broadcast/new")
);

export default function NotificationCampaigns() {
  return (
    <>
      <Route exact path="/dashboard/notification_campaigns" component={Home} />

      <Route
        blank
        exact
        path="/dashboard/notification_campaigns/triggered/new"
        component={NewTriggered}
      />

      <Route
        blank
        exact
        path="/dashboard/notification_campaigns/broadcast/new"
        component={NewBroadCast}
      />
    </>
  );
}
