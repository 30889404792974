import React, { useEffect, useRef, useState } from "react";

import Api from "services/Api";
// TODO: Replace this import with date-fns
// eslint-disable-next-line no-restricted-imports
import dayjs, { Dayjs } from "utils/dayjs";
import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

import { isAuthenticationServiceSession } from "utils/authentication";

import SessionExpirationWarningModal from "./SessionWatcher/SessionExpirationWarningModal";
import AutomaticallyLoggedOutModal from "./SessionWatcher/AutomaticallyLoggedOutModal";

const SessionWatcher = ({ children }: React.PropsWithChildren<{}>) => {
  const sessionTimeoutRef = useRef<number | null>(null);
  const warningTimeoutRef = useRef<number | null>(null);

  const [sessionState, setSessionState] = useState<SessionType>("active");

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    const updateTimeouts = (date: Dayjs) => {
      if (!isFlagEnabled(FeatureFlags.UseSessionWatcher)) {
        return;
      }

      setSessionState("active");

      if (sessionTimeoutRef.current) {
        clearTimeout(sessionTimeoutRef.current);
      }

      if (warningTimeoutRef.current) {
        clearTimeout(warningTimeoutRef.current);
      }

      const logoutInMs = date.diff(dayjs(), "milliseconds");

      const warningInMs = date
        .subtract(15, "minutes")
        .diff(dayjs(), "milliseconds");

      // TODO: This is disabled until we can properly handle this case:
      // https://linear.app/constructor/issue/MT-1700/randd-update-authentication-service-watcher
      if (!isAuthenticationServiceSession()) {
        warningTimeoutRef.current = window.setTimeout(() => {
          setSessionState("warning");
        }, warningInMs);
      }

      sessionTimeoutRef.current = window.setTimeout(() => {
        setSessionState("expired");
      }, logoutInMs);
    };

    // Callback handler for updating timeouts from storage events.
    // This is useful for keeping the callbacks in sync across multiple tabs.
    const onStorageUpdate = (event: Pick<StorageEvent, "key" | "newValue">) => {
      const sessionExpiresAt = getExpirationDate(event.newValue);

      if (event.key !== STORAGE_KEY || sessionExpiresAt === null) {
        return;
      }

      updateTimeouts(sessionExpiresAt);
    };

    // Callback handler for updating timeouts from network requests.
    // This is responsible for listening to incoming requests and make sure localStorage is always
    // up-to-date.
    const onSessionWatch = (newSessionExpiresAt: Date) => {
      const sessionExpiresAt = getExpirationDate(
        localStorage.getItem(STORAGE_KEY)
      );

      if (sessionExpiresAt && sessionExpiresAt.isSame(newSessionExpiresAt)) {
        return;
      }

      if (!isFlagEnabled(FeatureFlags.UseSessionWatcher)) {
        return;
      }

      setExpirationDate(newSessionExpiresAt);
      updateTimeouts(dayjs(newSessionExpiresAt));
    };

    Api.events.on("session-watch", onSessionWatch);
    window.addEventListener("storage", onStorageUpdate);

    // Initializes callbacks based on the document initial expiration timestamp
    const sessionExpirationMeta = document.querySelector<HTMLMetaElement>(
      "meta[name=session_expires_at]"
    )?.content;

    if (sessionExpirationMeta) {
      const sessionExpiration = dayjs.unix(Number(sessionExpirationMeta));

      setExpirationDate(sessionExpiration.toDate());
      updateTimeouts(sessionExpiration);
    }

    return () => {
      Api.events.removeListener("session-watch", onSessionWatch);
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);

  let sessionExpirationWarningModal: React.ReactNode;

  if (sessionState === "warning" && !isAuthenticationServiceSession()) {
    const sessionExpiresAt = getExpirationDate(
      localStorage.getItem(STORAGE_KEY)
    )?.toDate();

    sessionExpirationWarningModal = sessionExpiresAt ? (
      <SessionExpirationWarningModal sessionExpiresAt={sessionExpiresAt} />
    ) : null;
  }

  return (
    <>
      {sessionState === "expired" ? <AutomaticallyLoggedOutModal /> : children}
      {sessionExpirationWarningModal}
    </>
  );
};

export default SessionWatcher;

type SessionType = "active" | "warning" | "expired";

function getExpirationDate(newValue: string | null): Dayjs | null {
  try {
    if (newValue) {
      return dayjs(new Date(newValue));
    }
    return null;
  } catch {
    return null;
  }
}

function setExpirationDate(date: Date): void {
  localStorage.setItem(STORAGE_KEY, date.toISOString());
}

const STORAGE_KEY = "session-expiration-at";
