/**
 * Returns auth service token for the current user.
 * Can return token for the impersonated user.
 */
export function getAuthServiceToken(): string {
  const token = document.querySelector<HTMLMetaElement>(
    '[name="cnstrc_token"]'
  );

  return token ? token.content : "";
}

/**
 * Returns auth service token for the user who is impersonating.
 */
export function getAdminAuthServiceToken(): string {
  const token = document.querySelector<HTMLMetaElement>(
    '[name="cnstrc_admin_token"]'
  );

  return token ? token.content : "";
}

/**
 * Returns true if there's an active session for authentication service.
 *
 * We should not interact with authentication service if this returns `false`.
 * This represents the case where we're impersonating a user through admin dashboard who
 * is already migrated to the new auth service.
 */
export function isAuthenticationServiceSession(): boolean {
  const cnstrcToken = getAuthServiceToken();

  return cnstrcToken !== "";
}
