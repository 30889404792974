import React from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";

import { Configuration } from "app/App";
// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import Alert from "components/Modules/Alert";
import Page from "components/Modules/Page";
import FeatureFlags from "utils/featureFlags";
import useFeatureToggle from "app/hooks/useFeatureToggle";

export default function IncompleteDataWarning() {
  // @todo This is a bit of a misuse of feature flags, review this approach
  //       in ch8301
  const showWarning = useFeatureToggle(FeatureFlags.ProspectTrackingMessage);

  if (!showWarning) {
    return null;
  }

  return (
    <Configuration>
      <Page
        css={css`
          min-height: 0;
          margin: 40px auto -20px;
        `}
      >
        <Alert type="warning" noClose>
          <FormattedMessage id="prospect_warning_message" />
        </Alert>
      </Page>
    </Configuration>
  );
}
