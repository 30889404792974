import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";

import useLoadingStateConfig from "app/hooks/useLoadingStateConfig";
import getDisplayName from "utils/getDisplayName";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Spinner, { SpinnerOverlay, SpinnerBackground } from "./Spinner";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { FlexContainer } from "./SpinnerLoader";

export default function withSpinner<ComponentProps extends {}, ContainerProps>(
  Component: React.ComponentType<ComponentProps>,
  {
    container: Container,
    ...containerProps
  }: {
    container?: React.ComponentType<ContainerProps>;
  } & Partial<ContainerProps> = {}
) {
  const ComponentWithSpinner: React.FC<Props & ComponentProps> = ({
    fetching = false,
    overlay = false,
    spinnerContainerProps,
    spinnerMarginTop,
    message,
    messageDelay = 0,
    // Default to false so that spinners would be more responsive and show immediately without delay
    shouldDelaySpinner = false,
    ...props
    // cognitive-complexity rule is temporarily disabled for this function, please refactor this function
    // whenever possible.
    // eslint-disable-next-line sonarjs/cognitive-complexity
  }) => {
    const config = useLoadingStateConfig();
    const [spinnerShowTime, setSpinnerShowTime] = useState<number | null>(null);
    const [displayMessage, setDisplayMessage] = useState(messageDelay === 0);
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
      if (fetching && shouldDelaySpinner) {
        const timeout = setTimeout(() => {
          setSpinnerShowTime(Date.now());
        }, 1000);

        return () => clearTimeout(timeout);
      }
    }, [fetching, shouldDelaySpinner]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        setDisplayMessage(true);
      }, messageDelay);

      return () => clearTimeout(timeout);
    }, [messageDelay]);

    useEffect(() => {
      if (fetching) {
        setIsComplete(false);
        setSpinnerShowTime(null);
      } else {
        if (
          spinnerShowTime &&
          config.minimumSpinnerDelay > 0 &&
          shouldDelaySpinner
        ) {
          const timeout = setTimeout(() => {
            setIsComplete(true);
          }, Math.max(config.minimumSpinnerDelay - (Date.now() - spinnerShowTime), 0));

          return () => clearTimeout(timeout);
        }

        setIsComplete(true);
      }
      // @refactoring Forbid deactivation of hook dependencies (https://constructor.slab.com/posts/rfc-remove-deactivation-of-the-es-lint-rule-for-hook-dependenciesoli-vk98awgw)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetching, shouldDelaySpinner]);

    const SpinnerContainer = Container || FlexContainer;

    if (!overlay && (fetching || !isComplete)) {
      return (
        <SpinnerContainer
          {...containerProps}
          {...spinnerContainerProps}
          css={css`
            flex-direction: column;
          `}
        >
          {(spinnerShowTime || !shouldDelaySpinner) && (
            <div
              css={css`
                position: relative;
              `}
            >
              <Spinner
                css={css`
                  ${spinnerMarginTop
                    ? `margin-top: ${spinnerMarginTop}px;`
                    : `top: 50%;`}
                `}
              />

              {displayMessage && message && (
                <p
                  css={css`
                    position: absolute;
                    top: 40px;
                    text-align: center;
                    min-width: 220px;
                    left: -110px;
                  `}
                >
                  {message}
                </p>
              )}
            </div>
          )}
        </SpinnerContainer>
      );
    }

    return (
      <>
        {(fetching || !isComplete) && (
          <SpinnerOverlay
            aria-label="Loading overlay"
            css={css`
              align-items: start;
              z-index: 10;
            `}
          >
            <SpinnerBackground />
            {(spinnerShowTime || !shouldDelaySpinner) && (
              <Spinner
                css={css`
                  ${spinnerMarginTop
                    ? `margin-top: ${spinnerMarginTop}px;`
                    : `top: 50%;`}
                `}
              />
            )}
          </SpinnerOverlay>
        )}

        <Component {...(props as ComponentProps)} />
      </>
    );
  };

  ComponentWithSpinner.displayName = `${getDisplayName(Component)}WithSpinner`;

  return ComponentWithSpinner;
}

type Props = {
  fetching?: boolean;
  overlay?: boolean;
  // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  spinnerContainerProps?: any;
  spinnerMarginTop?: number;
  message?: string;
  /**
   * The amount of time (ms) we should wait before showing the message.
   */
  messageDelay?: number;
  /**
   * If true, the spinner won't be shown for some time. This is the default
   * behavior but considered deprecated. Until we set it to `false` by default,
   * we recommend you set it on each component usage.
   */
  shouldDelaySpinner?: boolean;
};
