import React from "react";

import {
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  SystemStyleObject,
  HStack,
  Modal,
  Text,
  Box,
  ModalHeaderProps,
} from "@chakra-ui/react";

import TrackingProvider from "app/providers/TrackingProvider";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./BaseModal.styles";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Header from "./Header";

export default function BaseModal({
  isCloseable = true,
  avoidCloseOnOverlayClick = false,
  modalContentSx,
  modalHeaderSx,
  secondaryInfo,
  rightFooter,
  leftFooter,
  subtitle,
  onClose,
  onBack,
  isOpen,
  title,
  body,
  size = "md",
  modalBodySx,
  withoutFooter,
  modalHeaderSeparatorSx,
  motionPreset,
}: Props) {
  return (
    <TrackingProvider context={`${title || "No title"} (Modal)`}>
      <Modal
        {...styles.modalStyle}
        closeOnOverlayClick={isCloseable && !avoidCloseOnOverlayClick}
        closeOnEsc={isCloseable && !avoidCloseOnOverlayClick}
        onClose={onClose}
        isOpen={isOpen}
        motionPreset={motionPreset}
      >
        <ModalOverlay />

        <ModalContent
          {...styles.modalContentStyle(size)}
          sx={modalContentSx}
          data-testid="modal-content"
        >
          <Header
            modalHeaderSx={modalHeaderSx}
            secondaryInfo={secondaryInfo}
            isCloseable={isCloseable}
            onClose={onClose}
            subtitle={subtitle}
            title={title}
            onBack={onBack}
          />

          {!!body && (
            <Box {...styles.headerSeparatorStyle} sx={modalHeaderSeparatorSx} />
          )}
          {body && (
            <ModalBody
              {...styles.modalBodyStyle}
              sx={modalBodySx}
              aria-label="modal-body"
            >
              {typeof body === "string" ? (
                <Text {...styles.bodyTextStyle} data-testid="modal-body-text">
                  {body}
                </Text>
              ) : (
                body
              )}
            </ModalBody>
          )}

          {!withoutFooter && (
            <ModalFooter {...styles.modalFooterStyle}>
              <HStack {...styles.footerContainerStyle}>
                {leftFooter && (
                  <HStack {...styles.leftFooterStackStyle}>
                    {renderFooter({
                      footer: leftFooter,
                      onClose,
                    })}
                  </HStack>
                )}

                {rightFooter && (
                  <HStack {...styles.rightFooterStackStyle}>
                    {renderFooter({
                      footer: rightFooter,
                      onClose,
                    })}
                  </HStack>
                )}
              </HStack>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </TrackingProvider>
  );
}

/**
 * Renders the modal footer component, supporting a function or ReactNode.
 */
function renderFooter({ footer, onClose }: RenderFooterArgs) {
  if (typeof footer === "function") {
    const props: ModalFooterProps = {
      onClose,
    };

    return footer(props);
  }

  return <>{footer}</>;
}

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  isCloseable?: boolean;
  /**
   * if `isCloseable` is true, we close the modal on overlayClick by default unless
   * `avoidCloseOnOverlayClick` is set to true
   */
  avoidCloseOnOverlayClick?: boolean;
  onBack?: () => void;
  subtitle?: React.ReactNode;
  secondaryInfo?: string;
  body?: React.ReactNode;
  rightFooter?: ModalFooter;
  leftFooter?: ModalFooter;
  modalContentSx?: SystemStyleObject;
  modalHeaderSx?: Partial<ModalHeaderProps>;
  modalBodySx?: SystemStyleObject;
  size?: "sm" | "md" | "lg" | "full";
  modalHeaderSeparatorSx?: SystemStyleObject;
  withoutFooter?: boolean;
  motionPreset?: React.ComponentProps<typeof Modal>["motionPreset"];
};

export type ModalFooter =
  | React.ReactNode
  | ((props: ModalFooterProps) => React.ReactNode);

export type ModalFooterProps = {
  onClose: () => void;
};

type RenderFooterArgs = ModalFooterProps & {
  footer: ModalFooter;
};
