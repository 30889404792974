import AuthApi from "services/AuthApi";

export type AssignCompanyRoleToUserParams = {
  companyId: string;
  userId: string;
  roleId: string;
};

/**
 * @description Assign role to user for the current company
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-assign-company-role-to-user
 * @returns {void}
 */
export default async function assignCompanyRoleToUser({
  companyId,
  userId,
  roleId,
}: AssignCompanyRoleToUserParams): Promise<void> {
  return await AuthApi.post(
    ["v1", "companies", companyId, "users", userId, "roles"],
    {},
    {
      role_id: roleId,
    }
  );
}

assignCompanyRoleToUser.key =
  "modules/authentication/actions/companies/assignCompanyRoleToUser";
