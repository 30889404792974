import { ValidPropertyType } from "@amplitude/analytics-types";
import * as amplitude from "@amplitude/analytics-browser";

export enum AmplitudeEvents {
  SidebarToggle = "SIDEBAR_TOGGLE",
  MenuSubItemClicked = "MENU_SUBITEM_CLICKED",
  MenuItemClicked = "MENU_ITEM_CLICKED",
  AbTestingRulesOpenSummaryModal = "AB_TESTING_RULES_OPEN_SUMMARY_MODAL",
  AbTestingRulesTestCreated = "AB_TESTING_RULES_TEST_CREATED",
  AbTestingRecommendationsTestCreated = "AB_TESTING_RECOMMENDATIONS_TEST_CREATED",
  AbTestingRecommendationsOpenSummaryModal = "AB_TESTING_RECOMMENDATIONS_OPEN_SUMMARY_MODAL",
  AbTestingTrialReadMoreClick = "AB_TESTING_TRIAL_READ_MORE_CLICK",
  IaSidebarMainNavigation = "MAIN_NAVIGATION",
  SearchandisingFilters = "SEARCHANDIZING_FILTER_CLICK",
  CollectionsAiCollectionsGenerateItems = "COLLECTIONS:AI_COLLECTIONS_GENERATE_ITEMS",
  Homepage = "HOMEPAGE",
  HomepageGraph = "HOMEPAGE:GRAPH",
  HomepageAiSynonyms = "HOMEPAGE:AI_SYNONYMS",
  HomepagePagesSearch = "HOMEPAGE:PAGES_SEARCH",
  HomepagePagesBrowse = "HOMEPAGE:PAGES_BROWSE",
  HomepageRulesSearch = "HOMEPAGE:RULES_SEARCH",
  HomepageRulesBrowse = "HOMEPAGE:RULES_BROWSE",
  HomepageAddedItems = "HOMEPAGE:ADDED_ITEMS",
  HomepageAgedSearchRules = "HOMEPAGE:AGED_RULES_SEARCH",
  HomepageAgedBrowseRules = "HOMEPAGE:AGED_RULES_BROWSE",
  HomepageDismissRulesSearchItem = "HOMEPAGE:DISMISS_RULES_SEARCH_ITEM",
  HomepageDismissRulesBrowseItem = "HOMEPAGE:DISMISS_RULES_BROWSE_ITEM",
  HomepageDismissPagesSearchItem = "HOMEPAGE:DISMISS_PAGES_SEARCH_ITEM",
  HomepageDismissPagesBrowseItem = "HOMEPAGE:DISMISS_PAGES_BROWSE_ITEM",
  HomepageSendHolidayInsights = "HOMEPAGE:SEND_HOLIDAY_INSIGHTS",
  HomepageDismissHolidayInsights = "HOMEPAGE:DISMISS_HOLIDAY_INSIGHTS",
  HomepageZeroResults = "HOMEPAGE:ZERO_RESULTS",
  HomepageTrendingUp = "HOMEPAGE:TRENDING_UP",
  HomepageTrendingDown = "HOMEPAGE:TRENDING_DOWN",
  HomepageTopSearches = "HOMEPAGE:TOP_SEARCHES",
  HomepageTopAutosuggestSelects = "HOMEPAGE:TOP_AUTOSUGGEST_SELECTS",
  HomepageTopConvertingItems = "HOMEPAGE:TOP_CONVERTING_ITEMS",
  RulePerformanceSlottingRuleTypeTab = "RULE_PERFORMANCE:SLOTTING_RULE_TYPE_TAB",
  AnalyticsConversionsChartToggle = "ANALYTICS:CONVERSIONS:CHART_TOGGLE",
  AnalyticsOpportunitiesChartToggle = "ANALYTICS:OPPORTUNITIES:CHART_TOGGLE",
  AnalyticsReportTopPerformingItems = "ANALYTICS:REPORTS:TOP_PERFORMING_ITEMS",
  AnalyticsConversionsNewItemsTab = "ANALYTICS:CONVERSIONS:TOP_PERFORMING_ITEMS:SWITCH_TO_NEW_ITEMS",
  AnalyticsConversionsItemsTable = "ANALYTICS:CONVERSIONS:TOP_PERFORMING_ITEMS",
  AnalyticsBrowseOverviewTab = "ANALYTICS:BROWSE:OVERVIEW_TAB",
  AnalyticsChartsSegmentClick = "ANALYTICS:CHARTS:SEGMENT:CLICK",
}

export enum AmplitudeIdentifyProperties {
  ViewportWidth = "viewport_width",
  ViewportHeight = "viewport_height",
  UserId = "user_id",
  FirstName = "first_name",
  LastName = "last_name",
  Email = "email",
  IsIaResponsivePageLayoutActive = "is_ia_responsive_page_layout_active",
  AdminEmail = "admin_email",
  ImpersonatingUserEmail = "impersonating_user_email",
  CompanyName = "company_name",
  CompanyId = "company_id",
  FeatureFlags = "feature_flags",
}

export const SearchandisingFilterEvents: Record<
  string,
  Record<string, string>
> = {
  automaticallyGenerated: {
    all: "All_Rule_Type",
    False: "All_Manual_Rule_Type",
    True: "All_Automatic_Rule_Type",
  },
  manualRuleType: {
    boost: "Boost_Manual_Rule_Type",
    bury: "Bury_Manual_Rule_Type",
    slot: "Slotting_Manual_Rule_Type",
    blacklist: "Blocklist_Manual_Rule_Type",
    whitelist: "Allowlist_Manual_Rule_Type",
    variation_slicing: "VariationSlicing_Manual_Rule_Type",
    content: "Content_Manual_Rule_Type",
  },
  autoRuleType: {
    boost: "Boost_Automatic_Rule_Type",
    bury: "Bury_Automatic_Rule_Type",
  },
  conditions: {
    need_review: "Review_Condition",
    expired: "ExpiredRule_Condition",
  },
  user: {
    unique: "Unique_User",
    allUsers: "All_User",
  },
};

export enum AmplitudeEventProperties {
  IsSidebarOpen = "is_sidebar_open",
}

export const sendAmplitudeEvent = ({
  eventName,
  eventProperties,
}: AmplitudeEvent) => {
  amplitude.track(eventName, eventProperties);
};

export const sendAmplitudeIdentifyEvent = (
  properties: AmplitudeIdentifyEvent
) => {
  const identify = new amplitude.Identify();
  Object.entries(properties).forEach(([property, value]) => {
    identify.set(property, value);
  });

  amplitude.identify(identify);
};

export type AmplitudeEvent = {
  eventName: string;
  eventProperties: Record<string, unknown>;
};

export type AmplitudeIdentifyEvent = Record<string, ValidPropertyType>;

export type AnalyticsChartsSegmentClickProperties = {
  chartName: string;
  segment: string;
};
