import {
  Company,
  NextPageCursor,
  NextPageCursorData,
} from "modules/authentication/actions/shared/types";

import AuthApi from "services/AuthApi";

export type GetCompaniesByUserParams = {
  userId: string;
  nextPageCursor?: string;
};

/**
 * @description Get all companies assigned to a specific user.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Users/operation/v1-auth-users-get-companies-assigned-to-user
 * @returns {GetCompaniesByUserResponse}
 */
export default async function getCompaniesByUser({
  userId,
  nextPageCursor = "",
}: GetCompaniesByUserParams): Promise<GetCompaniesByUserResponse> {
  const queryParams = nextPageCursor
    ? { next_page_cursor: nextPageCursor }
    : {};

  const result = await AuthApi.get<GetCompaniesByUserResponseData>(
    ["v1", "users", userId, "companies"],
    queryParams
  );

  return GetCompaniesByUserResponse.fromGetCompaniesByUserResponseData(result);
}

getCompaniesByUser.key =
  "modules/authentication/actions/users/getCompaniesByUser";

export type GetCompaniesByUserResponseData = {
  companies: {
    default_session_timeout: number;
    display_name: string;
    id: string;
    slug: string;
    type: string;
  }[];
} & NextPageCursorData;

export type GetCompaniesByUserResponse = {
  companies: Company[];
} & NextPageCursor;

export const GetCompaniesByUserResponse = {
  fromGetCompaniesByUserResponseData: (
    data: GetCompaniesByUserResponseData
  ): GetCompaniesByUserResponse => {
    const getCompaniesByUserResponse: GetCompaniesByUserResponse = {
      companies: data.companies.map((company) => ({
        defaultSessionTimeout: company.default_session_timeout,
        displayName: company.display_name,
        id: company.id,
        slug: company.slug,
        type: company.type,
      })),
      nextPageCursor: data.next_page_cursor,
    };
    return getCompaniesByUserResponse;
  },
};
