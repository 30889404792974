export function getNameFromEmail(email: string): string {
  const [user] = email.split("@");

  if (user.includes("+")) {
    return user.split("+")[0];
  }

  if (user.includes(".")) {
    return user.split(".")[0];
  }

  if (user.includes("_")) {
    return user.split("_")[0];
  }

  return user;
}
