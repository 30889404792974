import useAdminTokenValidate from "app/queries/authentication/useAdminTokenValidate";
import { AuthAuthScope, AuthOperation } from "app/auth";
import { isAuthenticationServiceSession } from "utils/authentication";

const useAdminAuthPermission = () => {
  const isAuthServiceEnabled = isAuthenticationServiceSession();

  const { data: token, isFetching } = useAdminTokenValidate([], {
    enabled: isAuthServiceEnabled,
  });

  if (!isAuthServiceEnabled || isFetching || !token) {
    return {
      canImpersonate: false,
    };
  }

  const getScopes = (operation: AuthOperation) =>
    token.permissions
      .filter((permission) => permission.operation.includes(operation))
      .map((permission) => permission.scope);

  const adminCan = (scope: AuthAuthScope | AuthAuthScope[]) => {
    const scopes = scope ? (Array.isArray(scope) ? scope : [scope]) : [];

    const adminHasScopes = getScopes(AuthOperation.Write);

    return scopes.every((scope) => adminHasScopes.includes(scope));
  };

  return {
    canImpersonate: adminCan(AuthAuthScope.AuthImpersonate),
  };
};

export default useAdminAuthPermission;
