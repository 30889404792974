export enum HttpStatus {
  Ok = 200,
  Created = 201,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  UnprocessableEntity = 422,
  InternalServerError = 500,
  TooManyRequests = 429,
}
