import {
  ModalFooterProps,
  ModalProps,
  StackProps,
  TextProps,
  BoxProps,
  ModalBodyProps,
} from "@chakra-ui/react";

export const modalStyle: Partial<ModalProps> = {
  lockFocusAcrossFrames: false,
  isCentered: true,
  autoFocus: false,
};

export const modalContentStyle = (size: "sm" | "md" | "lg" | "full") => {
  let width = "572px";
  switch (size) {
    case "sm":
      width = "400px";
      break;
    case "md":
      width = "572px";
      break;
    case "lg":
      width = "960px";
      break;
    case "full":
      width = "100vw";
      break;
    default:
      width = "572px";
  }

  return {
    minWidth: width,
    maxWidth: width,
    minHeight: size === "full" ? "100vh" : undefined,
    borderRadius: size === "full" ? 0 : "6px",
    padding: size === "full" ? 0 : undefined,
    margin: size === "full" ? 0 : undefined,
  };
};

export const modalBodyStyle: Partial<ModalBodyProps> = {
  flexDirection: "column",
  display: "flex",
  pb: "32px",
  px: "32px",
  pt: "8px",
};

export const headerSeparatorStyle: Partial<BoxProps> = {
  backgroundColor: "grey-verylight-green",
  height: "2px",
  width: "full",
  mb: "18px",
};

export const bodyTextStyle: Partial<TextProps> = {
  textStyle: "body1",
  m: 0,
};

export const modalFooterStyle: Partial<ModalFooterProps> = {
  bgColor: "grey-verylight-green",
  borderBottomRadius: "md",
  px: "32px",
  py: "24px",
};

export const footerContainerStyle: Partial<StackProps> = {
  width: "full",
  spacing: 4,
};

export const leftFooterStackStyle: Partial<StackProps> = {
  ...footerContainerStyle,
  justifyContent: "flex-start",
};

export const rightFooterStackStyle: Partial<StackProps> = {
  ...footerContainerStyle,
  justifyContent: "flex-end",
};
