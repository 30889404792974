import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";

// TODO: Replace this import with date-fns
// eslint-disable-next-line no-restricted-imports
import moment from "modules/moment";
import DateInputElement from "components/Modules/Forms/DateInput";

export default function DateInput({
  value,
  onChange,
  isError,
  ...props
}: props) {
  const [currentValue, setCurrentValue] = useState(value.format("MM/DD/YYYY"));

  const onDateChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCurrentValue(event.target.value);
  };

  useEffect(() => {
    const date = moment(currentValue, "MM/DD/YYYY", true);
    if (date.isValid()) {
      onChange(date);
    }
    // @refactoring Forbid deactivation of hook dependencies (https://constructor.slab.com/posts/rfc-remove-deactivation-of-the-es-lint-rule-for-hook-dependenciesoli-vk98awgw)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  useEffect(() => {
    setCurrentValue(value.format("MM/DD/YYYY"));
  }, [value]);

  const reset = () => {
    setCurrentValue(value.format("MM/DD/YYYY"));
  };

  return (
    <DateInputElement
      value={currentValue}
      type="text"
      onChange={onDateChange}
      onBlur={reset}
      css={css`
        width: 280px;
        padding-top: 15px;
        padding-bottom: 15px;
        ${isError && `border-color: var(--accent-danger);`}
      `}
      {...props}
    />
  );
}

type props = {
  value: moment.Moment;
  isError?: boolean;
  onChange: (date: moment.Moment) => void;
  className?: string;
};
