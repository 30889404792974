import React, { PropsWithChildren } from "react";
import {
  Accordion as ChakraAccordion,
  AccordionItem as ChakraAccordionItem,
  AccordionButton as ChakraAccordionButton,
  AccordionPanel as ChakraAccordionPanel,
  AccordionIcon as ChakraAccordionIcon,
  Text,
  Box,
  Flex,
  Grid,
  GridItem,
} from "@chakra-ui/react";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./Accordion.styles";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { AccordionItemProps, AccordionProps } from "./Accordion.types";

/**
 * Accordion acts as a wrapper for all AccordionItem belonging in the same Accordion Group
 */
export const Accordion = ({
  index,
  defaultIndex,
  allowMultiple = true,
  onChange,
  children,
}: PropsWithChildren<AccordionProps>) => {
  if (typeof index === "number") {
    index = [index];
  }

  if (typeof defaultIndex === "number") {
    defaultIndex = [defaultIndex];
  }

  return (
    <ChakraAccordion
      index={index}
      defaultIndex={defaultIndex}
      allowMultiple={allowMultiple}
      allowToggle={allowMultiple ? undefined : true}
      onChange={onChange}
      {...styles.accordionStyle}
    >
      {children}
    </ChakraAccordion>
  );
};

/**
 * There can be multiple AccordionItem for each Accordion.
 */
export const AccordionItem = ({
  size = "md",
  title,
  subtitle,
  panelPadding,
  children,
  hideSeparator,
  variant = "default",
}: PropsWithChildren<AccordionItemProps>) => {
  return (
    <ChakraAccordionItem
      {...styles.accordionItem[variant]}
      _hover={styles.accordionItemHover[variant]}
    >
      <ChakraAccordionButton
        data-testid="accordion-button"
        {...styles.accordionButtonStyle(size, variant)}
        _hover={styles.accordionButtonHover[variant]}
        _focusWithin={styles.accordionButtonFocusWithin[variant]}
      >
        <Grid {...styles.accordionGridStyle}>
          <GridItem>
            <Flex>
              <Box {...styles.accordionHeaderStyle}>
                {typeof title === "string" ? (
                  <Text
                    {...styles.accordionHeaderTitleStyle}
                    data-testid="accordion-title"
                  >
                    {!!title && title}
                  </Text>
                ) : (
                  title
                )}
              </Box>
              <ChakraAccordionIcon color="grey-dark" />
            </Flex>
          </GridItem>
          {subtitle && (
            <GridItem>
              <Box {...styles.accordionHeaderStyle}>
                <Text
                  {...styles.accordionHeaderSubtitleStyle}
                  data-testid="accordion-subtitle"
                >
                  {subtitle}
                </Text>
              </Box>
            </GridItem>
          )}
        </Grid>
      </ChakraAccordionButton>
      {!hideSeparator && (
        <Box
          {...styles.accordionSeparatorStyle(size)}
          data-testid="accordion-separator"
        />
      )}

      <ChakraAccordionPanel
        {...styles.accordionPanelStyle(size, variant)}
        {...panelPadding}
        data-testid="accordion-body"
      >
        {children}
      </ChakraAccordionPanel>
    </ChakraAccordionItem>
  );
};
