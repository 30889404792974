import URI from "utils/urijs";

export function notificationCampaigns({
  indexKey,
  tab,
}: { tab?: string; indexKey?: string } = {}): uri.URI {
  return URI("/dashboard/notification_campaigns").search({
    index_key: indexKey,
    tab,
  });
}
export function notificationCampaignsNewTriggeredCampaign({
  indexKey,
}: { tab?: string; indexKey?: string } = {}): uri.URI {
  return URI("/dashboard/notification_campaigns/triggered/new").search({
    index_key: indexKey,
  });
}
export function notificationCampaignsNewBroadcastCampaign({
  indexKey,
}: { tab?: string; indexKey?: string } = {}): uri.URI {
  return URI("/dashboard/notification_campaigns/broadcast/new").search({
    index_key: indexKey,
  });
}
