import { UserLoggedOutError } from "modules/appError";
import { HttpStatus } from "types/http";
import {
  getAdminAuthServiceToken,
  getAuthServiceToken,
} from "utils/authentication";

import ApiInstance, { ApiClass } from "./Api";
import { ApiError, IApiError, MultipleApiErrors } from "./ApiErrors";

export const authServiceUrl =
  document.querySelector<HTMLMetaElement>('meta[name="auth_service_url"]')
    ?.content ?? "";

type CommonApiError = ApiError | MultipleApiErrors;

class AuthApi extends ApiClass {
  protected getError<Err extends CommonApiError = CommonApiError>(
    status: HttpStatus,
    result: Err,
    formatError?: (err: Err) => IApiError
  ) {
    if (MultipleApiErrors.is(result)) {
      if (
        status === HttpStatus.Unauthorized &&
        result.errors[0].code === "unauthorized"
      ) {
        // Since authorization's consumer its the webapp UI itself
        // there is no need to have logic of redirect to the sign_in page when token expires
        // as a part of the rails server
        ApiInstance.get(["users", "sign_out"]);
        return new UserLoggedOutError();
      }

      return super.getError(
        status,
        {
          ...result,
          error: result.errors.map(
            (err) => `${err.code}; ${err.message}; ${err.source}`
          ),
        },
        formatError
      );
    }

    return super.getError(
      status,
      result,
      formatError as (err: ApiError) => IApiError
    );
  }
}

const AuthApiInstance = new AuthApi({
  baseUrl: authServiceUrl,
  getFetchOptions,
});

const AuthApiAdminInstance = new AuthApi({
  baseUrl: authServiceUrl,
  getFetchOptions: getAdminFetchOptions,
});

const MfaApiInstance = new AuthApi({
  baseUrl: authServiceUrl,
  getFetchOptions: getMfaFetchOptions,
});

export {
  AuthApiInstance as default,
  AuthApiAdminInstance as AdminAuthApi,
  MfaApiInstance as MfaApi,
};

type FetchOptions = {
  signal?: AbortSignal | null;
};

function getFetchOptions(
  options: FetchOptions = {}
): Pick<RequestInit, "credentials" | "headers" | "signal"> {
  const headers: Record<string, string> = {};
  const { signal } = options;

  const cnstrcToken = getAuthServiceToken();

  if (cnstrcToken) {
    headers["Authorization"] = `Bearer ${cnstrcToken}`;
  }

  return {
    credentials: "same-origin",
    headers,
    signal,
  };
}

function getAdminFetchOptions(
  options: FetchOptions = {}
): Pick<RequestInit, "credentials" | "headers" | "signal"> {
  const headers: Record<string, string> = {};
  const { signal } = options;

  const cnstrcToken = getAuthServiceToken();
  const adminToken = getAdminAuthServiceToken();

  const token = adminToken || cnstrcToken;

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return {
    credentials: "same-origin",
    headers,
    signal,
  };
}

/**
 * When interacting with MFA endpoints, we need to specify the actual user in the request,
 * so that we can send the email to the correct user.
 */
function getMfaFetchOptions(
  options: FetchOptions = {}
): Pick<RequestInit, "credentials" | "headers" | "signal"> {
  const headers: Record<string, string> = {};
  const { signal } = options;

  // `sessionToken` represents the user that is currently logged in (or being impersonated)
  const sessionToken = getAuthServiceToken();
  if (sessionToken) {
    headers["Authorization"] = `Bearer ${sessionToken}`;
  }

  // `internalUserToken` represents the user that can impersonate other users
  const internalUserToken = getAdminAuthServiceToken();
  if (internalUserToken) {
    headers["x-cnstrc-actual-user-token"] = internalUserToken;
  }

  return {
    credentials: "same-origin",
    headers,
    signal,
  };
}
