import React from "react";
import { useLocation } from "react-router";

import { Link } from "app/components/Navigation/shared/MenuUI";
import { FeatureFlags } from "utils/generatedFeatures";
import { isFlagEnabled } from "utils/featureFlags";

export default function Facets() {
  const location = useLocation();

  const isFacetSearchandizingEnabled = isFlagEnabled(
    FeatureFlags.FacetsSearchandizing
  );
  const isSearchFacetSearchandizingEnabled = isFlagEnabled(
    FeatureFlags.SearchFacetsSearchandizing
  );

  if (
    !location.pathname.startsWith("/dashboard/modify-facets") &&
    !isFacetSearchandizingEnabled
  ) {
    return null;
  }

  return (
    <Link
      to={
        isSearchFacetSearchandizingEnabled
          ? "/dashboard/modify-facets/search"
          : "/dashboard/modify-facets/browse"
      }
      isActive={location.pathname.startsWith("/dashboard/modify-facets")}
    >
      Facets
    </Link>
  );
}
