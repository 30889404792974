import { textStyles } from "app/designSystem/theme/foundations/typography";

export default {
  parts: ["container", "item", "icon"],
  baseStyle: {
    container: {
      padding: 0,
      margin: 0,
      borderRadius: "6px",
      overflowY: "auto",
      boxSizing: "content-box",
      display: "inline-flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      maxWidth: "none",
      boxShadow: "feedback",
    },
    item: {
      ...textStyles.body1,
      textAlign: "left",
      color: "grey-dark",
      position: "relative",
      padding: "12px 20px",
      bg: "white",
      border: "none",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      cursor: "pointer",
      _hover: {
        backgroundColor: "grey-pale",
      },
      _focus: {
        backgroundColor: "grey-pale",
        outline: "none",
      },
      _selected: {
        backgroundColor: "grey-verylight-green",
      },
      _notLast: {
        _before: {
          content: "''",
          position: "absolute",
          bottom: 0,
          left: "20px",
          right: "20px",
          height: "1px",
          width: "calc(100% - 40px)",
          backgroundColor: "grey-light-green",
        },
      },
      _disabled: {
        cursor: "default",
        color: "grey-disabled",
        _hover: {
          backgroundColor: "transparent",
        },
        _focus: {
          backgroundColor: "transparent",
        },
      },
    },
  },
  sizes: {},
  variants: {
    default: {},
    "with-header": {
      container: {
        position: "relative",
      },
      item: {
        _hover: {
          backgroundColor: "white",
        },
        ...textStyles.subheadline,
        // increase top / bottom padding to compensate decreased line height
        paddingTop: "14px",
        paddingBottom: "14px",
        position: "sticky",
        top: "0",
        zIndex: "1",
      },
    },
  },
  defaultProps: {
    variants: "default",
  },
};
