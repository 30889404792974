import React from "react";

import {
  Events,
  normalizeText,
  useTracking,
} from "app/providers/TrackingProvider";

import * as UI from "./shared/UI";

const Action = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<Props>
>(function ButtonComponent(
  { children, kind = "text", size = "default", onClick, ...props },
  ref
) {
  const { trackEvent } = useTracking();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    trackEvent(Events.ButtonClick, {
      title: props.title,
      text: normalizeText(event.currentTarget.innerText),
      kind,
      size,
    });

    onClick?.(event);
  };

  return (
    <UI.ButtonElement
      {...props}
      kind={kind}
      size={size}
      onClick={handleClick}
      ref={ref}
    >
      {children}
    </UI.ButtonElement>
  );
});

type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  kind?: UI.Kind;
  size?: keyof (typeof UI)["sizes"];
  title?: string;
  className?: string;
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
};

export default Action;
