import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { css, keyframes, SerializedStyles } from "@emotion/react";

import revealElement from "utils/revealElement";
// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme from "components/theme";

const OptionsMenu = styled.div`
  box-shadow: var(--heavy-shadow);
  border-radius: 6px;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
`;

export default OptionsMenu;

export function Floater({
  children,
  topOffset = 8,
  bottomOffset = 0,
  className,
  optionMenuStyle,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const element = ref.current;
    const onAnimationEnd = () => {
      revealElement(element, { side: "bottom", offset: bottomOffset });

      // fix container height to prevent window jumpiness on child resize
      element.style.height = element.getBoundingClientRect().height + "px";
    };

    element.addEventListener("animationend", onAnimationEnd);

    return () => element.removeEventListener("animationend", onAnimationEnd);
    // @refactoring Forbid deactivation of hook dependencies (https://constructor.slab.com/posts/rfc-remove-deactivation-of-the-es-lint-rule-for-hook-dependenciesoli-vk98awgw)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return (
    <div
      ref={ref}
      className={className}
      css={css`
        animation: ${show} 0.25s ease-in-out;
        z-index: 2;
        position: absolute;
        margin-bottom: ${bottomOffset}px;
        max-height: calc(
          min(512px, calc(100vh - ${topOffset}px - ${bottomOffset}px))
        );
      `}
    >
      <OptionsMenu
        css={[
          css`
            width: 100%;
            overflow: auto;
            max-height: calc(
              min(512px, calc(100vh - ${topOffset}px - ${bottomOffset}px))
            );
          `,
          optionMenuStyle,
        ]}
      >
        {children}
      </OptionsMenu>
    </div>
  );
}

type Props = {
  children: React.ReactNode;
  bottomOffset?: number;
  topOffset?: number;
  className?: string;
  optionMenuStyle?: SerializedStyles;
};

// Required to assign type to "Option" which is indirectly referenced in it's
// own initializer.
const Helper = styled.div<{
  fullWidthBorder?: boolean;
  borderWidth?: number;
  isHighlighted?: boolean;
}>``;

type Option = {
  fullWidthBorder?: boolean;
  borderWidth?: number;
  isHighlighted?: boolean;
  color?: keyof typeof theme.colors;
};

export const Option = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "fullWidthBorder" &&
    prop !== "isHighlighted" &&
    prop !== "borderWidth",
})<Option>`
  padding: 14px 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
  color: ${({ color }) => color || theme.colors.textGrayBlue};

  :focus {
    text-decoration: none;
    background-color: var(--pale-grey);
    outline: none;
  }

  :hover {
    text-decoration: none;
    background-color: var(--neutral-greenishligthgrey);
  }

  ${({ fullWidthBorder = false, borderWidth = 2 }) =>
    borderStyles({ fullWidthBorder, borderWidth })}

  background: ${({ isHighlighted = false }) =>
    isHighlighted ? "var(--pale-grey)" : "transparent"};
` as typeof Helper;

export const OptionLink = styled(Option)`
  text-decoration: none;
  font-size: 14px;

  :hover {
    color: ${theme.colors.textGrayBlue};
    text-decoration: none;
  }
`.withComponent("a");

type OptionButton = {
  wrapText?: boolean;
};

export const OptionButton = styled(Option, {
  shouldForwardProp: (prop) => prop !== "wrapText",
})<Option & OptionButton>`
  margin: 0;
  border: none;
  text-align: left;
  white-space: ${({ wrapText = false }) => {
    return wrapText ? "normal" : "nowrap";
  }};
  width: 100%;
  cursor: pointer;

  :focus {
    text-decoration: none;
    background-color: var(--pale-grey);
    outline: none;
  }

  :hover {
    text-decoration: none;
    background-color: var(--neutral-greenishligthgrey);
  }

  :disabled {
    cursor: default;
  }
`.withComponent("button");

export const Header = styled.h3`
  .dashboard h3&,
  & {
    font-family: ${theme.fonts.text};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: ${theme.colors.textGrayBlue};
    text-transform: uppercase;
    margin: 20px 24px 4px;
  }
`;

export const Value = styled.div`
  font-size: 15px;
  color: ${theme.colors.textGrayBlue};
`;

export const Comment = styled.div`
  font-size: 14px;
  font-style: italic;
  color: ${theme.colors.headerGray};
  padding-right: 2px;
`;

export const Divider = styled.div`
  height: 2px;
  background-color: rgba(227, 231, 243, 0.6);
`;

// Since emotion will generate a different class name for each of the different
// option types, we need to write the style specifically for every combination
// of them

const borderStyles = (args: BorderStyleArgs = {}) => css`
  &${Option}:not(:last-child):after,
  &${OptionLink}:not(:last-child):after,
  &${OptionButton}:not(:last-child):after {
    /* Trick to get the border to be less than the full width */
    ${borderCss(args)}
  }
`;

type BorderStyleArgs = {
  borderWidth?: number;
  fullWidthBorder?: boolean;
};

export const borderCss = ({
  borderWidth = 2,
  fullWidthBorder = false,
}: BorderStyleArgs = {}) => css`
  content: "";
  position: absolute;
  bottom: 0;
  width: ${fullWidthBorder ? "100%" : "calc(100% - 50px)"};
  ${fullWidthBorder && "left: 0;"}
  border-top: ${borderWidth}px solid rgba(227, 231, 243, 0.6);
`;

const show = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;
