import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

import {
  CommonPageGroupArgs,
  PageGroup,
} from "app/components/shared/shared/types";
import { getIsHistorySupported } from "app/components/shared/shared/utils";

export default function ({ path }: CommonPageGroupArgs): PageGroup {
  return {
    items: [
      {
        label: "Rules Impact",
        href: "/dashboard/analytics/rules",
        activePaths: ["/dashboard/analytics/rules"],
        isEnabled: isFlagEnabled(FeatureFlags.EnableRulesImpactDashboard),
        hasVisited: true,
        isSubscribed: true,
        isHistorySupported: getIsHistorySupported(
          "/dashboard/analytics/rules",
          path
        ),
        breadcrumbs: [{ label: "Analytics" }, { label: "Rules Impact" }],
        analyticsLabel: "Rules Impact",
      },
      {
        label: "Opportunities",
        href: "/dashboard/analytics/opportunities",
        activePaths: ["/dashboard/analytics/opportunities"],
        isEnabled: true,
        hasVisited: true,
        isSubscribed: true,
        isHistorySupported: getIsHistorySupported(
          "/dashboard/analytics/opportunities",
          path
        ),
        breadcrumbs: [{ label: "Analytics" }, { label: "Opportunities" }],
        analyticsLabel: "Opportunities",
      },
      {
        label: "Search",
        href: "/dashboard/analytics/search",
        activePaths: [
          "/dashboard/analytics/search",
          "/dashboard/analytics/terms",
          "/dashboard/visitors/",
        ],
        isEnabled: true,
        hasVisited: true,
        isSubscribed: true,
        isHistorySupported: getIsHistorySupported(
          "/dashboard/analytics/search",
          path
        ),
        breadcrumbs: [{ label: "Analytics" }, { label: "Search" }],
        analyticsLabel: "Search",
      },
      {
        label: "Browse",
        href: "/dashboard/analytics/browse",
        activePaths: ["/dashboard/analytics/browse"],
        hasVisited: true,
        isSubscribed: true,
        isEnabled: isFlagEnabled(FeatureFlags.BrowseAnalytics),
        isHistorySupported: getIsHistorySupported(
          "/dashboard/analytics/browse",
          path
        ),
        breadcrumbs: [{ label: "Analytics" }, { label: "Browse" }],
        analyticsLabel: "Browse",
      },
      {
        label: "Conversions",
        href: "/dashboard/analytics/conversions",
        activePaths: ["/dashboard/analytics/conversions"],
        hasVisited: true,
        isSubscribed: true,
        isEnabled: true,
        isHistorySupported: getIsHistorySupported(
          "/dashboard/analytics/conversions",
          path
        ),
        breadcrumbs: [{ label: "Analytics" }, { label: "Conversions" }],
        analyticsLabel: "Conversions",
      },
      {
        label: "Recommendations",
        href: "/dashboard/analytics/recommendations",
        activePaths: ["/dashboard/analytics/recommendations"],
        hasVisited: true,
        isSubscribed: true,
        isEnabled: isFlagEnabled(FeatureFlags.Recommendations),
        isHistorySupported: getIsHistorySupported(
          "/dashboard/analytics/recommendations",
          path
        ),
        breadcrumbs: [{ label: "Analytics" }, { label: "Recommendations" }],
        analyticsLabel: "Recommendations",
      },
      {
        label: "Rule performance",
        href: "/dashboard/analytics/rule_performance",
        activePaths: ["/dashboard/analytics/rule_performance"],
        hasVisited: true,
        isSubscribed: true,
        isEnabled: true,
        isHistorySupported: getIsHistorySupported(
          "/dashboard/analytics/rule_performance",
          path
        ),
        breadcrumbs: [{ label: "Analytics" }, { label: "Rule performance" }],
        analyticsLabel: "Rule_Performance",
      },
      {
        label: "Quizzes",
        href: "/dashboard/analytics/quizzes",
        activePaths: ["/dashboard/analytics/quizzes"],
        hasVisited: true,
        isSubscribed: true,
        isEnabled: isFlagEnabled(FeatureFlags.Quizzes),
        isHistorySupported: getIsHistorySupported(
          "/dashboard/analytics/quizzes",
          path
        ),
        breadcrumbs: [{ label: "Analytics" }, { label: "Quizzes" }],
        analyticsLabel: "Quizzes",
      },
      {
        label: "Geo Personalization",
        href: "/dashboard/analytics/geo_personalization",
        activePaths: ["/dashboard/analytics/geo_personalization"],
        hasVisited: true,
        isSubscribed: true,
        isEnabled: isFlagEnabled(FeatureFlags.GeoPersonalization),
        isHistorySupported: getIsHistorySupported(
          "/dashboard/analytics/geo_personalization",
          path
        ),
        breadcrumbs: [{ label: "Analytics" }, { label: "Geo Personalization" }],
        analyticsLabel: "Geo_Personalization",
      },
      {
        label: "A/B Testing",
        href: "/dashboard/analytics/ab_testing",
        activePaths: ["/dashboard/analytics/ab_testing"],
        hasVisited: true,
        isSubscribed: true,
        isEnabled: isFlagEnabled(FeatureFlags.AbTestingRoute),
        isHistorySupported: getIsHistorySupported(
          "/dashboard/analytics/ab_testing",
          path
        ),
        breadcrumbs: [{ label: "Analytics" }, { label: "A/B Testing" }],
        analyticsLabel: "AB_Testing",
      },
    ],
    index: 0,
    label: "Analytics",
    icon: "podium-16",
    analyticsLabel: "Analytics",
  };
}
