import React from "react";
import { Global, css } from "@emotion/react";

// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme from "components/theme";

export default function GlobalStyle() {
  return (
    <Global
      styles={css`
        @import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto+Flex:opsz,wght@8..144,100..1000&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

        @property --gradient-angle {
          syntax: "<angle>";
          initial-value: 0deg;
          inherits: false;
        }

        :root {
          // DEPRECATED - use colors defined in Chakra's theme, either through
          // Chakra's components or by using the CSS custom properties generated
          // for each token.
          --primary-green: #4abb8b;
          --primary-darkgreen: #2b8f65;
          --primary-disabled: #c8cdd5;
          --neutral-black: #181c25;
          --neutral-greenishgrey: #e4ebef;
          --neutral-greenishligthgrey: rgba(228, 235, 239, 0.64);
          --neutral-disabledgrey: rgba(80, 92, 117, 0.38);
          --neutral-disabledgrey-background: rgba(80, 92, 117, 0.28);
          --neutral-lightgrey: rgba(80, 92, 117, 0.64);
          --neutral-grey: #505a75;
          --neutral-blueishgrey: #e1e5f1;
          --neutral-greyedition: #414a5b;
          --accent-danger: #e0182d;
          --accent-primary-light: #b7e4d1;
          --secondary-bluebrand: #1f89fb;
          --secondary-bluedark: #196ec9;
          --secondary-turquoiselight: #79e2f2;
          --secondary-purplelight: #998dd9;
          --secondary-yellowlight: #ffc44d;
          --secondary-magentalight: #f55f89;
          --secondary-yellowregular: #ffab00;
          --secondary-turquoiseregular: #00b8d9;
          --secondary-magentadark: #c22c56;
          --secondary-purpleregular: #6554c0;
          --secondary-magentaregular: #f3376b;
          --secondary-yellowdark: #e69a00;
          --secondary-turquoisedark: #008da6;
          --secondary-purpledark: #403294;
          --pale-grey: #f7f9fa;
          --black: #000d1c;
          --background-grey: #eff4f6;
          --background-white: #fff;
          --input-color: #272e36;
          --light-blue: #d7f6fb;
          --gold: #fff7e8;
          --grey: #8f97a7;
          --grey-disabled: #ced1d8;

          --gray-50: #f7f7f9;
          --gray-100: #efeff4;
          /*
            This color does not have a name in the designs. Instead of making up
            yet another "lightish-grey" we're going with a common pattern in
            themable component libraries which is to have the same color in
            different shades. 450 is chosen as it's close to the saturation of
            gray-450 in chakra-ui.
          */
          --gray-450: #bcc1cb;
          --gray-400: #a0a0a8;

          --frame-shadow: 0px 2px 1px rgba(80, 92, 117, 0.05);
          --light-shadow: 0 4px 16px 0 rgba(0, 93, 186, 0.04);
          --medium-shadow: 0px 8.44615px 16.8923px rgba(96, 128, 153, 0.1),
            0px 1.68923px 16.8923px rgba(96, 128, 153, 0.04),
            0px 0.844615px 3.37846px rgba(96, 128, 153, 0.06);
          --heavy-shadow: 0 4px 16px 0 rgba(0, 93, 186, 0.1),
            0 4px 8px 0 rgba(0, 0, 0, 0.1);

          --font-header: Work Sans, sans-serif;
          --font-text: Lato, sans-serif;
          --font-code: Source Code Pro, monospace;
        }

        html,
        body {
          padding: 0;
          margin: 0;
          background-color: ${theme.colors.backgroundDefault};
          font-size: 14px;
          color: ${theme.colors.textGrayBlue};
          line-height: 1.42857143;
          height: 100%;
          font-feature-settings: "liga", "kern";

          // Using this value of antialiasing makes rendering closer to the
          // designs in webkit browsers for macOS.
          -webkit-font-smoothing: antialiased;
        }

        * {
          box-sizing: border-box;
          font-family: ${theme.fonts.text};
        }

        a {
          text-decoration: none;
          color: var(--neutral-black);

          :hover {
            text-decoration: underline;
            color: var(--neutral-black);
          }
        }

        body:has(.react-app > .ia_layout_container) {
          display: flex;
          flex-direction: column;
        }

        .react-app:has(.ia_layout_container) {
          height: 100%;
          overflow: hidden;
        }

        a > button {
          /* button links should have cursor: pointer just like regular links */
          cursor: pointer;
        }

        p {
          margin-block-end: 10px;
          margin-block-start: 6px;
          margin-bottom: 10px;
        }

        code {
          background-color: var(--background-grey);
          color: var(--neutral-grey);
        }

        h1,
        h2,
        h3,
        h4 {
          font-family: "Work Sans", sans-serif;
          font-weight: 500;
          line-height: 1.1;
        }

        strong {
          font-family: inherit;
          font-weight: 800;
        }

        *::placeholder {
          color: var(--neutral-lightgrey);
        }

        .ReactModal__Overlay > div {
          :focus {
            outline: none;
          }
        }

        /* modal */

        .cio-modal {
          margin: auto;
          opacity: 0;

          transform: translateY(-15%);
          transition: opacity 150ms linear, transform 0.15s ease-out;
        }

        .cio-modal__content {
          border-radius: 6px;
          background-color: white;
          overflow: hidden;
        }

        .cio-modal__header {
          padding: 24px 30px;
          border-bottom: 2px solid var(--neutral-blueishgrey);
        }

        .cio-modal__close {
          float: right;
        }

        .cio-modal__alert {
          margin-bottom: 24px;
        }

        .cio-modal__header-description {
          margin-top: 8px;
          margin-bottom: 0;
        }

        .cio-modal__body {
          padding: 24px 30px;
        }

        .cio-modal__footer {
          background-color: var(--neutral-greenishgrey);
          padding: 24px 30px;
        }

        .cio-modal__footer--with-actions {
          display: flex;
          justify-content: flex-end;
        }

        // Override Bootstrap classes' rules
        .modal-backdrop.in {
          opacity: 0.5;
        }

        .modal-content {
          padding: 0; // Padding is handled by each element individually
        }

        .cio-modal__subtitle {
          text-transform: uppercase;
          margin-bottom: 16px;
          font-size: 12px;
        }

        /* button */

        .cio-btn {
          border: none;
          border-radius: 9999px;
          padding: 15px 30px;
          font-size: 12px;
          color: var(--neutral-grey);
          text-transform: uppercase;
          // box-shadow instead of border to make it an "inset" border and have the
          // same size as buttons without a border
          box-shadow: inset 0 0 0 2px var(--neutral-blueishgrey);
          letter-spacing: 1.25px;
          position: relative;
          font-weight: bold;

          &:hover {
            text-decoration: none;
            color: var(--neutral-grey);
            cursor: pointer;
          }
        }

        .cio-btn--primary {
          box-shadow: none;
          color: white;
          background-color: #3dba85;

          &:hover {
            color: white;
          }
        }

        .cio-btn--primary:disabled {
          background-color: #c8cdd5;
          color: var(--neutral-disabledgrey);
        }

        .cio-btn--danger {
          box-shadow: none;
          color: white;
          background-color: var(--accent-danger);

          &:hover {
            color: white;
          }
        }

        .cio-btn--add {
          padding-left: 50px;
          background-image: url(${require("./GlobalStyle/Images/icon-add.svg")});
          background-repeat: no-repeat;
          background-position-x: 16px;
          background-position-y: center;
        }

        .cio-btn--add:disabled {
          background-image: url(${require("./GlobalStyle/Images/icon-add-disabled.svg")});
        }

        .cio-btn--small {
          padding: 8px 30px;
        }

        .cio-btn--add:focus {
          color: #ffffff;
          text-decoration: none;
        }

        .cio-btn + .cio-btn {
          margin-left: 16px;
        }

        .cio-btn-action {
          width: 32px;
          height: 32px;
          border: 2px solid var(--neutral-blueishgrey);
          border-radius: 4px;
          background-color: #fff;
          background-position: center;
          background-repeat: no-repeat;
        }

        .cio-btn-action + .cio-btn-action {
          margin-left: 10px;
        }

        .cio-btn-action--edit {
          background-size: 14px;
          background-image: url(${require("./GlobalStyle/Images/icon-edit.svg")});

          &:hover {
            background-image: url(${require("./GlobalStyle/Images/icon-edit-hover.svg")});
          }
        }

        .cio-btn-action--primary:hover {
          border-color: var(--primary-green);
          color: var(--primary-green);
        }

        .cio-btn-action--delete {
          background-size: 16px;
          background-image: url(${require("./GlobalStyle/Images/icon-delete.svg")});

          &:hover {
            background-image: url(${require("./GlobalStyle/Images/icon-delete-hover.svg")});
          }
        }

        .cio-btn-action--danger:hover {
          border-color: var(--accent-danger);
          color: var(--accent-danger);
        }

        .cio-btn__text--loading {
          opacity: 0;
        }

        @-moz-keyframes cio-btn__spinner--spin {
          100% {
            -moz-transform: rotate(360deg);
          }
        }
        @-webkit-keyframes cio-btn__spinner--spin {
          100% {
            -webkit-transform: rotate(360deg);
          }
        }
        @keyframes cio-btn__spinner--spin {
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }

        .cio-btn__spinner {
          background-size: 16px;
          background-image: url(${require("./GlobalStyle/Images/icon-spinner.svg")});
          width: 16px;
          height: 16px;
          display: inline-block;
          position: absolute;
          left: 50%;
          margin-left: -8px;

          -webkit-animation: cio-btn__spinner--spin 2s linear infinite;
          -moz-animation: cio-btn__spinner--spin 2s linear infinite;
          animation: cio-btn__spinner--spin 2s linear infinite;
        }

        .cio-confirmation {
          width: 615px;
        }

        /*
          For Storybook's Docs code block - temp fix since the code style above is affecting Storybook's Docs code block
          TODO: Remove this line once  code style is removed. (line 115)
        */
        .language-jsx {
          color: white;
          background-color: initial;
        }

        /*
          This will hide the focus indicator if the element receives focus    via the mouse,
          but it will still show up on keyboard focus.
        */
        .js-focus-visible :focus:not([data-focus-visible-added]) {
          outline: none;
          box-shadow: none;
        }

        /* TODO: Remove this code once integration/api_token's Conversion Events is removed */
        .conversion_events {
          padding-top: 24px;
        }

        .conversion_events h2 {
          font-size: 1.5em;
          margin-bottom: 15px;
          font-weight: 400;
          color: #4abb8b;
        }

        .conversion_events h3 {
          color: #272e36;
          font-size: 1.3em;
          margin-top: 20px;
          margin-bottom: 15px;
          font-weight: 400;
        }

        .conversion_events h4 {
          color: #272e36;
          font-size: 1.5em;
          margin-top: 0;
          margin-bottom: 0.5rem;
        }

        .conversion_events code {
          font-family: "Source Code Pro", monospace;
          background-color: var(--background-grey);
          color: var(--neutral-grey);
          font-size: 87.5%;
          word-wrap: break-word;
        }

        .conversion_events .alert {
          position: relative;
          border: 1px solid transparent;
          border-radius: 0.25rem;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 0;
        }

        .conversion_events .text-center {
          text-align: center !important;
        }

        .conversion_events .alert-warning {
          color: #856404;
          background-color: #fff3cd;
          border-color: #ffeeba;
        }
      `}
    />
  );
}
