import styled from "@emotion/styled";

import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

const isNewDesignSystem = isFlagEnabled(FeatureFlags.EnableNewDesignSystem);

/**
 * TODO: deprecated Use the design system `Input` component instead.
 */
const Input = styled.input`
  background-color: white;
  border-radius: 6px;
  border: 2px solid var(--neutral-blueishgrey);
  box-shadow: none;
  color: var(--input-color);
  display: block;
  font-size: 14px;
  height: 42px;
  line-height: 1.42857143;
  outline: none;
  padding: 6px 12px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 100%;

  :disabled {
    cursor: ${isNewDesignSystem ? "not-allowed" : "default"};
    background-color: ${isNewDesignSystem
      ? "var(--cio-colors-gray-50)"
      : "var(--neutral-greenishligthgrey)"};
    border-color: ${isNewDesignSystem && "var(--cio-colors-gray-100);"};
    color: ${isNewDesignSystem && "var(--cio-colors-gray-400);"};
  }

  :hover:not(:disabled):not(:focus):not(:focus-within) {
    border: 2px solid var(--neutral-lightgrey);
  }

  :focus,
  :focus-within {
    border: 2px solid var(--primary-green);
  }
`;

export default Input;
