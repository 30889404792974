import AuthApi from "services/AuthApi";
import { convertFromApiFormat } from "utils/datesApi";

import { NextPageCursor, NextPageCursorData, User } from "../shared/types";

/**
 * @description Get all non-Constructor users assigned to a specific role within a company.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-get-assigned-non-constructor-company-users-to-role
 * @returns {GetNonConstructorUsersByRoleAndCompanyResponse}
 */
export default async function getNonConstructorUsersByRoleAndCompany({
  companyId,
  roleId,
  nextPageCursor = "",
}: GetNonConstructorUsersByRoleAndCompanyParams): Promise<GetNonConstructorUsersByRoleAndCompanyResponse> {
  const queryParams = nextPageCursor
    ? { next_page_cursor: nextPageCursor }
    : {};

  const result =
    await AuthApi.get<GetNonConstructorUsersByRoleAndCompanyResponseData>(
      ["v1", "companies", companyId, "roles", roleId, "non-constructor-users"],
      queryParams
    );

  return GetNonConstructorUsersByRoleAndCompanyResponse.fromGetNonConstructorUsersByRoleAndCompanyResponseData(
    result
  );
}

getNonConstructorUsersByRoleAndCompany.key =
  "modules/authentication/actions/users/getNonConstructorUsersByRoleAndCompany";

export type GetNonConstructorUsersByRoleAndCompanyResponseData = {
  users: {
    created_at: string;
    email: string;
    first_name: string;
    id: string;
    last_login_at?: string;
    last_name: string;
    sign_in_count: number;
  }[];
} & NextPageCursorData;

export type GetNonConstructorUsersByRoleAndCompanyResponse = {
  users: User[];
} & NextPageCursor;

export type GetNonConstructorUsersByRoleAndCompanyParams = {
  companyId: string;
  roleId: string;
  nextPageCursor?: string;
};

export const GetNonConstructorUsersByRoleAndCompanyResponse = {
  fromGetNonConstructorUsersByRoleAndCompanyResponseData: (
    data: GetNonConstructorUsersByRoleAndCompanyResponseData
  ): GetNonConstructorUsersByRoleAndCompanyResponse => {
    const getNonConstructorUsersByRoleAndCompanyResponse: GetNonConstructorUsersByRoleAndCompanyResponse =
      {
        users: data.users.map((user) => ({
          createdAt: user.created_at
            ? convertFromApiFormat(user.created_at)
            : null,
          email: user.email,
          firstName: user.first_name,
          id: user.id,
          lastLoginAt: user.last_login_at
            ? convertFromApiFormat(user.last_login_at)
            : null,
          lastName: user.last_name,
          signInCount: user.sign_in_count,
        })),
        nextPageCursor: data.next_page_cursor,
      };
    return getNonConstructorUsersByRoleAndCompanyResponse;
  },
};
