import * as z from "zod";

/**
 * This interface represent the errors as returned with the API - at least
 * they'll have a message. Other errors might include more information, in order
 * to access that information the consumer has to create a type for the error
 * that extends IApiError and pass it to `request` (or one of its wrappers) as
 * the second generic type.
 *
 * In order to avoid breaking backwards compatibility, the actual error object
 * returned from the server is wrapped in an `ApiErrors` instance. It is
 * accessible through the `source` property.
 */
export type IApiError = {
  message: string;
};

export type ApiError = {
  error: SubError;
} & IApiError;

export type MultipleApiErrorsError = {
  code: string;
  message: string;
  source: string | null;
};

const MultipleApiErrorsErrorSchema = z.object({
  code: z.string(),
  message: z.string(),
  source: z.string().nullable(),
});

export const MultipleApiErrorsError = {
  is(err: unknown): err is MultipleApiErrorsError {
    return MultipleApiErrorsErrorSchema.safeParse(err).success;
  },
};

export type MultipleApiErrors = {
  trace_id: string;
  errors: MultipleApiErrorsError[];
} & IApiError;

export const MultipleApiErrors = {
  is(err: ApiError | MultipleApiErrors): err is MultipleApiErrors {
    return "errors" in err;
  },
};

export const ApiError = {
  is(err: ApiError | MultipleApiErrors): err is ApiError {
    return "error" in err;
  },
};

type ObjectWithError = { error: string };

type SubError =
  | Array<SubError>
  | ObjectWithError
  | { [key: string]: SubError }
  | string;

export default class ApiErrors<T extends IApiError> extends Error {
  errors: ApiError;
  status: number;
  source?: T;

  constructor(errors: ApiError, status: number, source?: T) {
    super(getMessage(errors));

    this.errors = errors;
    this.status = status;
    this.source = source;

    Object.setPrototypeOf(this, ApiErrors.prototype);
  }

  get message() {
    return getMessage(this.errors);
  }

  toString() {
    return this.errors && convertErrorsToString(this.errors);
  }

  get info() {
    return {
      status: this.status,
      errors: this.errors,
      source: this.source,
    };
  }
}

function getMessage(errors: ApiError) {
  return (
    convertErrorsToString(errors?.error) ||
    convertErrorsToString(errors?.message) ||
    "Unknown error"
  );
}

function convertErrorsToString(value: SubError): string {
  if (value instanceof Array) {
    return value.map((subValue) => convertErrorsToString(subValue)).join(", ");
  }

  if (value instanceof Object) {
    if (isErrorObject(value)) {
      return value.error;
    }

    return Object.keys(value)
      .map((key) => key + ": " + convertErrorsToString(value[key]))
      .join("; ");
  }

  if (value && value.toString) {
    return value.toString();
  }

  return value;
}

function isErrorObject(error: SubError): error is ObjectWithError {
  if (typeof error === "string") {
    return false;
  }

  return "error" in error && Object.keys(error).length === 1;
}

export function isErrorResponse<T, E>(
  response: T | E,
  status: number
): response is E {
  return status >= 400;
}
