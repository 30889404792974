import React, { PropsWithChildren } from "react";
import { Flex, Tag as ChakraTag, TagLabel } from "@chakra-ui/react";

import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";
import { CHAKRA_ICONS_16_TYPE } from "app/designSystem/components/ChakraIcon/iconTypes";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./Tag.styles";

type Props = {
  /**
   * Determines if the tag is disabled
   */
  isDisabled?: boolean;

  /**
   * Determines if the close icon is displayed
   */
  showClose?: boolean;

  /**
   * Callback function when close button is clicked
   */
  onClose?: () => void;

  /**
   * Callback function when tag is clicked
   */
  onClick?: () => void;

  /**
   * Size of the `Tag` component.
   */
  size?: "sm" | "md" | "lg";

  /**
   * Set the Left Icon (only sm icons) for `Tag` component.
   */
  leftIcon?: CHAKRA_ICONS_16_TYPE | React.ReactElement;

  /**
   * Set colorScheme for `Tag` component.
   */
  colorScheme?: "purple" | "yellow" | "grey";
  /**
   * Set the default alignment for tag. Defaults to `center`.
   */
  alignItems?: "center" | "baseline";
};

export const Tag = React.forwardRef<
  HTMLButtonElement | HTMLDivElement,
  PropsWithChildren<Props>
>(
  (
    {
      children,
      isDisabled = false,
      showClose = false,
      onClose,
      size = "md",
      leftIcon,
      colorScheme,
      alignItems,
      onClick,
    },
    ref
  ) => {
    return (
      <ChakraTag
        size={size}
        {...styles.Tag(
          isDisabled,
          colorScheme,
          size,
          !!leftIcon,
          showClose,
          !!onClick,
          alignItems
        )}
        onClick={onClick}
        as={onClick ? "button" : "span"}
        ref={ref}
      >
        <Flex {...styles.TagContainer}>
          {leftIcon && typeof leftIcon === "string" ? (
            <ChakraIcon icon={leftIcon} {...styles.TagIcon(isDisabled)} />
          ) : (
            leftIcon
          )}
        </Flex>
        {children && (
          <TagLabel
            {...styles.TagLabel(isDisabled, !!leftIcon, showClose, !!onClick)}
          >
            {children}
          </TagLabel>
        )}
        {showClose && (
          <Flex {...styles.TagCloseButton(isDisabled)}>
            <ChakraIcon
              aria-label="Close"
              icon="close-16"
              color={isDisabled ? "grey" : "grey-dark"}
              onClick={() => onClose && !isDisabled && onClose()}
            />
          </Flex>
        )}
      </ChakraTag>
    );
  }
);

Tag.displayName = "Tag";
