import { AuthOperation } from "./enums";
import { Permission } from "./types";

/**
 * Check if the permission set grants `operation` (r, w, or rw) access to the given scope and resources
 * @param {Permission[]} permissions - The permission set to check against, e.g. current token's permissions
 * @param {string} scope - The scope to check for, e.g. AuthWebsiteScope.AbTesting or "custom_scope"
 * @param {AuthOperation} operation - The operation to check for, e.g. AuthOperation.Read or AuthOperation.Write
 * @param {string | string[]} [resources] - The resources to check for, e.g. "index_key" or "*" for all resources
 * @returns {boolean} - Whether the permission set grants the requested access
 */
export function hasPermission(
  permissions: Permission[],
  scope: string,
  operation: AuthOperation,
  resources: string | string[] = "*"
): boolean {
  resources = Array.isArray(resources) ? resources : [resources];

  return resources.every((resource) =>
    operation === AuthOperation.ReadWrite
      ? hasPrimitivePermission(
          permissions,
          scope,
          AuthOperation.Read,
          resource
        ) &&
        hasPrimitivePermission(
          permissions,
          scope,
          AuthOperation.Write,
          resource
        )
      : hasPrimitivePermission(permissions, scope, operation, resource)
  );
}

function hasPrimitivePermission(
  permissions: Permission[],
  scope: string,
  operation: AuthOperation.Read | AuthOperation.Write,
  resource: string
): boolean {
  return permissions.some(
    (permission) =>
      permission.scope === scope &&
      permission.operation.includes(operation) &&
      (permission.resources.includes("*") ||
        permission.resources.includes(resource))
  );
}
