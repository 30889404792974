import AuthApi from "services/AuthApi";
import { Permission } from "app/auth";

import { Role, RoleData } from "../shared/types";

/**
 * @description Creates a new role within a specified company.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-create-role
 * @returns {Role}
 */
export default async function createRole({
  companyId,
  description,
  displayName,
  permissions,
}: Props): Promise<Role> {
  const result = await AuthApi.post<RoleData>(
    ["v1", "companies", companyId, "roles"],
    {},
    {
      description,
      display_name: displayName,
      permissions,
    }
  );

  return Role.parse(result);
}

export type Props = {
  companyId: string;
  description: string;
  displayName: string;
  permissions: Permission[];
};
