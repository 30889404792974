import styled from "@emotion/styled";

// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme from "components/theme";
import A from "components/Modules/Link";

export default styled(A)`
  display: inline-flex;
  align-items: center;
  color: ${theme.colors.textGrayBlue};
  text-decoration: none;
  height: auto;
  border: 2px solid ${theme.colors.borderGrayLight};
  border-radius: 9999px;
  padding: 6px 16px 6px 12px;
  letter-spacing: 0.5px;
  margin: 0px 12px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  top: 1px;
  white-space: nowrap;
  background: white;

  &:active,
  &:focus,
  &:hover {
    background: ${theme.colors.borderGrayLight};
    color: ${theme.colors.textGrayBlue};
    text-decoration: none;
    outline: none;
    cursor: pointer;
  }

  &:focus {
    border-color: ${theme.colors.borderDark};
  }
`;
