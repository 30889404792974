import styled from "@emotion/styled";

// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme from "components/theme";

export const Body = styled.div`
  padding: 32px;
`;

export const Footer = styled.div`
  background-color: ${theme.colors.neutralGreenishgrey};
  padding: 24px 32px;
  display: flex;
  justify-content: flex-end;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;
