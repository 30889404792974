import { useRouteMatch } from "react-router-dom";
import { useContext } from "react";

import URI from "utils/urijs";
import RootContext from "app/providers/RootContext";
import useVisitedTabs from "app/components/SubscriptionGuard/useVisitedTabs";
import useSessionStorage from "app/hooks/useSessionStorage";

import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

import { isAuthenticationServiceSession } from "utils/authentication";

import { Breadcrumb } from "./shared/types";
import { getPathDetails, isPathInGroup } from "./shared/utils";
import getAnalytics from "./usePageLayout/analytics";
import getWorkspaces from "./usePageLayout/workspace";
import getIntegrations from "./usePageLayout/integration";
import getHelp from "./usePageLayout/help";
import getAccounts from "./usePageLayout/accounts";

type ExtraProps = {
  canManageUsers?: boolean;
};

const usePageLayout = ({ canManageUsers = false }: ExtraProps = {}) => {
  const { hasSso, canUpdateCompany, isIaResponsivePageLayoutActive } =
    useContext(RootContext);
  const { path: routerPath } = useRouteMatch();
  const uriPath = URI().path();

  const currentPath = routerPath === "/" ? uriPath : routerPath;

  const { visitedTabs } = useVisitedTabs();

  const isAuthServiceEnabled = isAuthenticationServiceSession();

  const help = getHelp();
  const analytics = getAnalytics({ path: currentPath });
  const workspace = getWorkspaces({ path: currentPath, visitedTabs });
  const accounts = getAccounts({
    path: currentPath,
    canUpdateCompany,
    userHasSso: hasSso,
    isAuthServiceEnabled,
    canManageUsers,
  });
  const integration = getIntegrations({ path: currentPath });

  const getRouteData = (path: string) => {
    switch (true) {
      case isPathInGroup(path, analytics):
        return getPathDetails(path, analytics);
      case isPathInGroup(path, workspace):
        return getPathDetails(path, workspace);
      case isPathInGroup(path, integration):
        return getPathDetails(path, integration);
      case isPathInGroup(path, help):
        return getPathDetails(path, help);
      case isPathInGroup(path, accounts):
        return getPathDetails(path, accounts);
      default:
        return getPathDetails(path);
    }
  };

  const currentPathData = getRouteData(currentPath);
  const animationHelper = useSessionStorage("animated", false);

  const getBreadcrumbDataFromQuery = (
    fallbackLabel: string,
    fallbackUrl?: string,
    paramName = "back"
  ): Breadcrumb => {
    const params = new URLSearchParams(URI().search());
    const href = params.get(paramName) || fallbackUrl || "";

    const currentPathData = getRouteData(href);

    const label = currentPathData.breadcrumbs.length
      ? currentPathData.breadcrumbs[1].label
      : "";

    return label
      ? { label, href }
      : { label: fallbackLabel, href: fallbackUrl };
  };

  const isNewLayoutFeatureEnabled = isFlagEnabled(
    FeatureFlags.EnableIaResponsivePageLayout
  );

  const isNewPageLayoutByDefaultEnabled = isFlagEnabled(
    FeatureFlags.EnableIaResponsivePageLayoutByDefault
  );

  return {
    help,
    accounts,
    analytics,
    workspace,
    integration,
    currentPath,
    animationHelper,
    currentPathData,
    getBreadcrumbDataFromQuery,
    isNewPageLayoutByDefaultEnabled,
    isRenderingNewLayout:
      (isNewLayoutFeatureEnabled && isIaResponsivePageLayoutActive) ||
      isNewPageLayoutByDefaultEnabled,
  };
};

export default usePageLayout;
