import React from "react";
import { IconButton } from "@chakra-ui/react";

import { ChakraIcon } from "app/designSystem/components";

export default function ExpandCollapseButton({
  isOpen,
  onClick,
  isExpanded,
}: Props) {
  return (
    <IconButton
      isRound
      h="24px"
      w="24px"
      minH="24px"
      minW="24px"
      position="absolute"
      top="24px"
      right="-12px"
      transition="right 300ms, background-color 150ms"
      transitionTimingFunction="ease-in-out"
      backgroundColor="grey-light-blue"
      _hover={{ backgroundColor: "grey-2" }}
      display={{ sm: "none", md: "block" }}
      icon={
        <ChakraIcon
          icon={
            isExpanded
              ? "link-specific-chevron-left-16"
              : "link-specific-chevron-right-16"
          }
        />
      }
      aria-label={`${isOpen ? "Collapse" : "Expand"} navigation sidebar`}
      onClick={onClick}
      boxShadow="feedback"
    />
  );
}

type Props = {
  onClick: () => void;
  isOpen: boolean;
  isExpanded: boolean;
};
