import React from "react";
import {
  Box,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  Heading,
  Divider,
  Flex,
  Text,
} from "@chakra-ui/react";

import useSidebar from "app/components/shared/useSidebar";
import NavbarDropdown from "components/Modules/NavbarDropdown";
import useModal from "app/hooks/useModal";

import Indexes from "components/Modules/IndexFilter/Indexes";

import { Index } from "modules/indexes";
import usePageLayout from "app/components/shared/usePageLayout";

import { ChakraIcon } from "app/designSystem/components";

import DefaultIndexChanger from "./IndexFilter/DefaultIndexChanger";

const Z_INDEX_SECOND_OVERLAY = 1400;

function IndexFilter({
  indexes,
  className,
  currentIndexName = null,
  allowAll = true,
  baseUrl = null,
  keepQueryParameters = [],
  disabled = false,
  isLegacyIndexFilter = true,
}: Props) {
  const { isDesktopView } = useSidebar();
  const [ModalGate, toggleIndexModal] = useModal();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isRenderingNewLayout } = usePageLayout();

  if (!isDesktopView && isRenderingNewLayout) {
    return (
      <Box p="10px 4px">
        <Box
          onClick={onOpen}
          display="flex"
          flexDirection="column"
          cursor="pointer"
        >
          <Text
            textStyle="subheadline"
            fontWeight="bold"
            color="grey-dark"
            m={0}
          >
            Index
          </Text>
          <Flex alignItems="center" justifyContent="space-between">
            <Text m={0}>{currentIndexName ?? "All indexes"}</Text>
            <ChakraIcon icon="arrow-drop-left-16" color="grey-dark" />
          </Flex>
        </Box>

        <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
          <DrawerOverlay
            background="rgba(0, 0, 0, 0.08)"
            zIndex={Z_INDEX_SECOND_OVERLAY}
          />
          <DrawerContent
            borderTopLeftRadius="6px"
            borderTopRightRadius="6px"
            paddingBottom="47px"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <DrawerHeader
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
                alignItems="center"
                minW="full"
                p="24px 32px 28px"
              >
                <Heading
                  as="h3"
                  m="0"
                  textAlign="start"
                  fontSize="20px"
                  lineHeight="24px"
                  fontWeight="500"
                  color="black"
                >
                  Select an index
                </Heading>
                <ChakraIcon
                  icon="close-24"
                  color="grey-dark"
                  onClick={onClose}
                />
              </DrawerHeader>
            </Box>
            <Divider m="0px" border="2px" mb={23} />
            <DrawerBody p="0px 12px">
              <Box w="full" boxShadow="none" overflowX="hidden">
                <Indexes
                  allowAll={allowAll}
                  baseUrl={baseUrl}
                  closeDropdown={onClose}
                  keepQueryParameters={keepQueryParameters}
                  onChangeDefault={() => {
                    toggleIndexModal(true);
                    onClose();
                  }}
                />
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    );
  }

  return (
    <>
      <ModalGate>
        <DefaultIndexChanger
          onCancel={() => toggleIndexModal(false)}
          onSuccess={() => toggleIndexModal(false)}
        />
      </ModalGate>
      <Flex pl={isLegacyIndexFilter ? "22px" : "0px"}>
        <NavbarDropdown
          headline="Index"
          name={currentIndexName ? currentIndexName : "All Indexes"}
          alignRight
          disabled={disabled}
          className={className}
        >
          {({ closeDropdown }: { closeDropdown: () => void }) => (
            <Indexes
              allowAll={allowAll}
              indexes={indexes}
              closeDropdown={closeDropdown}
              baseUrl={baseUrl}
              keepQueryParameters={keepQueryParameters}
              onChangeDefault={() => {
                closeDropdown();
                toggleIndexModal(true);
              }}
            />
          )}
        </NavbarDropdown>
      </Flex>
    </>
  );
}

type Props = {
  currentIndexName?: string | null;
  isLegacyIndexFilter?: boolean;
  allowAll?: boolean;
  indexes?: Index[];
  className?: string;
  baseUrl?: string | null;
  disabled?: boolean;
  keepQueryParameters?: string[];
};

export default IndexFilter;
