import React from "react";
import { HStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import URI from "utils/urijs";
import IndexFilter from "components/Modules/IndexFilter";

import useDates from "app/hooks/useDates";
import usePrevious from "app/hooks/usePrevious";
import { Button as RegularButton } from "app/designSystem/components";
import { ActionGroupProps } from "app/components/shared/actionGroupProps";
import { getIsHistorySupported } from "app/components/shared/shared/utils";
import usePageLayout from "app/components/shared/usePageLayout";

import DateRangeFilter from "./ActionGroup/DateRangeFilter";

export default function ActionGroup({
  allowAllIndexes = true,
  disableIndexSelector = false,
  isIndexFetching = false,
  persistQueryParams = [],
  showDateRangePicker = false,
  dateRangeFilter = null,
  index = null,
  indexBaseUrl = null,
  showIndexSelector = true,
}: ActionGroupProps) {
  const previousIndexName = usePrevious(index && index.name);
  const [{ startDate, endDate }, setDates] = useDates({
    keepQueryParameters: persistQueryParams,
  });

  const { currentPath } = usePageLayout();

  const interactbase = "/dashboard/interact";
  const isHistorySupported = getIsHistorySupported(currentPath, interactbase);
  const interactLink = URI(interactbase)
    .setSearch("back", URI().resource())
    .resource();

  const buttonProps = isHistorySupported
    ? { as: Link, to: interactLink }
    : { as: "a", href: interactLink };

  return (
    <>
      {showDateRangePicker &&
        (dateRangeFilter ?? (
          <DateRangeFilter
            startDate={startDate.format("YYYY-MM-DD")}
            endDate={endDate.format("YYYY-MM-DD")}
            onChange={setDates}
          />
        ))}
      {showIndexSelector && (
        <IndexFilter
          currentIndexName={isIndexFetching ? previousIndexName : index?.name}
          disabled={disableIndexSelector || isIndexFetching}
          allowAll={allowAllIndexes}
          isLegacyIndexFilter={false}
          baseUrl={indexBaseUrl}
          keepQueryParameters={[
            "start_date",
            "end_date",
            ...persistQueryParams,
          ]}
        />
      )}
      <HStack
        borderLeft="1px solid"
        borderLeftColor={{ sm: "transparent", md: "overlay-white" }}
        pl={{ sm: "0", md: "28px" }}
      >
        <RegularButton
          {...buttonProps}
          icon="preview-16"
          variant="ghost"
          css={{ "&:hover, &:hover:focus": { textDecoration: "none" } }}
        >
          Interact
        </RegularButton>
      </HStack>
    </>
  );
}
