import { css } from "@emotion/react";

/**
 * TODO: deprecated Use styles provided by Chakra instead, either by using Chakra
 * components or by consuming its tokens with CSS custom properties.
 */
export default {
  colors: {
    backgroundDefault: "#f7f9fa",
    backgroundWhite: "#fff",
    backgroundPrimary: "#3dba85",
    backgroundPrimaryDisabled: "#c8cdd5",
    backgroundGray: "rgba(228, 235, 239, 0.64)",
    backgroundGrayBlue: "rgba(194, 200, 218, 0.24)",
    backgroundGrayDark: "#e4ebef",
    backgroundGrayHover: "#e5e8f3",
    backgroundGrayHoverDark: "#5a6780",
    backgroundInfo: "#1f89fb",
    backgroundGrayLight: "rgba(228, 235, 239, 0.2)",
    paleGray: "rgba(247, 249, 250, 0.6)",
    textGray: "#67738c",
    textGrayBlue: "#505a75",
    textGrayDark: "#4b5873",
    textGrayDarkDisabled: "#a6adba",
    textBlack: "#000d1c",
    lightHighlight: "#f1faf6",
    darkHighlight: "#4abb8b",
    success: "#4abb8b",
    warning: "#ffc44d",
    danger: "#e0182d",
    dangerDark: "#bf314a",
    dangerBackgroundDark: "#bf1728",
    borderGrayLight: "#e1e5f1",
    headerBlack: "#181c25",
    headerGray: "rgba(80, 92, 117, 0.64)",
    borderDark: "#46516b",
    blueBrand: "#1F89FB",
    purpleRegular: "#6554C0",
    inputColor: "#272e36",
    neutralGreenishgrey: "rgba(228, 235, 239, 0.6)",
    neutralLightGrey: "rgba(80, 92, 117, 0.64)",
    neutralDisabledGrey: "rgba(80, 92, 117, 0.28)",
    disabled: "#cfd3da",
    white: "#ffffff",
    primaryDarkGreen: "#2b8f65",
    secondaryBlueDark: "#196ec9",
    gold: "#fff7e8",
  },
  fonts: {
    header: "Work Sans, sans-serif",
    text: "Lato, sans-serif",
    code: "Source Code Pro, monospace",
  },
};

const styles = {};

styles.shadows = {
  base: css`
    box-shadow: 0px 2px 1px 0px rgba(80, 92, 117, 0.05);
  `,
  light: css`
    box-shadow: 0 4px 16px 0 rgba(0, 93, 186, 0.04);
  `,
  heavy: css`
    box-shadow: 0px 4px 16px 0px rgba(0, 93, 186, 0.1),
      0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  `,
};

styles.box = css`
  ${styles.shadows.heavy};
  border-radius: 6px;
  background-color: white;
`;

export { styles };
