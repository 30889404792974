import { FacetOption, OptionFacet } from "types/search";

export function getFacetDisplayName(
  filterName: string,
  filterValue: string,
  parent?: OptionFacet
) {
  if (parent) {
    const path = getOptionPath(filterValue, parent.options);
    if (path) {
      return [
        parent.displayName,
        ...path.map((option) => option.displayName),
      ].join(" / ");
    }
  }
  return `${filterName} / ${filterValue}`;
}

// depth-first recursive graph traversal
export function getOptionPath(
  filterValue: string,
  options: FacetOption[]
): FacetOption[] | undefined {
  for (const child of options) {
    if (child.value === filterValue) {
      return [child];
    }
    if (child.options) {
      const path = getOptionPath(filterValue, child.options);
      if (path) {
        return [child, ...path];
      }
    }
  }
  return undefined;
}
