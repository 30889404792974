import { defineStyleConfig } from "@chakra-ui/react";

export const Counter = defineStyleConfig({
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "4px",
    border: "1px solid",
    borderColor: "transparent",
    color: "gray.1000",
    cursor: "default",
    justifyContent: "center",
    alignItems: "center",
    _disabled: {
      cursor: "not-allowed",
    },
  },
  sizes: {
    lg: {
      h: "24px",
      p: "2px",
      textStyle: "p1",
    },
    md: {
      h: "20px",
      p: "2px",
      textStyle: "p2",
    },
    sm: {
      h: "16px",
      py: "0px",
      px: "2px",
      textStyle: "new-caption",
    },
  },
  variants: {
    grey: {
      bgColor: "gray.25",
      _disabled: {
        bgColor: "gray.50",
        color: "gray.500",
      },
    },
    neutral: {
      bgColor: "white",
      _disabled: {
        color: "gray.500",
      },
    },
    outline: {
      bgColor: "white",
      borderColor: "gray.300",
      _disabled: {
        borderColor: "gray.100",
        color: "gray.500",
      },
    },
  },
  // The default variant value
  defaultProps: {
    variant: "grey",
    size: "md",
  },
});
