// TODO: Replace this import with date-fns
// eslint-disable-next-line no-restricted-imports
import moment from "modules/moment";
import useCurrentCompany from "app/hooks/useCurrentCompany";

export default function useAnalyticsReferenceDate(): {
  moment: moment.Moment | undefined;
  days: number;
} {
  const currentCompany = useCurrentCompany();
  // NOTE: If the PageLayoutWrapper is rendered outside of the <App />, currentCompany
  // may be undefined, so I've made this code safe by using the conditional ? operator
  const analyticsDate =
    currentCompany?.dashboardSettings?.interactAnalyticsDate;
  const analytics60Days =
    currentCompany?.dashboardSettings?.interactAnalytics60Days;

  // could be considered null
  const analyticsMoment = analyticsDate
    ? moment.utc(analyticsDate, moment.ISO_8601)
    : undefined;

  const analyticsDays = analytics60Days ? 60 : 30;

  return { moment: analyticsMoment, days: analyticsDays };
}
