import React from "react";
import * as Sentry from "@sentry/react";
import {
  Route as VendorRoute,
  RouteProps as VendorRouteProps,
} from "react-router-dom";
import { ExtractRouteParams } from "react-router";

import Wrapper from "./Route/Wrapper";

// Wraps the default Route component with Sentry's instrumentation so we can
// identify different URLs as the same transaction (e.g. /users/1 and /users/2
// both belong to /users/:id)
// We keep the real Route for tests since we don't use Sentry there.
const BaseRoute =
  process.env.NODE_ENV === "test"
    ? VendorRoute
    : Sentry.withSentryRouting(VendorRoute);

export default function Route<
  Path extends string = string,
  Params extends { [K: string]: string | undefined } = ExtractRouteParams<
    Path,
    string
  >
>({
  component: Component,
  render,
  children,
  ...props
}: VendorRouteProps<Path, Params>) {
  return (
    <BaseRoute
      {...props}
      render={
        Component
          ? (renderProps) => {
              return (
                <Wrapper>
                  <Component {...renderProps} />
                </Wrapper>
              );
            }
          : render
          ? (renderProps) => {
              // @ts-expect-error for some reason parameters doesn't match
              // the expected type, even they should
              return <Wrapper>{render(renderProps)}</Wrapper>;
            }
          : typeof children === "function"
          ? (renderProps) => {
              // @ts-expect-error for some reason parameters doesn't match
              // the expected type, even they should
              return <Wrapper>{children(renderProps)}</Wrapper>;
            }
          : children
          ? () => {
              return <Wrapper>{children}</Wrapper>;
            }
          : undefined
      }
    />
  );
}
