// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { AlertStatusTypes, alertVariantType } from "./Alert.types";

export const alertVariant: alertVariantType = {
  success: { icon: "success-16", color: "success" },
  danger: { icon: "danger-16", color: "danger" },
  info: { icon: "info-outline-16", color: "old-blue" },
  warning: { icon: "warning-16", color: "warning" },
};

export const Alert = (status: AlertStatusTypes) => {
  return {
    paddingY: "16px",
    paddingLeft: "13px",
    paddingRight: "16px",
    bgColor: "white",
    boxShadow: "0 3px 8px 0px rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderLeft: "3px solid",
    borderColor: alertVariant[status].color,
  };
};

export const AlertIcon = (status: AlertStatusTypes) => {
  return {
    h: "16px",
    w: "16px",
    color: alertVariant[status].color,
    alignSelf: "flex-start",
    mt: "4px",
  };
};

export const AlertText = {
  color: "grey-dark",
  textAlign: "left" as const,
  w: "full",
  textStyle: "body2",
  lineHeight: "22px",
  pl: "8px",
};

export const AlertCloseIcon = {
  color: "grey-dark",
  cursor: "pointer",
  h: "16px",
  w: "16px",
};
