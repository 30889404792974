import * as variationsV2 from "modules/variationsV2";

export type ItemVariation = {
  id: string;
  name: string;
  metadata: object;
  suggestedScore: number;
  deactivated?: boolean;
  description?: string;
  facets?: Record<string, string[] | number[]>;
  groupIds?: string[];
  imageUrl?: string;
  url?: string;
};

export const ItemVariation = {
  fromVariationV2(data: variationsV2.Variation): ItemVariation {
    const {
      deactivated,
      description,
      facets,
      groupIds,
      imageUrl,
      url,
      ...metadata
    } = data.data ?? {};

    return {
      id: data.id,
      name: data.name || "",
      deactivated: deactivated,
      description: description ?? undefined,
      facets: facets ?? undefined,
      groupIds: groupIds ?? undefined,
      imageUrl: imageUrl ?? undefined,
      metadata: metadata,
      suggestedScore: data.suggestedScore || 0,
      url: url ?? undefined,
    };
  },
};

export type ItemVariationData = {
  id: string;
  name: string;
  metadata: object;
  suggested_score: number;
  deactivated?: boolean;
  description?: string;
  facets?: Record<string, string[] | number[]>;
  group_ids?: string[];
  image_url?: string;
  url?: string;
};

export const ItemVariationData = {
  toItemVariation(data: ItemVariationData): ItemVariation {
    return {
      id: data.id,
      name: data.name,
      deactivated: data.deactivated,
      description: data.description,
      facets: data.facets,
      groupIds: data.group_ids,
      imageUrl: data.image_url,
      metadata: data.metadata,
      suggestedScore: data.suggested_score,
      url: data.url,
    };
  },
  fromItemVariation(data: ItemVariation): ItemVariationData {
    return {
      id: data.id,
      name: data.name,
      deactivated: data.deactivated,
      description: data.description,
      facets: data.facets,
      group_ids: data.groupIds,
      image_url: data.imageUrl,
      metadata: data.metadata,
      suggested_score: data.suggestedScore,
      url: data.url,
    };
  },
};
