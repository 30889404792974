import { useMutation } from "@tanstack/react-query";

import * as actions from "modules/authentication/actions";

export function useDeleteAuthToken() {
  return useMutation(actions.deleteTokens.deleteAuthToken);
}

export function useDeleteAdminToken() {
  return useMutation(actions.deleteTokens.deleteAdminToken);
}
