import React from "react";

// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import ModulesAlert from "components/Modules/Alert";

export default function Alert(
  props: React.ComponentProps<typeof ModulesAlert>
) {
  return <ModulesAlert {...props} />;
}
