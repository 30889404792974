import React, { useState } from "react";
import { Box, Text, Flex, VStack } from "@chakra-ui/react";

import BaseModal from "app/designSystem/components/Modal/BaseModal/BaseModal";
import { Button, Spinner } from "app/designSystem/components";
import { HugeLogo } from "components/Modules/Graphics/Quizzes";

import onboardingWalkthroughVideo from "./shared/onboarding_walkthrough_1440.mp4";

export default function GoToNewExperienceModal({
  onSubmit,
  isOpen,
  onClose,
}: Props) {
  const [isVideoLoading, setIsVideoLoading] = useState(true);

  return (
    <BaseModal
      size="lg"
      withoutFooter
      isOpen={isOpen}
      title=""
      onBack={onClose}
      onClose={onClose}
      subtitle={
        <Flex alignItems="center" mt="-8px">
          <HugeLogo />
          <Box h="100px" w="100%" display="flex" />
        </Flex>
      }
      modalHeaderSeparatorSx={{ display: "none" }}
      modalHeaderSx={{ pb: "0" }}
      modalBodySx={{ pt: "16px" }}
      body={
        <VStack spacing="16px">
          <Text textStyle="heading1" margin="0">
            Welcome to our new navigation!
          </Text>
          <Text textStyle="heading4" margin="0">
            We’ve improved the experience to make navigating through the
            dashboard even easier.
          </Text>
          <Box>
            <Box maxW="720px" h="400px" mt="16px" pos="relative">
              <Box
                borderWidth="1px"
                borderColor="grey-light-blue"
                borderRadius="6px"
                borderStyle="solid"
                as="video"
                w="100%"
                h="100%"
                onLoadedData={() => setIsVideoLoading(false)}
                controls={false}
                autoPlay
                loop
              >
                <source
                  src={onboardingWalkthroughVideo}
                  type="video/mp4"
                  style={{ imageRendering: "crisp-edges" }}
                />
                Sorry, your browser doesn&apos;t support embedded videos.
              </Box>
              <Box
                pos="absolute"
                w="100%"
                h="100%"
                top="0"
                left="0"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgColor="white"
                opacity={isVideoLoading ? "1" : "0"}
                transition="0.2s opacity ease-in"
              >
                <Spinner size="lg" />
              </Box>
            </Box>
          </Box>
          <Box pt="16px">
            <Button
              size="lg"
              onClick={() => {
                onClose();
                onSubmit();
              }}
            >
              go to the new experience
            </Button>
          </Box>
        </VStack>
      }
    />
  );
}

type Props = {
  onSubmit: () => void;
  isOpen: boolean;
  onClose: () => void;
};
