import React from "react";
import { Button, Text } from "@chakra-ui/react";

// TODO: Replace this import with date-fns
// eslint-disable-next-line no-restricted-imports
import moment from "modules/moment";

const Option = ({
  startDate,
  endDate,
  label,
  singleSelection,
  selectRange,
}: {
  startDate: moment.Moment;
  endDate?: moment.Moment;
  label: string;
  singleSelection?: boolean;
  selectRange: (from: moment.Moment, to?: moment.Moment) => void;
}) => {
  return (
    <Button
      color="grey-dark"
      bg="transparent"
      h="auto"
      w={{ sm: "fit-content", md: "full" }}
      flexBasis="fit-content"
      flexGrow={1}
      flexShrink={0}
      _hover={{
        bg: "green-light",
      }}
      borderTop={{ sm: "2px solid #eff1f9", md: "2px solid #eff1f9" }}
      border={{ sm: "2px solid #eff1f9", md: "none" }}
      _first={{ md: { borderTop: "none" } }}
      cursor="pointer"
      whiteSpace="nowrap"
      textAlign="left"
      borderRadius={{ sm: "4px", md: "0" }}
      padding={{ sm: "4px 8px", md: "12px 16px" }}
      onClick={() =>
        selectRange(
          startDate,
          !singleSelection && endDate ? endDate : undefined
        )
      }
    >
      <Text
        textStyle="body2"
        whiteSpace="nowrap"
        m="0"
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
        width="full"
      >
        {label}
      </Text>
      {singleSelection && (
        <Text
          marginLeft="auto"
          m="0"
          fontSize="13px"
          fontWeight="400"
          lineHeight="16px"
          color="grey"
          pl="8px"
        >
          {startDate.format("MMM D, YYYY")}
        </Text>
      )}
    </Button>
  );
};

export default Option;
