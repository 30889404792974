import React from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import styled from "@emotion/styled";

import URI from "utils/urijs";
import { isHistoryApiSupported } from "utils/routes";
import {
  Events,
  normalizeText,
  useTracking,
} from "app/providers/TrackingProvider";
import { isFlagEnabled } from "utils/featureFlags";
import { FeatureFlags } from "utils/generatedFeatures";
// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme from "components/theme";
import colors from "app/designSystem/theme/foundations/colors";

type Props = (LinkProps | React.AnchorHTMLAttributes<HTMLAnchorElement>) & {
  useHistory?: boolean;
  to: string;
  replace?: boolean;
};

const isDesignSystemButtonV2 = isFlagEnabled(FeatureFlags.DesignSystemButtonV2);

/**
 * Handles redirecting properties to either react-router's link or a native
 * anchor element based on the useHistory prop.
 */
function BaseLink({
  useHistory,
  to,
  replace,
  children,
  onClick,
  ...props
}: Props) {
  const { trackEvent } = useTracking();

  const currentUrl = window.location.pathname + window.location.search;

  const useHistoryLink =
    // eslint-disable-next-line no-negated-condition
    useHistory !== undefined
      ? useHistory
      : isHistoryApiSupported(URI().path(), {
          currentUrl,
        }) && isHistoryApiSupported(to, { currentUrl });

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    trackEvent(Events.LinkClick, {
      href: to,
      title: props["aria-label"] || props.title,
      text: normalizeText(event.currentTarget.innerText),
    });

    onClick?.(event);
  };

  if (useHistoryLink) {
    return (
      <RouterLink
        to={to}
        replace={replace}
        onClick={handleClick}
        {...(props as Omit<LinkProps, "to">)}
      >
        {children}
      </RouterLink>
    );
  }

  // We pass children explicitly (instead of relying on the expanded props
  // object) so that eslint will know that this link has content (otherwise an
  // accessibility linter check fails).
  return (
    <a href={to} onClick={handleClick} {...props}>
      {children}
    </a>
  );
}

const Link = styled(BaseLink)`
  color: ${isDesignSystemButtonV2
    ? colors.blue[700]
    : theme.colors.headerBlack};

  &:hover {
    color: ${isDesignSystemButtonV2
      ? colors.blue[700]
      : theme.colors.headerBlack};
  }
`;

export default Link;
