import AuthApi from "services/AuthApi";

import assignCompanyRoleToUser from "./assignCompanyRoleToUser";

type InviteUserToCompanyParams = {
  companyId: string;
  email: string;
  firstName?: string;
  lastName?: string;
  roleId: string;
  mfaToken?: string;
};

/**
 * @description Invite user to the current company
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-add-user-to-company
 * @returns { userId: string }
 */
export default async function inviteUserToCompany({
  companyId,
  email,
  roleId,
  firstName,
  lastName,
  mfaToken,
}: InviteUserToCompanyParams): Promise<{
  userId: string;
  firstName: string | null;
  lastName: string | null;
}> {
  const result = await AuthApi.post<{
    user_id: string;
    first_name: string | null;
    last_name: string | null;
  }>(
    ["v1", "companies", companyId, "users"],
    {},
    {
      email,
      first_name: firstName || "",
      last_name: lastName || "",
      email_mfa_token: mfaToken,
    }
  );

  await assignCompanyRoleToUser({
    companyId,
    roleId,
    userId: result.user_id,
  });

  return {
    userId: result.user_id,
    firstName: result.first_name,
    lastName: result.last_name,
  };
}

inviteUserToCompany.key =
  "modules/authentication/actions/companies/inviteUserToCompany";
